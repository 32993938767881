import React from "react";
import DwCookie from"../components/DwCookieCheck"
import {DwTableDiv} from "../components/DwTableDiv";



const Organization = () =>{

    return (
      <div >
        <DwCookie/>
        <div className="col-sm-12">
          <div className="card bg-dark p-2 m-1" style={{ minWidth: 1400 }}>
            <DwTableDiv />
          </div>
        </div>
      </div>
      );
    
}
export default Organization;