import React, { useEffect, useState } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import Swal from 'sweetalert2/dist/sweetalert2.js';

const SortableList = (props) => {

    useEffect(() =>{
        updateItems(props.items)
    },[props]);

    const [items, updateItems] = useState(props.items);

    function handleOnDragEnd(result) {
        if (!result.destination) return;
        const newItems = Array.from(items);
        const [reorderedItem] = newItems.splice(result.source.index, 1);
        newItems.splice(result.destination.index, 0, reorderedItem);
        updateItems(newItems);
        props.updateList(newItems);
    }

    function deleteItem(index){
        if(items.length === 1){
            Swal.fire({icon:"warning", text:"You can't remove the last item"});
            return;
        }
        if(props.deleteItem !== undefined){
            props.deleteItem(index);
        }
        else{
            const list = [...items];
            list.splice(index, 1);
            updateItems(list);
            props.updateList(list);
        }        
    }
    function editItem(index){
        if(props.editItem !== undefined){
            props.editItem(index);
        }        
    }

    return (
        <div className="SortableList">
            <DragDropContext onDragEnd={handleOnDragEnd}>
                <Droppable droppableId="characters">
                {(provided) => (
                    <ul className="ItemUl" {...provided.droppableProps} ref={provided.innerRef}>
                        {items.map((item, index) => {
                            return (
                                <Draggable key={index} draggableId={index.toString()} index={index} isDragDisabled={props.isDisable}>
                                    {(provided) => (
                                    <li className='ItemList' ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                        <p className='ItemP'>{item.name}</p>
                                        <div>
                                            <button className="itemEditButton" onClick={(e)=>{editItem(index)}}><EditIcon/></button>                                            
                                            <button className="itemButton" onClick={(e)=>{deleteItem(index)}}><DeleteIcon/></button>
                                        </div>
                                    </li>
                                    )}
                                </Draggable>
                            );
                        })}
                        {provided.placeholder}
                    </ul>
                )}
                </Droppable>
            </DragDropContext>
        </div>
    );
}

export default SortableList;