import React, { useState ,useEffect } from "react";
import axios from "axios";
import DwNav from "../components/DwNav";
import DwTakes from"../components/DwTakes"

const Takes = (props) => {
  const [take, setTake] = useState([]);

  useEffect(()=>{
      getTake();
      // eslint-disable-next-line
  },[]);

  const getTake = async function(){
    setTake([])
    try{
      await axios.get( window.domain+"take?sid="+getLocStg("sid"),returnHeaders()).then(res => {
        setTake(res["data"]) ;
      });
    }
    catch{

    }
  }
  const getLocStg=function(e){
    return localStorage.getItem(e)
  }
  const returnHeaders = function(){
    var token = getCookie( 'token' );
    let headerProposal ={
    headers: {
        'X-BP-Token': token,
      }
    };
    return headerProposal;
  }
  const getCookie = function( name ) {
    var r = document.cookie.match( "\\b" + name + "=([^;]*)\\b" );
    return r ? r[ 1 ] : null;
  };

  return (
    <div className="app-container">
      <DwNav />
      <header>
        <h1>{getLocStg("projectName")}</h1>
        <DwTakes take={take} gettake={getTake} heightwin={"75vh"}/>
      </header>
    </div>
  )
}

export default Takes