import React, { useState } from "react";
import axios from "axios";
import { ButtonSecondary } from "../components/forms/Buttons";
import md5 from "blueimp-md5";
import { TextInput } from "../components/forms/TextInputs";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';

const RegisterUser = () =>{
	// eslint-disable-next-line
	var reLargo = /^(([^<>()\[\]\\.,;:\s@”]+(\.[^<>()\[\]\\.,;:\s@”]+)*)|(“.+”))@((\[[0–9]{1,3}\.[0–9]{1,3}\.[0–9]{1,3}\.[0–9]{1,3}])|(([a-zA-Z\-0–9]+\.)+[a-zA-Z]{2,}))$/
	let navigate = useNavigate();
	const [currentName, setName] = useState('');
	const [last_name, setLast_name] = useState('');
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [repassword, setRepassword] = useState('');
	const [getType, setType] = useState("password");
	const [getTypeValidate, setTypeValidate] = useState("password");

	// const returnHeaders = function(){
	// 	var token = getCookie( 'token' );
	// 	let headerProposal ={headers: {'X-BP-Token': token}};
	// 	return headerProposal;
	// }
	// const getCookie = function( name ) {
	// 	var r = document.cookie.match( "\\b" + name + "=([^;]*)\\b" );
	// 	return r ? r[ 1 ] : null;
	// }
	const CreateOrgAPI = async()=>{
		if(currentName === '' || last_name === ''){
			alert("Please fill form");
			return;
		}
		if(!reLargo.test(email)){
			alert("email invalid");
			return;
		}
		if(password !== repassword){
			alert("password not match");
			return;
		}
		let json = {"name": currentName, "last_name": last_name, "email": email, "pass": md5(password)}
		try {
			await axios.post( window.domain +"user", json).then(res => {
				alert(res.data.msg);
				navigate("/login");
			});
		} 
		catch (error) {
			alert(error.response.data.error);
			return;
		}
	}
	const validatePassword =(value)=>{
		setRepassword(value);
	}
	const returnWrongPassword=()=>{
		if(repassword !== "" && password !== repassword){
			return( <h6 className={"mb-4 "}>Password not match!</h6> ) 
		}
	}
	const updateType = async () =>{
		setType((getType === "password") ? "text" : "password");
	}
	const updateTypeValidate = async () =>{
		setTypeValidate((getTypeValidate === "password") ? "text" : "password");
	}
	return (
		<div className = "row d-flex justify-content-center align-items-center h-100">
			<div className="col-12 col-md-9 col-lg-7 col-xl-5">
				<div className="" style={{borderRadius: "1rem"}}>
					<div className="card-body p-5 " >
						<div className="mb-md-5 mt-md-4 pb-5">
							<h1 className="text-center" style={ { color: "#ffffff", } }>Register</h1>
							<div className="row">
								<div className="col-lg-6"><TextInput className={"width-100 mb-4"} required={false} placeholder="Name" onChange={(e) => setName(e.target.value)} /></div>
								<div className="col-lg-6"><TextInput className={"width-100 mb-4"} required={false} placeholder="Last Name" onChange={(e) => setLast_name(e.target.value)} /></div>
							</div>
							<TextInput className={"width-100 mb-4"} required={false} placeholder="Enter email" onChange={(e) => setEmail(e.target.value)} />
							{/* <TextInput type="password" className={"width-100 mb-4"} required={false} placeholder="Enter Password" onChange={(e) => setPassword(e.target.value)} />
							<TextInput type="password" className={"width-100 mb-4"} required={false} placeholder="Repeat Password" onChange={(e) => { validatePassword(e.target.value) }} /> */}
							<div className="input-group mb-3">
								<input type={getType} required={false} className="form-control dw-input" placeholder="Enter Password" aria-label="password" aria-describedby="button-addon2"  onChange={(e) => setPassword(e.target.value)} />
								<button onClick={()=>{updateType()}} className="btn btn-outline-secondary dw-input" type="button" id="button-addon2"><FontAwesomeIcon icon={(getType === "password") ? faEyeSlash : faEye}/></button>
							</div>
							<div className="input-group mb-3">
								<input type={getTypeValidate} required={false} className="form-control dw-input" placeholder="Repeat Password" aria-label="password" aria-describedby="button-addon2"  onChange={(e) => { validatePassword(e.target.value) }} />
								<button onClick={()=>{updateTypeValidate()}} className="btn btn-outline-secondary dw-input" type="button" id="button-addon2"><FontAwesomeIcon icon={(getTypeValidate === "password") ? faEyeSlash : faEye}/></button>
							</div>
							{returnWrongPassword()}							
							<ButtonSecondary className="width-100 mb-3" label="Create" action={()=>{ CreateOrgAPI() }}/>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
export default RegisterUser;