import React, { useState, useEffect } from "react";
import DwNav from "../components/DwNav";
import axios from "axios";

import {DropDown} from "../components/forms/TextInputs";
import {ButtonPrimary} from "../components/forms/Buttons"

import {ColorPickerButton} from "../components/forms/ColorPickerButton"
import Swal from 'sweetalert2/dist/sweetalert2.js'


const UpdateColors=(props)=>{

    const [getProjects, setProjects] = useState([]);
    const [getSelectedProject, setSelectedProject] = useState();

    const [letterList, setLetterList] = useState([]);
    const [letterSelected, setLetterSelected] = useState();

    const [color, setColor] = useState();
    

    useEffect(()=>{
        getProjectsList();
        // eslint-disable-next-line
    },[]); 
    useEffect(()=>{
        if(getSelectedProject !== undefined && getSelectedProject !== null){
            fillLetterList();            
        }
        // eslint-disable-next-line
    },[getSelectedProject]);

    const getCookie = function( name ){
        var r = document.cookie.match( "\\b" + name + "=([^;]*)\\b" );
        return r ? r[ 1 ] : null;
    }
    const returnHeaders = () =>{
        var token = getCookie( 'token' );
        let headerProposal ={ headers: { 'X-BP-Token': token } };
        return headerProposal;
    }
    const getProjectsList = async() =>{
        await axios.get( window.domain+"project?oid=" + localStorage.getItem("oid"), returnHeaders()).then(response=>{
            var newArray = [];
            response["data"].forEach(element => {
              newArray.push({value:element.pid, label:element.name});
            });
            setProjects(newArray);
          });
    }   
    const fillLetterList = () =>{
        let list = [];
        for (let index = 0; index < 26; index++) {
            let letter = (index + 10).toString(36).toUpperCase();
            list.push( {value:letter, label:letter, color:"#ffffff"} );
        }
        getCameraColors(list);
    }
    const getCameraColors = async(list) =>{
        await axios.get( window.domain+"cameraColor?pid=" + getSelectedProject.value, returnHeaders()).then(response=>{
            list.forEach(element =>{
                let colorObject = "#ffffff";
                try {
                    colorObject = response["data"].find(x => x.letter === element.label).color;
                  } catch (error) {
                    colorObject = "#ffffff";
                  }
                element.color = colorObject ;
            });
            setLetterList(list);
          });
    }
    const createNewCamera = async() =>{
        let json = {
            "pid":getSelectedProject.value,
            "letter":letterSelected.value,
            "color":color
        }
        await axios.post( window.domain + "cameraColor", json, returnHeaders()).then(response=>{
            if(response.data.success){
                Swal.fire({ title: 'Updated', text: "Camera: " + letterSelected.value, confirmButtonText: 'OK', timer: 1000 });
            }
        });
    }
    const updateValues = (e) =>{
        setLetterSelected(e);
        setColor(e.color);
    }
    return(
        <>
            <DwNav/>
            <div className= "container" >
                <div>
                    <div className="col-xl-6 col-sm-12">
                        <h1>Colors</h1>
                    </div>
                </div>
                <div className="row">
                    
                    <div className="col-xl-6 col-sm-12">
                        <label className="form-label"><span>Project:</span></label>
                        <DropDown required={true} options={getProjects} value={getSelectedProject} onChange={(e)=>setSelectedProject(e)} placeholder="Select project"/>
                    </div>
                    <div className="col-xl-2 col-sm-3">
                        <label className="form-label"><span>Letter:</span></label>
                        <DropDown required={false} options={letterList} value={letterSelected} onChange={(e)=>{updateValues(e)} } placeholder="Select letter"/>
                    </div>
                    <div className="col-xl-1 col-sm-3">
                        <label className="form-label"><span>Color:</span></label>
                        <ColorPickerButton color={color} setColor={setColor}/>
                    </div>
                    <div className="col-xl-3 col-sm-6">
                        <label className="form-label"><span>&nbsp;</span></label>
                        <ButtonPrimary className="width-100" label={"Update"} action={()=>{createNewCamera()}}/>
                    </div>
                </div>
            </div>
        </>
    );
}

export default UpdateColors;