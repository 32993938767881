
import { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import {TextInput, DateInput, TextArea} from "../components/forms/TextInputs";
import { ButtonSecondary} from "./forms/Buttons";
import Swal from 'sweetalert2/dist/sweetalert2.js';
import axios from "axios";

const DwModalPrint = (props) => {
  const [to, setTo] = useState('');
  const [from, setfrom] = useState('');
  const [datesent,setDateSent] = useState(new Date());
  const [re, setRe] = useState('');
  const [version, setversion] = useState('');
  const [comments, setcomments] = useState('');
  // const [backstory, setBackstory] = useState('');

  const isdiferent =function(item){
    if(item !== '' && item !=="-" && item !== null && item !== undefined){
      return true;
    }
    return false;
  }  
  const returnHeaders = function(){
    var token = getCookie( 'token' );
    let headerProposal ={headers: {'X-BP-Token': token}, responseType: 'blob'};
    return headerProposal;
  }
  const getCookie = function( name ) {
    var r = document.cookie.match( "\\b" + name + "=([^;]*)\\b" );
    return r ? r[ 1 ] : null;
  }
  const create = async ()=>{
    if(isdiferent(props.data.pid) === false || isdiferent(to) === false || isdiferent(from) === false ||  isdiferent(datesent) === false || isdiferent(re) === false){
      Swal.fire({
        title: 'Required fields are empty'
      }).then((result) => {})
    }
    else{
      try {
        var SaveFormat = new Intl.DateTimeFormat('az',{year:'numeric', month:'numeric', day:'numeric' });
        let json = props.data;
        if(isdiferent(to)){json.to = to;}
        if(isdiferent(from)){json.from = from;}
        if(isdiferent(SaveFormat.format(datesent))){json.created = SaveFormat.format(datesent);}
        if(isdiferent(re)){json.re = re;}
        if(isdiferent(version)){json.ver = version;}
        if(isdiferent(comments)){json.comment = comments;}
        // if(isdiferent(backstory)){json.backstory = backstory;}
        await axios.post(window.domain+props.url, json, returnHeaders()).then(res => {
          window.open(URL.createObjectURL(res.data))
          props.onHide();
        });
      } catch (error) {
        
      }      
    }   
  }
  return (
    <Modal show={props.modalPrint} onHide={() =>{ props.onHide();}} aria-labelledby="contained-modal-title-vcenter" centered >      
      <Modal.Body>
      <div className="row mb-4">
      <div className = "col-11">
        <h4>{props.title}</h4>
      </div>
      <div className = "col-1" onClick={props.onHide}>
        <h5 className="text-right">X</h5>
      </div>
      </div>      
        <div className="row">
            <div className="col-lg-12">
              <span>To:</span><br></br>
              <TextInput required={true} onChange={(e)=>setTo(e.target.value)} placeholder="To" className="width-100"/>
            </div>
            <div className="col-lg-12">
              <span>From:</span><br></br>
              <TextInput required={true} onChange={(e)=>setfrom(e.target.value)} placeholder="From" className="width-100"/>
            </div>
            <div className="col-lg-12">
              <span>Date sent:</span><br></br>
              <DateInput setStartDate={setDateSent} required={true} startDate={datesent} className="width-100"/>
            </div>
            <div className="col-lg-9">
              <span>Re.:</span><br></br>
              <TextInput required={true} onChange={(e)=>setRe(e.target.value)} placeholder="Re." className="width-100"/>
            </div>
            <div className="col-lg-3">
              <span>Version:</span><br></br>
              <TextInput required={false} onChange={(e)=>setversion(e.target.value)} placeholder="Version" className="width-100"/>
            </div>
            <div className="col-lg-12 mb-4">
              <span>Comments:</span><br></br>
              <TextArea required={false} onChange={(e)=>setcomments(e.target.value)} placeholder="Comments" className="width-100 tall-Comments"/>
            </div>
            {/* <div className="col-lg-12">
              <span>Backstory:</span><br></br>
              <TextArea required={false} onChange={(e)=>setBackstory(e.target.value)} placeholder="INTERNAL USE ONLY ( this will never be seen by clients )" className="width-100 tall-Comments"/>
            </div>   */}
            <div className="col-sm-12">
              < ButtonSecondary className="width-100" label={props.btnLabel} action={() =>{create()}} required={true}/>
            </div>
        </div>
      </Modal.Body>
    </Modal>
  )
}

DwModalPrint.defaultProps = {show:false, onHide:null};
export {DwModalPrint}