import React from "react";
import Modal from 'react-bootstrap/Modal'
import DwCompare from"../components/DwCompare"

const DwAdvanceOption = (props) => {
  return (
    <Modal show={props.show} onHide={() =>{ props.setcurrentselection(""); props.onHide();}}      
      dialogClassName="dw-modal-90w"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <DwCompare 
          update={props.update}
          colors={props.colors}
          show={props.show} 
          onHide={props.onHide} 
          tstop={props.tstop}
          tilts ={props.tilts}
          resolution ={props.resolution}
          fps ={props.fps}
          shutter ={props.shutter}
          dollydistance ={props.dollydistance}
          height ={props.height}
          whitebalance ={props.whitebalance}
          distance ={props.distance}
          iso ={props.iso}
          selectedtake={props.selectedtake}
          focal_length={props.focal_length}
          tilt={props.tilt}
          significantobj ={props.significantobj}
          filtters={props.filtters}
          productioncam={props.productioncam}
          lens={props.lens}
          gettake={props.gettake}
          secondTake={props.secondTake}
          render_again={props.render_again}
          take={props.take}
          setcurrentselection={props.setcurrentselection}
          currentSelection={props.currentSelection}
          cameras={props.cameras}
          ProductionCameras={props.ProductionCameras}
          />
      </Modal.Body>
    </Modal>
  );
}

DwAdvanceOption.defaultProps = {
  show: false,
  onHide: null
}

export {DwAdvanceOption}