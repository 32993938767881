import React, { useState ,useEffect } from "react";
import axios from "axios";
import { ButtonSecondary, ButtonPrimary } from "./forms/Buttons";
import { TextInput,  DropDown, TextArea} from "../components/forms/TextInputs";
import Swal from 'sweetalert2/dist/sweetalert2.js'
import { DwGenericModal } from "../components/DwGenericModal";

import Chip from '@mui/material/Chip';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import hexRgb from 'hex-rgb';
import Select from "react-select";

const DwCompare = (props) =>{
  // const [copyTake, setCopyTake] = useState({});
  const [imageModal, setImageModal] = useState(false);
  const [take, setTake] = useState(props.secondTake[0]);
  const [takes, setTakes] = useState(props.secondTake);
  const [keyMap, setKeyMap]=useState();
  const [unit, setUnit] = useState("ft");
  const [cameraProduction, setCameraProduction] = useState("ft");
  
 
  useEffect(()=>{
    setTakes(props.secondTake);
  },[props.secondTake])

  const getCookie = function( name ) {
    var r = document.cookie.match( "\\b" + name + "=([^;]*)\\b" );
    return r ? r[ 1 ] : null;
  }  
  const returnHeaders = function(){
    var token = getCookie('token');
    let headerProposal ={headers:{'X-BP-Token': token}};
    return headerProposal;
  }
  const updateTake=async(tkid, value, name)=>{
    let auxTakes = [...takes];
    let take = auxTakes.find(x => x.tkid === tkid);    
    let val = value;
    if(value.label !== undefined){val = value.label;}
    let json = {"tkid" : tkid, "uid": parseInt(localStorage.getItem("uid"))};
    val = (name === "tilt") ? val.replace("+","").replace(" ","") : val;
    json[name] = val;
    take[name] = val;
    try {
      await axios.put( window.domain + "take",json,returnHeaders()).then(response => {
        if(name === "camera"){ props.gettake(); }
        const i = auxTakes.findIndex(x => x.tkid === tkid)
        auxTakes[i] = take;
        setTakes(auxTakes);
      });
    }
    catch{ }
  }
  // const updateTake=async(tkid,value,name)=>{
  //   let json = {}
  //   switch(name){
  //     case "number":
  //       json = {
  //         "tkid" : tkid,
  //         "uid": parseInt( localStorage.getItem("uid")),
  //         "number": value
  //       }
  //     break; 
  //     case "camera":
  //       json = {
  //         "tkid" : tkid,
  //         "uid": parseInt( localStorage.getItem("uid")),
  //         "camera": value
  //       }
  //     break; 
  //     case "image":
  //       json = {
  //         "tkid" : tkid,
  //         "uid": parseInt( localStorage.getItem("uid")),
  //         "image": value
  //       }
  //     break;
  //     case "focal_length":
  //       json = {
  //         "tkid" : tkid,
  //         "uid": parseInt( localStorage.getItem("uid")),
  //         "focal_length": value
  //       }
  //     break;
  //     case "lid":
  //       json = {
  //         "tkid" : tkid,
  //         "uid": parseInt( localStorage.getItem("uid")),
  //         "lid": value
  //       }
  //     break;
  //     case "sig_object":
  //       json = {
  //         "tkid" : tkid,
  //         "uid": parseInt( localStorage.getItem("uid")),
  //         "sig_object": value
  //       }
  //     break; 
  //     case "distance":
  //       json = {
  //         "tkid" : tkid,
  //         "uid": parseInt( localStorage.getItem("uid")),
  //         "distance": value
  //       }
  //     break;
  //     case "tilt":
        
  //       value = value.replace("+","")
  //       value = value.replace(" ","")
  //       json = {
  //         "tkid" : tkid,
  //         "uid": parseInt( localStorage.getItem("uid")),
  //         "tilt": parseInt(value)
  //       }
  //     break;
  //     case "height":
  //       json = {
  //         "tkid" : tkid,
  //         "uid": parseInt( localStorage.getItem("uid")),
  //         "height": value
  //       }
  //     break;
  //     case "dolly_dist":
  //       json = {
  //         "tkid" : tkid,
  //         "uid": parseInt( localStorage.getItem("uid")),
  //         "dolly_dist": value
  //       }
  //     break;
  //     case "scid":
  //       json = {
  //         "tkid" : tkid,
  //         "uid": parseInt( localStorage.getItem("uid")),
  //         "scid": value
  //       }
  //     break;
  //     case "resolution":
  //       json = {
  //         "tkid" : tkid,
  //         "uid": parseInt( localStorage.getItem("uid")),
  //         "resolution": value
  //       }
  //     break; 
  //     case "t_stop":
  //       json = {
  //         "tkid" : tkid,
  //         "uid": parseInt( localStorage.getItem("uid")),
  //         "t_stop": value
  //       }
  //     break;
  //     case "fps":
  //       json = {
  //         "tkid" : tkid,
  //         "uid": parseInt( localStorage.getItem("uid")),
  //         "fps": value
  //       }
  //     break;
  //     case "white_balance":
  //       json = {
  //         "tkid" : tkid,
  //         "uid": parseInt( localStorage.getItem("uid")),
  //         "white_balance": value
  //       }
  //     break;
  //     case "shutter":
  //       json = {
  //         "tkid" : tkid,
  //         "uid": parseInt( localStorage.getItem("uid")),
  //         "shutter": value
  //       }
  //     break;
  //     case "iso":
  //       json = {
  //         "tkid" : tkid,
  //         "uid": parseInt( localStorage.getItem("uid")),
  //         "iso": value
  //       }
  //     break;
  //     case "filter_on_lens":
  //       json = {
  //         "tkid" : tkid,
  //         "uid": parseInt( localStorage.getItem("uid")),
  //         "filter_on_lens": value
  //       }
  //     break;  
  //     case "filters_to_add":
  //       json = {
  //         "tkid" : tkid,
  //         "uid": parseInt( localStorage.getItem("uid")),
  //         "filters_to_add": value
  //       }
  //     break;
  //     case "notes":
  //       json = {
  //         "tkid" : tkid,
  //         "uid": parseInt( localStorage.getItem("uid")),
  //         "notes": value
  //       }
  //     break;
  //     default:
  //       // do nothing
  //       break;
  //   }

  //   try {
  //     await axios.put( window.domain+"take",json,returnHeaders()).then(res => {});
  //   }
  //   catch{
  //   }
  // }
  // const multiDropDownValues=(option, values)=>{
  //   var variable =[];
  //   let valueReturn=[]
  //   if(option === null){
  //     return
  //   }
  //   if(option.includes(",")){
  //     valueReturn=option.split(",");
  //     if(valueReturn[i])
  //     for(var i = 0; i <= valueReturn.length; i++){
  //         for(var x = 0; x <= values.length; x++){
  //           if(values[x]){
  //             if(valueReturn[i] === values[x].label){
  //               variable.push(values[x])
  //             }
  //           }
  //         }
  //     }
  //   }
  //   else{
  //     for(var i = 0; i <= values.length; i++){
  //       if(values[i]){
  //         if(option === values[i].label){
  //           variable.push(values[i])
  //         }
  //       }
  //     }
  //   }
  //   if(typeof variable !== "undefined"){
      
  //   }
  //   else{
  //       setTimeout(variable, 250);
  //   }
  //   return variable;
  // }
  const defaultTake =(index)=>{
    var optionsArray ={value:index, label:(index).toString()}
    return optionsArray;
  }
  const returndefaultValue=(options, value)=>{
    let valueReturn={value:value, label:value};
    for(var i = 0; i <= options.length; i++){
      if(options[i]){
        if(options[i].label.toString() === value.toString()){
          valueReturn= options[i];
        }
      }
    }
    // if(valueReturn === {}){
    //   valueReturn = {value:value, label:value.toString()}
    // }
    return valueReturn;
  }
  const returndefaultCam=(options, value)=>{
    let valueReturn={value:"", label:""};
    for(var i = 0; i <= options.length; i++){
      if(options[i]){
        if(options[i].value.toString() === value.toString()){
          valueReturn= options[i];
        }
      }
    }
    // if(valueReturn === {}){
    //   valueReturn = {value:value, label:value.toString()}
    // }
    return valueReturn;
  }
  const productionCameraValue=(options, value)=>{
    let object = options.find( obj => obj.value === value );
    // let valueReturn={};
    // for(var i = 0; i <= options.length; i++){
    //   if(options[i]){
    //     if(options[i].value === value){
    //       valueReturn= options[i];
    //     }
    //   }

    // }
    // if(valueReturn === {}){
    //   valueReturn = {value:value, label: options[i].label }
    // }  
    // return valueReturn;
    return object;
  }
  // const returndefaultValueTilt=(options, value)=>{
  //   let valueReturn={};
  //   for(var i = 0; i <= options.length; i++){
  //     if(options[i]){
  //       let numberVal = options[i].label.toString()
  //       numberVal = numberVal.replace("+","")
  //       numberVal = numberVal.replace(" ","")
  //       if(parseInt(numberVal) === value){
  //         valueReturn= options[i];
  //       }
  //     }

  //   }
  //   if(valueReturn === {}){
  //     valueReturn = {value:value, label:value.toString()}
  //   }  
  //   return valueReturn;
  // }
  // const returndefaultValueFocalL=(options, value)=>{
  //   let valueReturn={};
  //   for(var i = 0; i <= options.length; i++){
  //     if(options[i]){
  //       if(options[i].label.toString() === value.toString()+"mm"){
  //         valueReturn= options[i];
  //       }
  //     }

  //   }
  //   if(valueReturn === {}){
  //     valueReturn = {value:value, label:value.toString()}
  //   }  
  //   return valueReturn;
  // }
  const defaultCamera =(camera)=>{
    var optionsArray ={value:camera, label:camera}
    return optionsArray;
  }
  const fillOptionsNumber = function(){
    var optionsArray =[]
    for(var i = 1; i <= 200; i++) {
      optionsArray.push({value:i, label:i.toString()})
    }
    return optionsArray;
  }
  // const backCameraColor=(camera)=>{
  //   let value = 0
  //   value = (camera.charCodeAt(0) - 65)
  //   return value;
  // }
  const fillOptionsAlfabet = function(){
    var optionsArray =[]
    optionsArray.push({value:-1, label:""})
    for(var i = 0; i <= 25; i++) {
      optionsArray.push({value:(i+10).toString(36).toUpperCase(), label:(i+10).toString(36).toUpperCase()})
    }
    return optionsArray;
  }
  const multiChange = (tkid, value, field)=>{
    let newValue = [];    
    if(["distance", "dolly_dist", "height"].includes(field) && value[0] !== undefined ){
      let pop = value.pop();
      if(!isNaN(pop)){
        pop = pop + (unit === "ft" ? "'" : "m");
      }      
      value.push(pop);
    }
    if(field === "tilt" && value[0] !== undefined){
      let pop = value.pop();
      pop = pop + "°";
      value.push(pop);
    }
    newValue = value.join(",");
    updateTake(tkid, newValue, field);
  }
  const returnSplit=(options)=>{
    if (options !== null && options !== ""){
      return options.toString().split(",");
    }
    return;
  }
  // const imageExists=async(image_url)=>{
  //   try {
  //     const res = await fetch(image_url, { method: "HEAD" });
  //     if (res.ok) {
  //       return true;
  //     } else {
  //       return false;
  //     }
  //   } catch (error) {
  //     return false
  //   }
  // }
  const _update=()=>{
    props.update()
  }
  const openModalImage = function(take, id){
    setKeyMap(id);
    setTake(take);
    setImageModal(true);
  }
  // const returnImage=(take)=>{
  //   if(take.image !== ""){
  //     return <img src={take.image} className="width-100 mb-2" onClick={()=>{openModalImage(take)}} alt=""></img>
  //   }
  //   else{
  //     return <img src= window.domainBase + "/img/placeholder.png" className="width-100 mb-2" alt="" onClick={()=>{openModalImage(take)}}></img>
  //   }
  // }
  const keyboardEvent = new KeyboardEvent('keydown', { code: 'Enter', key: 'Enter', charCode: 13, keyCode: 13, view: window, bubbles: true});
  const enterFunction = function(e, value){
    if(![undefined, "", null].includes(e.target.value)){
      e.target.dispatchEvent(keyboardEvent);
    }
  }
  const copyTakeOptions=(Stake)=>{
    Swal.fire({
      title: "Are you sure to copy this take?",
      showDenyButton: true,
      confirmButtonText: "Yes",
      denyButtonText: "No",
    }).then((result) => {
      if (result.isConfirmed) {
        localStorage.setItem("selectedTake", JSON.stringify(Stake));
        // setCopyTake(Stake);
      }
    })
  }
  const pasteTakeOptions=async(Stake)=>{
    if(localStorage.getItem("selectedTake") === ""){
      return
    }
    let data = JSON.parse(localStorage.getItem("selectedTake"));
    console.log(data)
    const json = {
      "tkid": Stake.tkid,
      "uid": Stake.uid,
      "camera": Stake.camera,
      "image": Stake.image,
      "focal_length": data.focal_length,
      "lid": data.lid,
      "sig_object": data.sig_object,
      "distance": data.distance,
      "tilt": data.tilt,
      "height": data.height,
      "dolly_dist": data.dolly_dist,
      "scid": data.scid,
      "resolution": data.resolution,
      "t_stop": data.t_stop,
      "fps": data.fps,
      "white_balance": data.white_balance,
      "shutter": data.shutter,
      "iso": data.iso,
      "filter_on_lens": data.filter_on_lens,
      "filters_to_add": data.filters_to_add,
      "notes": data.notes
    }
    try {
        await axios.put( window.domain+"take",json,returnHeaders()).then(response => {
          props.gettake();
          localStorage.setItem("selectedTake", "");
        });
    }
    catch{
    }
  }
  const blankTake=async(Stake)=>{
    let maxValue = 0;
    for(var i = 0; i <= props.take.length; i++) {
      if(props.take[i]){
        if (maxValue < props.take[i].number){          
          maxValue = props.take[i].number;
        }
      }      
    }
    const json = {
      "number": maxValue+1,
      "uid": parseInt( localStorage.getItem("uid")),
      "sid": parseInt( localStorage.getItem("sid")),
      "camera": Stake.camera,
      "image": "",
      "focal_length": "",
      "lid": 1,
      "sig_object": "",
      "distance": "",
      "tilt": "",
      "height": "",
      "dolly_dist": "",
      "scid": 1,
      "resolution": "",
      "t_stop": "",
      "fps": 24,
      "white_balance": 0,
      "shutter": 0,
      "iso": "",
      "filter_on_lens": "",
      "filters_to_add": "",
      "notes": ""
    }
    try {
      await axios.post( window.domain+"take",json,returnHeaders()).then(response => {       
        props.gettake();
      });
    }
    catch{}
    showMessageTake();
  }
  const nextTake=async(Stake)=>{
    // let maxValue = 0;
    // for(var i = 0; i <= props.take.length; i++) {
    //   if(props.take[i]){
    //     if (maxValue < props.take[i].number){
    //       maxValue = props.take[i].number;
    //     }
    //   }
    // }
    // const json = {
    //   "number": maxValue +1,
    //   "uid": parseInt( localStorage.getItem("uid")),
    //   "sid": parseInt( localStorage.getItem("sid")),
    //   "camera": Stake.camera,
    //   "image": Stake.image === window.domainBase + "/img/placeholder.png" ? "" : Stake.image,
    //   "focal_length": Stake.focal_length,
    //   "lid": Stake.lid,
    //   "sig_object": Stake.sig_object,
    //   "distance": Stake.distance,
    //   "tilt": Stake.tilt,
    //   "height": Stake.height,
    //   "dolly_dist": Stake.dolly_dist,
    //   "scid": Stake.scid,
    //   "resolution": Stake.resolution,
    //   "t_stop": Stake.t_stop,
    //   "fps": Stake.fps,
    //   "white_balance": Stake.white_balance,
    //   "shutter": Stake.shutter,
    //   "iso": Stake.iso,
    //   "filter_on_lens": Stake.filter_on_lens,
    //   "filters_to_add": Stake.filters_to_add,
    //   "notes": Stake.notes
    // }
    // try {
    //   await axios.post( window.domain+"take",json,returnHeaders()).then(response => {
    //     props.gettake();
    //   });
    // }
    // catch{}
    let maxValue = 1;
    // let nextLetter = "A"
    let cameras = [];
    for(var i = props.take.length; i >= 0; i--) {
      if(props.take[i]){
        if (maxValue < props.take[i].number){
          maxValue = props.take[i].number;
        }
        // eslint-disable-next-line no-loop-func
        if ( !cameras.filter(e => e.camera === props.take[i].camera ).length > 0 ){
          cameras.push(props.take[i])
        }
      }
    }
    let json ={}
    for(var x = 0; x < cameras.length; x++){
        if (cameras[x].number === maxValue){
          json = {
            "number": maxValue +1,
            "uid": parseInt( localStorage.getItem("uid")),
            "sid": parseInt( localStorage.getItem("sid")),
            "camera": cameras[x].camera,
            "image":  cameras[x].image === window.domainBase + "/img/placeholder.png" ? "" : cameras[x].image,
            "focal_length":  cameras[x].focal_length,
            "lid": cameras[x].lid,
            "sig_object": cameras[x].sig_object,
            "distance": cameras[x].distance,
            "tilt": cameras[x].tilt,
            "height": cameras[x].height,
            "dolly_dist": cameras[x].dolly_dist,
            "scid": cameras[x].scid,
            "resolution": cameras[x].resolution,
            "t_stop": cameras[x].t_stop,
            "fps": cameras[x].fps,
            "white_balance": cameras[x].white_balance,
            "shutter": cameras[x].shutter,
            "iso": cameras[x].iso,
            "filter_on_lens": cameras[x].filter_on_lens,
            "filters_to_add": cameras[x].filters_to_add,
            "notes": cameras[x].notes
          }
          try {
            await axios.post( window.domain + "take", json,returnHeaders()).then(response => {
              props.gettake();
             });
          }
          catch{ }
        }
      }
      showMessageTake();
  }
  const showMessageTake = () =>{
    Swal.fire({
      title: "New takes created",
      text: "New takes may may not be visible due to filtering, do you wan to go back and see them?",
      showDenyButton: true,
      confirmButtonText: "Take me to SETUPS ",
      denyButtonText: "Stay here",
    }).then((result) => {
      if (result.isConfirmed) {
        props.onHide();
      }
    })
  }
  const getColor = (letter) =>{
    // let color = props.colors.find((obj) => obj.value === letter).color;
    let color = props.cameras.find((obj) => obj.value === letter).color;
    return color;
  }
  const getProductionCamera = (letter) =>{
    let pcid = parseInt( props.cameras.find((obj) => obj.value === letter).pcid);
    let camera = props.ProductionCameras.find((cam) => cam.value === pcid);
    return camera.label
  }
  const labelColor = (color) =>{
    let rgb = hexRgb(color.replace("#",""));
    var o = Math.round(((parseInt(rgb.red) * 299) + (parseInt(rgb.green) * 587) + (parseInt(rgb.blue) * 114)) /1000);
    return o > 125 ? "#000000" : "#FFFFFF";
  }
  const updateUnit=()=>{
    let newUnit = unit === "ft" ? "m" : "ft";
    setUnit(newUnit);
    let name = newUnit === "m" ? "metric" : "imperial";
    props.updateUnits(name);
  }
  const Options=(key)=>{
    // const [cam, setCam] = useState(key.camera);
    return(
      <div id={key.tkid}  className="col-xl-6 mb-3 inLineScroll" key={key.tkid}>
         <div className={"card p-3 "} style={{backgroundColor: getColor(key.camera)}}>{/* style={{ backgroundColor: getColor(cam)}} */}
        <div className="row  mb-2">
            <div className="col-xl-3" style={{display:"flex"}}>
              <div className="col-xl-6">
                <span style={{ color: labelColor(getColor(key.camera))}}>CAMERA</span>
                <DropDown className={"width-100"} onChange={(e) => { updateTake(key.tkid, e.value,"camera")}} required={true} options={props.cameras} defaultValue={defaultCamera(key.camera)}/>
              </div>
              <div className="col-xl-6">
                <span style={{ color: labelColor(getColor(key.camera))}}>TAKE</span>
                <DropDown className={"width-100"} onChange={(e) => {updateTake(key.tkid, e.value,"number")}} required={true} options={fillOptionsNumber()} defaultValue={defaultTake(key.number)}/>
              </div>
            </div>
            {/* <div className="col-xl-1">
              <span style={{ color: labelColor(getColor(key.camera))}}>CAMERA</span>
              <DropDown className={"width-100"} onChange={(e) => { updateTake(key.tkid, e.value,"camera")}} required={true} options={fillOptionsAlfabet()} defaultValue={defaultCamera(key.camera)}/>
            </div>
            <div className="col-xl-2">
              <span style={{ color: labelColor(getColor(key.camera))}}>TAKE</span>
              <DropDown className={"width-100"} onChange={(e) => {updateTake(key.tkid, e.value,"number")}} required={true} options={fillOptionsNumber()} defaultValue={defaultTake(key.number)}/>
            </div> */}
            <div className="col-xl-2">
              <span>&nbsp;</span>
              <ButtonSecondary className={"width-100"} label="1-Copy Take" action={()=>{copyTakeOptions(key)}}/>
            </div>
            <div className="col-xl-3">
              <span>&nbsp;</span>
              <ButtonSecondary className={"width-100"} label="2- Create Blank Take" action={()=>{blankTake(key)}}/>
            </div>
            <div className="col-xl-2">
              <span>&nbsp;</span>
              <ButtonSecondary className={"width-100"} label="3-Paste Take" action={()=>{pasteTakeOptions(key)}}/>
            </div>            
            <div className="col-xl-2">
              <span>&nbsp;</span>
              <ButtonSecondary className={"width-100"} label="Next Take"  action={()=>{nextTake(key)}}/>
            </div>
          </div>
          <div className="row">
            <div className="col-xl-6 align-self-center mb-2">
              <img id={"img_" + key.tkid} src={take.image || window.domainBase + "/img/placeholder.png"} className="width-100 mb-2 ratio" onClick={()=>{openModalImage(take, key.tkid)}} alt=""></img>
            </div>
            <div className="col-xl-6 align-self-center mb-3">
              <div className="mb-3">
                <span style={{ color: labelColor(getColor(key.camera))}}>FOCAL LENGTH</span>
                <Autocomplete onBlur={(e)=>{enterFunction(e)}} className="autocomplete important" onChange={(event, value)  => {multiChange(key.tkid, value,"focal_length")}} multiple limitTags={5} id="multiple-limit-tags" defaultValue={returnSplit(key.focal_length)} options={props.focal_length.map((option) => option.label.toString())} freeSolo renderTags={(value, getTagProps) => value.map((option, index) => (<Chip variant="outlined" label={option} {...getTagProps({ index })}/>))} renderInput={(params) => (<TextField {...params} variant="filled" placeholder="" />)}/>
                {/* <DropDown className={"width-100"} required={true} onChange={(e) => {updateTake(key.tkid, e.value,"focal_length")}} options={props.focal_length} defaultValue={returndefaultValueFocalL(props.focal_length, key.focal_length)}/> */}
              </div>
              <div className="mb-3">
                <span style={{ color: labelColor(getColor(key.camera))}}>LENS</span>
                <DropDown className={"width-100"} required={true} onChange={(e) => {updateTake(key.tkid, e.value,"lid")}} options={props.lens} defaultValue={productionCameraValue(props.lens,key.lid)}/>
              </div>
              <div className="mb-3">
                <span style={{ color: labelColor(getColor(key.camera))}} onClick={()=>updateUnit()}>DISTANCE ({unit})</span>
                <Autocomplete onBlur={(e)=>{enterFunction(e)}} className="autocomplete important" onChange={(event, value)  => {multiChange(key.tkid, value,"distance")}} multiple limitTags={5} id="multiple-limit-tags" defaultValue={returnSplit(key.distance)} options={props.distance.map((option) => option.label.toString())} freeSolo renderTags={(value, getTagProps) => value.map((option, index) => (<Chip variant="outlined" label={option} {...getTagProps({ index })}/>))} renderInput={(params) => (<TextField {...params} variant="filled" placeholder="" />)}/>
                {/* <DropDown className={"width-100"} required={true} onChange={(e) => {updateTake(key.tkid, e.value,"distance")}} options={props.distance} defaultValue={returndefaultValue(props.distance, key.distance)}/> */}
              </div>
              <div className="mb-2">
                <span style={{ color: labelColor(getColor(key.camera))}}>SIGNIFICANT OBJECT</span>
                <Autocomplete onBlur={(e)=>{enterFunction(e)}} className="autocomplete" onChange={(event, value)  => {multiChange(key.tkid, value,"sig_object")}} multiple limitTags={5} id="multiple-limit-tags" defaultValue={returnSplit(key.sig_object)} options={props.significantobj.map((option) => option.label.toString())} freeSolo renderTags={(value, getTagProps) => value.map((option, index) => (<Chip variant="outlined" label={option} {...getTagProps({ index })}/>))} renderInput={(params) => (<TextField {...params} variant="filled" placeholder="" />)}/>
                {/* <DropDown className={"width-100"} required={true} onChange={(e) => {updateTake(key.tkid, e.label,"sig_object")}} options={props.significantobj} defaultValue={returndefaultValue(props.significantobj, key.sig_object)}/> */}
              </div>
            </div>
            <div className="col-xl-12 mb-3">
              <div className="row">
                <div className="col-xl-4"> <span style={{ color: labelColor(getColor(key.camera))}}>TILT (°)</span>
                <Autocomplete onBlur={(e)=>{enterFunction(e)}} className="autocomplete" onChange={(event, value)  => {multiChange(key.tkid, value,"tilt")}} multiple limitTags={5} id="multiple-limit-tags" defaultValue={returnSplit(key.tilt)} options={props.tilt.map((option) => option.label.toString())} freeSolo renderTags={(value, getTagProps) => value.map((option, index) => (<Chip variant="outlined" label={option} {...getTagProps({ index })}/>))} renderInput={(params) => (<TextField {...params} variant="filled" placeholder="" />)}/>
                {/* <DropDown className={"width-100"} onChange={(e) => {updateTake(key.tkid, e.label,"tilt")}} options={props.tilt} defaultValue={returndefaultValueTilt(props.tilt, key.tilt)} />  */}
                </div>
                <div className="col-xl-4"> <span style={{ color: labelColor(getColor(key.camera))}} onClick={()=>updateUnit()}>HEIGHT ({unit})</span>
                <Autocomplete onBlur={(e)=>{enterFunction(e)}} className="autocomplete" onChange={(event, value)  => {multiChange(key.tkid, value,"height")}} multiple limitTags={5} id="multiple-limit-tags" defaultValue={returnSplit(key.height)} options={props.height.map((option) => option.label.toString())} freeSolo renderTags={(value, getTagProps) => value.map((option, index) => (<Chip variant="outlined" label={option} {...getTagProps({ index })}/>))} renderInput={(params) => (<TextField {...params} variant="filled" placeholder="" />)}/>
                {/* <DropDown className={"width-100"} onChange={(e) => {updateTake(key.tkid, e.label,"height")}} options={props.height} defaultValue={returndefaultValue(props.height, key.height)}/>  */}
                </div>
                <div className="col-xl-4"> <span style={{ color: labelColor(getColor(key.camera))}} onClick={()=>updateUnit()}>DOLLY DISTANCE ({unit})</span>
                <Autocomplete onBlur={(e)=>{enterFunction(e)}} className="autocomplete" onChange={(event, value)  => {multiChange(key.tkid, value,"dolly_dist")}} multiple limitTags={5} id="multiple-limit-tags" defaultValue={returnSplit(key.dolly_dist)} options={props.dollydistance.map((option) => option.label.toString())} freeSolo renderTags={(value, getTagProps) => value.map((option, index) => (<Chip variant="outlined" label={option} {...getTagProps({ index })}/>))} renderInput={(params) => (<TextField {...params} variant="filled" placeholder="" />)}/>
                
                {/* <DropDown className={"width-100"} onChange={(e) => {updateTake(key.tkid, e.label,"dolly_dist")}}  height options={props.dollydistance} defaultValue={returndefaultValue(props.dollydistance, key.dolly_dist)} />  */}
                </div>
              </div>
            </div>
            
          <hr style={{border:"2px", color:"white"}}></hr>
            <div className="col-xl-12 mb-3">
              <div className="row">
                <div className="col-xl-6"> <span style={{ color: labelColor(getColor(key.camera))}}>PRODUCTION CAMERA</span>
                  {/* <DropDown className={"width-100"} options={props.productioncam} defaultValue={productionCameraValue(props.productioncam,key.scid)} onChange={(e) => {updateTake(key.tkid, e.value,"scid")}}/>  */}
                  {/* <Autocomplete className="autocomplete" freeSolo options={props.productioncam} onBlur={(e)=>{enterFunction(e)}} onChange={(event, value) => {updateTake(key.tkid, value,"scid")}} renderInput={(params) => <TextField {...params} />} defaultValue={returndefaultCam(props.productioncam, key.scid)} /> */}
                  <TextInput className={"width-100"} isDisabled={true} options={props.ProductionCameras} defaultValue={getProductionCamera( key.camera)}/> 
                  
                </div>
                <div className="col-xl-6"> <span style={{ color: labelColor(getColor(key.camera))}}>RESOLUTION</span>
                {/* <DropDown className={"width-100"} options={props.resolution} onChange={(e) => {updateTake(key.tkid, e.label,"resolution")}} defaultValue={returndefaultValue(props.resolution, key.resolution)}/>  */}
                  <Autocomplete className="autocomplete" freeSolo options={props.resolution} onBlur={(e)=>{enterFunction(e)}} onChange={(event, value) => {updateTake(key.tkid, value,"resolution")}} renderInput={(params) => <TextField {...params} />} defaultValue={returndefaultValue(props.resolution, key.resolution)} />
                </div>
              </div>
            </div>
            <div className="col-xl-12 mb-3">
              <div className="row">
                <div className="col-xl-3"> <span style={{ color: labelColor(getColor(key.camera))}}>FPS</span>
                {/* <DropDown className={"width-100"} onChange={(e) => {updateTake(key.tkid, e.label,"fps")}} options={props.fps}  defaultValue={returndefaultValue(props.fps, key.fps)}/>  */}
                <Autocomplete className="autocomplete" freeSolo options={props.fps} onBlur={(e)=>{enterFunction(e)}} onChange={(event, value) => {updateTake(key.tkid, value,"fps")}} renderInput={(params) => <TextField {...params} />} defaultValue={returndefaultValue(props.fps, key.fps)} />
                </div>
                <div className="col-xl-3"> <span style={{ color: labelColor(getColor(key.camera))}}>WB / COLOUR TEMP</span>
                {/* <DropDown className={"width-100"} options={props.whitebalance} onChange={(e) => {updateTake(key.tkid, e.label,"white_balance")}} defaultValue={returndefaultValue(props.whitebalance, key.white_balance)}/>  */}
                <Autocomplete className="autocomplete" freeSolo options={props.whitebalance} onBlur={(e)=>{enterFunction(e)}} onChange={(event, value) => {updateTake(key.tkid, value,"white_balance")}} renderInput={(params) => <TextField {...params} />} defaultValue={returndefaultValue(props.whitebalance, key.white_balance)} />
                </div>
                <div className="col-xl-3"> <span style={{ color: labelColor(getColor(key.camera))}}>SHUTTER</span>
                {/* <DropDown className={"width-100"} options={props.shutter} onChange={(e) => {updateTake(key.tkid, e.label,"shutter")}} defaultValue={returndefaultValue(props.shutter, key.shutter)}/>  */}
                <Autocomplete className="autocomplete" freeSolo options={props.shutter} onBlur={(e)=>{enterFunction(e)}} onChange={(event, value) => {updateTake(key.tkid, value,"shutter")}} renderInput={(params) => <TextField {...params} />} defaultValue={returndefaultValue(props.shutter, key.shutter)} />
                </div>
                <div className="col-xl-3"> <span style={{ color: labelColor(getColor(key.camera))}}>ISO</span>
                {/* <DropDown className={"width-100"} options={props.iso} onChange={(e) => {updateTake(key.tkid, e.label,"iso")}} defaultValue={returndefaultValue(props.iso, key.iso)}/>  */}
                <Autocomplete className="autocomplete" freeSolo options={props.iso} onBlur={(e)=>{enterFunction(e)}} onChange={(event, value) => {updateTake(key.tkid, value ,"iso")}} renderInput={(params) => <TextField {...params} />} defaultValue={returndefaultValue(props.iso, key.iso)} />
                </div>
              </div>
            </div>
            <div className="col-xl-12 mb-4">
              <div className="row">
                <div className="col-xl-4"> <span style={{ color: labelColor(getColor(key.camera))}}>T-STOP</span>
                {/* <DropDown className={"width-100"} options={props.tstop} onChange={(e) => {updateTake(key.tkid, e.label,"t_stop")}} defaultValue={returndefaultValue(props.tstop, key.t_stop)}/>  */}
                <Autocomplete className="autocomplete" freeSolo options={props.tstop} onBlur={(e)=>{enterFunction(e)}} onChange={(event, value) => {updateTake(key.tkid, value,"t_stop")}} renderInput={(params) => <TextField {...params} />} defaultValue={returndefaultValue(props.tstop, key.t_stop)} />
                </div>
                <div className="col-xl-4"> <span style={{ color: labelColor(getColor(key.camera))}}>FILTERS ON LENS</span>
                {/* <MultiDropDown className={"width-100"} options={props.filtters} onChange={(e) =>{updateTake(key.tkid,e,"focal_length")} } defaultValue={multiDropDownValues(key.filter_on_lens,props.filtters)} />  */}
                <Autocomplete onBlur={(e)=>{enterFunction(e)}}  className="autocomplete" onChange={(event, value)  => {multiChange(key.tkid, value,"filter_on_lens")}} multiple limitTags={2} id="multiple-limit-tags" defaultValue={returnSplit(key.filter_on_lens)} options={props.filtters.map((option) => option.label.toString())} freeSolo renderTags={(value, getTagProps) => value.map((option, index) => (<Chip variant="outlined" label={option} {...getTagProps({ index })}/>))} renderInput={(params) => (<TextField {...params} variant="filled" placeholder="" />)}/>
                </div>
                <div className="col-xl-4"> <span style={{ color: labelColor(getColor(key.camera))}}>FILTERS TO ADD IN VFX</span>
                {/* <MultiDropDown className={"width-100"} options={props.filtters} onChange={(e) =>{updateTake(key.tkid,e,"filters_to_add")} } defaultValue={multiDropDownValues(key.filters_to_add,props.filtters)}/>  */}
                <Autocomplete onBlur={(e)=>{enterFunction(e)}} className="autocomplete" onChange={(event, value)  => {multiChange(key.tkid, value,"filters_to_add")}} multiple limitTags={2} id="multiple-limit-tags" defaultValue={returnSplit(key.filters_to_add)} options={props.filtters.map((option) => option.label.toString())} freeSolo renderTags={(value, getTagProps) => value.map((option, index) => (<Chip variant="outlined" label={option} {...getTagProps({ index })}/>))} renderInput={(params) => (<TextField {...params} variant="filled" placeholder="" />)}/>
                </div>
              </div>
            </div>
            <div className="col-xl-12">
              <span style={{ color: labelColor(getColor(key.camera))}}>NOTES</span>
              <TextArea className={"width-100"} defaultValue={key.notes} onChange={(e) =>{updateTake(key.tkid,e.target.value,"notes")}}></TextArea>
            </div>
          </div>
        </div>
        </div>
    )
  }
  // const optionsSelected=(key)=>{
  //   if(key === true){
  //     return(Options(props.selectedtake))
  //   }    
  // }
  const showTakes = () => {
    if(takes !== undefined && takes !== null && takes.length > 0){
      return takes.map(function(take){
        return Options(take)
      });
    }
  }
  const GetTypeView = () =>{    
    switch(props.currentSelection){
      case "getTakes":
        return "Similar takes";
      case "getCameras":
        return "Similar cameras";
      case "getSelected":
        return "Selected take";
      default:
        return "";
    }
  }

  return(
      <div className = "col-12  row ">
        <DwGenericModal 
          entity={"take"}
          id={take.tkid} 
          image={take.image} 
          update={_update} 
          show={imageModal} 
          onHide={() => setImageModal(false)}
          keyMap={keyMap}
        />
        <div className = "col-7">
          <h2>TAKE DETAILS ({GetTypeView()})</h2>
        </div>
        {/* <div className = "col-1" onClick={() => { props.setcurrentselection(); props.onHide(); props.gettake() }}>
          <h2 className="text-right">X</h2>
        </div> */}
        <div className = "col-2" >
          <ButtonPrimary className={"width-100"}  required={true} label={"SAVE & REFRESH"} action={()=>{ props.setcurrentselection(); props.gettake() }}/>
        </div>
        <div className = "col-2" >
          <ButtonPrimary className={"width-100"}  required={true} label={"SAVE & CLOSE"} action={()=>{ props.setcurrentselection(); props.onHide(); props.gettake() }}/>
        </div>
        <div className = "col-1" onClick={()=>{props.onHide(); props.gettake()}}>
          <h5 className="text-right">X</h5>
        </div>
        <div className = "col-12 ">
          <div className="row y-scroller scrolling-wrapper-flexbox" >
            {/* {optionsSelected(false)} */}
            {showTakes()}
          </div>
          {/* {options()} */}
          <div className="col-xl-6"></div>
        </div>
      </div>
  );
}
export default DwCompare;