import React, { useState ,useEffect } from "react";
import axios from "axios";
import { ButtonSecondary, ButtonPrimary } from "./forms/Buttons";
import {TextInput, DropDown, DateInput,TextArea, TimeInput} from "../components/forms/TextInputs";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSort, faTrash } from "@fortawesome/free-solid-svg-icons";
import Swal from 'sweetalert2/dist/sweetalert2.js'
import { DwModalTimeSelect } from "../components/DWTimeSelect";

const DwSetup = (props) =>{
	const [sid, setSid] = useState("");
	const [setupSlateName, setSetupSlateName] = useState("");
	const [selectedEpisode, setSelectedEpisode] = useState({});
	const [selectedVX, setSelectedVX] = useState({});
	const [selectedPreLet, setSelectedPreLet] = useState({});
	const [selectedScene, setSelectedScene] = useState({});
	const [selectedPostLet, setSelectedPostLet] = useState({});
	const [selectedSetupSuffix, setSelectedSetupSuffix] = useState({});
	const [selectedBudgetImpact, setSelectedBudgetImpact] = useState({});
	const [selectedNote, setSelectedNote] = useState("");
	const [selectedHDRI, setSelectedHDRI] = useState("");

	const [timeSetupStart, setTimeSetupStart] = useState("");
	const [timeSetupEnd, setTimeSetupEnd] = useState("");
	const [timeShootStart, setTimeShootStart] = useState("");
	const [timeShootEnd, setTimeShootEnd] = useState("");

	const [totalTimeSetup, setTotalTimeSetup] = useState("00:00:00");
	const [totalTimeShoot, setTotalTimeShoot] = useState("00:00:00");
	const [totalTimeComplete, setTotalTimeComplete] = useState("00:00:00");

	const [shootDate, setShootDate] = useState("");
	const [shootDay, setShootDay] = useState({});

	const [sortList, setSortList] = useState(false);
	const [isDisabled, setIsDisable] = useState(true);

	const [showTimerModal, setShowTimerModal] = useState(false);
	const [timeField, setTimeField] = useState('');
	const [timeSelected, setTimeSelected] = useState();

	const [SelectedCrew, setCrew] = useState("");

	// const [updatetimeTake, setupdatimeTake] = useState();

	const xvOptions=[{value:"", label:"-"}, {value:"v", label:"V"}, {value:"vx", label:"VX"}, {value:"x", label:"X"}];

	useEffect(()=>{
		totalTime();
		if(sid){
			save_refresh_2(sid, timeSetupStart, timeSetupEnd, timeShootStart, timeShootEnd);
		}
	// eslint-disable-next-line
	},[timeSetupStart, timeSetupEnd, timeShootStart, timeShootEnd])
	useEffect(()=>{
		clearViewSetup();
	// eslint-disable-next-line
	},[props.clearSetup])
	useEffect(()=>{
		if(sid){
			let index = props.setup.findIndex(x => x.sid === parseInt(sid));
			SelectSetup(props.setup[index], null, index);
		}
		else{
			SelectSetup(props.setup[props.setup.length-1], null);
		}
	// eslint-disable-next-line
	},[props.setup])
	useEffect(()=>{
		updateTakeTime(); 
	// eslint-disable-next-line
	},[props.timeControl])

	
	const updateTakeTime = async() => {
		if(props.timeControl !== null && props.timeControl !== undefined && sid !== ""){
			if((timeShootStart === "00:00:00" || timeShootStart === "")){
				starClock("ShootStart");
			}
			save_(sid, true);
		}
	}
	const createNewselectObject = (_value, _label) =>{
		return {value: _value, label: _label}
	}
	const fillLetterList = () =>{
		let letterList=[{value:"",label:"-"}];
		for (let index = 0; index < 26; index++) {
			let letter = (index + 10).toString(36).toUpperCase();
			letterList.push( {value:letter,label:letter} );
		}
		return letterList;
	}
	const fillEpisodeList = () =>{
		let episodeList=[];
		for (let index = 100; index <= 300; index++) {
			episodeList.push( {value:index, label:index.toString()} );
		}
		return episodeList;
	}
	const fillSceneList = () =>{
		let sceneList=[];
		for (let index = 1; index <= 200; index++) {
			sceneList.push( {value: index, label: index.toString().padStart(3, 0)} );
		}
		return sceneList;
	}
	const getBudgetImpactById = function(id){
		if(props.budgetImpact.length === 0) { return }
		let bidSelected = props.budgetImpact.find( obj => obj.value === id );
		if(bidSelected == null) { return }
		return bidSelected;
	}
	const CheckToEmptyString = (value, defaultValue) =>{
		let invalidValues = ["", "null", "undefined", null, undefined, -1, "-1", "_", "_null", "_undefined", "_-1", "Invalid Date"];
		if(invalidValues.includes(value)){
			return defaultValue;
		}
		return value;
	}
	const GetSetupName = (suffix) =>{
		let project = props.project;
		let episode = props.episode;
		let productionItem = props.productionItem;
		return project.short_name + "_" + episode.number + CheckToEmptyString("_" + productionItem.v_x, "").toUpperCase() + "_" + CheckToEmptyString(productionItem.scene_prelet, "") + productionItem.scene_number + CheckToEmptyString(productionItem.scene_postlet, "") + CheckToEmptyString("_" + CheckToEmptyString(suffix,"").toString().toUpperCase(),"");
	}
	const GetSlateName = (suffix) =>{
		let episode = props.episode;
		let productionItem = props.productionItem;
		return episode.number + "\u00A0 \u00A0 \u00A0 \u00A0" + CheckToEmptyString(productionItem.v_x, "").toUpperCase() + " " + CheckToEmptyString(productionItem.scene_prelet, "") + productionItem.scene_number + CheckToEmptyString(productionItem.scene_postlet, "") + " " + CheckToEmptyString(CheckToEmptyString(suffix,"").toString().toUpperCase(),"");
	}
	const changeSetup = (object, e) =>{
		if(timeSetupStart !== null && timeSetupEnd !== null && timeShootStart !== null && timeShootEnd){
			SelectSetup(object, e);
		}
		else{
			Swal.fire({
				icon: "warning",
				title: "Incomplete Setup Times",
				text: "Are you sure you want to navigate out of this view?",
				showDenyButton: true,
				confirmButtonText: 'Yes',
				denyButtonText: `No`
			}).then((result) => {
				if (result.isConfirmed) {
					SelectSetup(object, e);
				} 
				else if (result.isDenied) { }
			});
		}
	}
	const sortingSetups = () =>{
		let orderSetups;
		if(sortList){
			orderSetups = [...props.setup].reverse().map( function( object, index){
				return(
					<div key={object.sid} id="setup-div" className="width-100 mb-1 dw-setup-div" onClick={(e)=>{changeSetup(object, e)}}>
						<span id="span-id">
							{GetSetupName(object.suffix)}
						</span>
					</div>
				);				
			});
		}
		else{
		orderSetups = props.setup.map( function( object, index ){
		return <div key={object.sid} id="setup-div" className="width-100 mb-1 dw-setup-div" onClick={(e)=>{changeSetup(object, e)}}>
		<span id="span-id">
		{GetSetupName(object.suffix)}
		</span>
		</div>
		});
		}
		return orderSetups;
	}
	const SelectSetup = (object, e, position = null) =>{
		if(object === undefined){return}
		let episode = props.episode;
		let productionItem = props.productionItem;

		let vx = CheckToEmptyString(object.v_x, "");
		let _vx = CheckToEmptyString(object.v_x, "-");
		let preLetter = CheckToEmptyString(productionItem.scene_prelet,"").toUpperCase();
		let postLetter = CheckToEmptyString(productionItem.scene_postlet,"").toUpperCase();

		let htmlSetupList = document.querySelectorAll("[id='setup-div']");
		let note = document.getElementById("note-id");
		let hdri = document.getElementById("hdri-id");

		setIsDisable(false);

		for (let index = 0; index < htmlSetupList.length; index++) {
			htmlSetupList[index].className = "width-100 mb-1 dw-setup-div";
		}
		if(position !== null){
			htmlSetupList[position].className = "width-100 mb-1 dw-setup-div-click";
		}
		else{
			if(e === null){
				htmlSetupList[htmlSetupList.length-1].className = "width-100 mb-1 dw-setup-div-click";
			}
			else{
				if(e.target.id === "span-id"){
					e.target.parentElement.className = e.target.parentElement.className+"-click";
				}
				else{
					e.target.className = e.target.className + "-click";
				}
			}
		}
		
		setSid( object.sid );
		localStorage.setItem( 'sid', object.sid);
		setSetupSlateName( GetSlateName(object.suffix));
		setSelectedEpisode( createNewselectObject(episode.number, episode.number.toString()) );
		setSelectedVX( createNewselectObject(vx.toLowerCase(), _vx.toUpperCase()) );
		setSelectedPreLet( createNewselectObject(preLetter, preLetter) );
		setSelectedScene( createNewselectObject(parseInt(productionItem.scene_number), productionItem.scene_number.toString()) );
		setSelectedPostLet( createNewselectObject(postLetter, postLetter) );
		setSelectedSetupSuffix( createNewselectObject(CheckToEmptyString(object.suffix,""), CheckToEmptyString(object.suffix,"-")) );
		setSelectedBudgetImpact( getBudgetImpactById(object.budget_impact) );
		setSelectedNote( object.notes );
		setSelectedHDRI( object.hdri_ref_files );
		note.value = object.notes;
		hdri.value = object.hdri_ref_files;
		setTimeSetupStart( CheckToEmptyString( object.setup_start, "" ) );
		setTimeSetupEnd( CheckToEmptyString( object.setup_end, "" ) );
		setTimeShootStart( CheckToEmptyString( object.shoot_start, "" ) );
		setTimeShootEnd( CheckToEmptyString( object.shoot_end, "" ) );
		setShootDate( new Date( object.shoot_date + " 00:00:00" ) );
		setShootDay( createNewselectObject(object.shoot_day, "Day " + object.shoot_day) );
		setCrew( object.crew)
		props.gettake();
	}
	const displayTimeValue = (value) =>{
		try {
			var DisplayFormat = new Intl.DateTimeFormat('en-US',{hour:'numeric', minute:'numeric', second:'numeric', hour12:false });
			var time = new Date(value);
			return DisplayFormat.format(time);
		} 
		catch (error) {
			return "";
		}
	}
	const updateTime = (name, e) =>{
		if( e.target.value !== "" ){
			Swal.fire({
				title: 'Do you want to change the time?',
				showDenyButton: true,
				confirmButtonText: 'Change',
				denyButtonText: `Don't change`
			}).then((result) => {
			if (result.isConfirmed) {
				starClock(name);
			} 
			else if (result.isDenied) { }
			});
		}
		else{
		starClock(name);
		}
	}
	const starClock = async (name) =>{    
		var SaveFormat = new Intl.DateTimeFormat('az',{year:'numeric', month:'numeric', day:'numeric', hour:'numeric', minute:'numeric', second:'numeric', hour12:false });
		var actual = new Date();
		var setupEndTime;
		switch(name){
			case "SetupStart":
				if( ChecktTime(actual, name) === true ){
					alertTime(name);
				}
				else{            
					await setTimeSetupStart(SaveFormat.format(actual));
				}
				break;
			case "SetupEnd":
				if( ChecktTime(actual,name) === true ){
					alertTime(name);
				}
				else{
					await setTimeSetupEnd(SaveFormat.format(actual));
					await setTimeShootStart(SaveFormat.format(actual));
				}
				break;
			case "ShootStart":
				if( ChecktTime(actual,name) === true ){
					alertTime(name);
				}
				else{
					await setTimeShootStart(SaveFormat.format(actual));
					setupEndTime = new Date(timeSetupEnd);
					if(actual < setupEndTime || setupEndTime.toString() === "Invalid Date"){
						await setTimeSetupEnd(SaveFormat.format(actual));
					}
				}
				break;
			case "ShootEnd":
				await setTimeShootEnd(SaveFormat.format(actual));
				setupEndTime = new Date(timeSetupEnd);
				if(actual < setupEndTime || setupEndTime.toString() === "Invalid Date"){
					await setTimeSetupEnd(SaveFormat.format(actual));
					await setTimeShootStart(SaveFormat.format(actual));
				}
				var shootStartTime = new Date(timeShootStart);
				if(actual < shootStartTime || shootStartTime.toString() === "Invalid Date"){
					await setTimeShootStart(SaveFormat.format(actual));
				}
				break;
			default:
				//do nothing
				break;
		}
	}
	function ChecktTime(newTime, caseName){
		let value = false;

		var setupstartTime = new Date(timeSetupStart);
		var setupEndTime = new Date(timeSetupEnd);
		var shootStartTime = new Date(timeShootStart);
		var shootEndTime = new Date(timeShootEnd);

		switch (caseName) {
			case "SetupStart":
				setupstartTime = newTime;
				if(newTime > setupEndTime || newTime > shootStartTime || newTime > shootEndTime){
					value = true;
				}
				break;
			case "SetupEnd":
				setupEndTime = newTime;
				break;
			case "ShootStart":
				shootStartTime = newTime
				break;
			case "ShootEnd":
				shootEndTime = newTime
				break;
			default:
				//do nothing
				break;
		}

		var msSetup = setupEndTime.getTime() - setupstartTime.getTime();
		var diffSetup = get_whole_values( msSetup, [1000,60,60]);

		diffSetup.forEach(element => {
			if(parseInt( element ) <0){
				value = true;
			}
		});

		var msShoot = shootEndTime.getTime()-shootStartTime.getTime();
		var diffShoot = get_whole_values( msShoot, [1000,60,60]);

		diffShoot.forEach(element => {
			if(parseInt( element ) <0){
				value = true;
			}
		});

		var diffGardTotal = get_whole_values( msShoot + msSetup, [1000,60,60]);
		diffGardTotal.forEach(element => {
			if(parseInt( element ) <0){
				value = true;
			}
		});

		return value;
	}
	function alertTime(name, time){
		Swal.fire({
			title: 'This change conflicts with current times, do you want to keep it? \n previous values ​​will be lost',
			showDenyButton: true,
			confirmButtonText: 'Change',
			denyButtonText: `Don't change`
		}).then((result) => {
			if (result.isConfirmed) {
				changeTime(name, time)
			} 
			else if (result.isDenied) { }
		});
	}
	function changeTime(name, time){
		var SaveFormat = new Intl.DateTimeFormat('az',{year:'numeric', month:'numeric', day:'numeric', hour:'numeric', minute:'numeric', second:'numeric', hour12:false });
		switch(name){
			case "SetupStart":
				setTimeSetupStart(SaveFormat.format(time));
				setTimeSetupEnd('');
				setTimeShootStart('');
				setTimeShootEnd('');
				break;
			case "SetupEnd":
				setTimeSetupEnd(SaveFormat.format(time));
				setTimeShootStart('');
				setTimeShootEnd('');   
				break;
			case "ShootStart":
				setTimeShootStart(SaveFormat.format(time));
				setTimeShootEnd('');
				break;
			case "ShootEnd":
				setTimeShootEnd(SaveFormat.format(time));          
				break;
			default:
				//do nothing
				break;
		}
	}
	function get_whole_values(base_value, time_fractions){
		var time_data = [base_value];
		for (let i = 0; i < time_fractions.length; i++) {
			var value = String(parseInt(time_data[i]/time_fractions[i])).padStart(2, '0');
			if (value === "NaN"){
				value = "00";
			}
			time_data.push(value);
			value = String(time_data[i] % time_fractions[i]).padStart(2, '0'); 
			if (value === "NaN"){
				value = "00";
			}
			time_data[i] = value;
		}
		return time_data;
	}
	function totalTime(){
		var setupstartTime = new Date(timeSetupStart);
		var setupEndTime = new Date(timeSetupEnd);
		var msSetup = setupEndTime.getTime()-setupstartTime.getTime();
		var diffSetup = get_whole_values( msSetup, [1000,60,60]);
		setTotalTimeSetup(diffSetup[3] + ":" + diffSetup[2] + ":" + diffSetup[1]);

		var shootStartTime = new Date(timeShootStart);
		var shootEndTime = new Date(timeShootEnd);
		var msShoot = shootEndTime.getTime()-shootStartTime.getTime();
		var diffShoot = get_whole_values( msShoot, [1000,60,60]);
		setTotalTimeShoot(diffShoot[3] + ":" + diffShoot[2] + ":" + diffShoot[1]);

		var diffGardTotal = get_whole_values( msShoot + msSetup, [1000,60,60]);
		setTotalTimeComplete(diffGardTotal[3] + ":" + diffGardTotal[2] + ":" + diffGardTotal[1]);
	}
	const updateModalTime = async (value, field) =>{
		var SaveFormat = new Intl.DateTimeFormat('az',{year:'numeric', month:'numeric', day:'numeric', hour:'numeric', minute:'numeric', second:'numeric', hour12:false });
		var actual = new Date(value);
		var setupEndTime;
		switch(field){
			case "SetupStart":
				if( ChecktTime(actual, field) === true ){
					alertTime(field, actual);
				}
				else{            
					await setTimeSetupStart(SaveFormat.format(actual));
				}
				break;
			case "SetupEnd":
				if( ChecktTime(actual,field) === true ){
					alertTime(field, actual);
				}
				else{
					await setTimeSetupEnd(SaveFormat.format(actual));
					await setTimeShootStart(SaveFormat.format(actual));
				}          
				break;
			case "ShootStart":
				if( ChecktTime(actual,field) === true ){
					alertTime(field, actual);
				}
				else{
					await setTimeShootStart(SaveFormat.format(actual));
					setupEndTime = new Date(timeSetupEnd);
					if(actual < setupEndTime || setupEndTime.toString() === "Invalid Date"){
						await setTimeSetupEnd(SaveFormat.format(actual));
					}
				}
				break;
			case "ShootEnd":
				await setTimeShootEnd(SaveFormat.format(actual));
				setupEndTime = new Date(timeSetupEnd);
				if(actual < setupEndTime || setupEndTime.toString() === "Invalid Date"){
					await setTimeSetupEnd(SaveFormat.format(actual));
				}
				var shootStartTime = new Date(timeShootStart);
				if(actual < shootStartTime || shootStartTime.toString() === "Invalid Date"){
					await setTimeShootStart(SaveFormat.format(actual));
				}
				break;
			default:
				//do nothing
				break;
		}
	}
	function fillNewTime(value){
		let valuereturn = null;
		if(value === null || value === ''){        
			var SaveFormat = new Intl.DateTimeFormat('az',{year:'numeric', month:'numeric', day:'numeric', hour:'numeric', minute:'numeric', second:'numeric', hour12:false });
			var actual = new Date();
			valuereturn = SaveFormat.format(actual);
		}
		else{
			return value;
		}
		return valuereturn;
	}
	const returnHeaders = () =>{
		var token = getCookie( 'token' );
		let headerProposal ={ headers: { 'X-BP-Token': token } };
		return headerProposal;
	}
	const getCookie = function( name ){
		var r = document.cookie.match( "\\b" + name + "=([^;]*)\\b" );
		return r ? r[ 1 ] : null;
	}
	const nextSuffix = function(){
		let exceptions = ["-1",-1,null,undefined,"null","undefined","","-"]
		if(props.setup.length === 0){
			return "";
		}
		let suffix = props.setup[props.setup.length-1].suffix;
		if(exceptions.includes(suffix)){
			return "A";
		}
		let value = suffix.substring(0, suffix.length - 1) + String.fromCharCode(suffix.charCodeAt(suffix.length - 1) + 1);
		return value;
	}
	const triggerAlert = (Messaje) =>{
		Swal.fire({ title: 'Alert!', text: Messaje, confirmButtonText: 'OK' });
	}
	const deleteSetupAPI = async()=>{
		var token = getCookie( 'token' );
		try {
			await axios.delete( window.domain+"setup",{ headers: {'X-BP-Token': token}, data: {"sid":sid} }).then(res => {
				props.setUdso(new Date());
				props.gettake();
				clearViewSetup();
			});
		}
		catch{}
	}
	function delete_Setup(){
		if(sid){
			Swal.fire({
				title: 'Are you sure you want to delete this setup ' + setupSlateName + '?',
				showDenyButton: true,
				confirmButtonText: 'YES',
				denyButtonText:"NO"
			}).then((result) => {
				if (result.isConfirmed) { deleteSetupAPI() }
				else if (result.isDenied) { }
			});
		}
		else{
			triggerAlert("Select a shot first");
		}
	}
	function clearViewSetup(){
		let emptyValue = createNewselectObject("","");
		let emptyValue_ = createNewselectObject("","-");

		setSid("");
		localStorage.setItem( 'sid', "")
		setSetupSlateName("");
		setSelectedEpisode(emptyValue);
		setSelectedVX(emptyValue_);
		setSelectedPreLet(emptyValue_);
		setSelectedScene(emptyValue);
		setSelectedPostLet(emptyValue_);
		setSelectedSetupSuffix(emptyValue_);
		setSelectedBudgetImpact(emptyValue);
		setSelectedNote("");
		setSelectedHDRI("");
		document.getElementById("note-id").value = "";
		document.getElementById("hdri-id").value = "";
		setTimeSetupStart("");
		setTimeSetupEnd("");
		setTimeShootStart("");
		setTimeShootEnd("");
		setShootDate("");
		setShootDay(emptyValue);
		setIsDisable(true);
		setCrew("");
		props.setUdso(new Date());    
	}
	const save_ = async (id, updateTimes = false) =>{
		const options = { year: 'numeric', month: 'numeric', day: 'numeric' };
		let date = shootDate.toLocaleDateString('en-US', options);
		let split = date.split("/");
		let dateComp = split[2]+"-"+split[0]+"-"+split[1];

		let sendSetupS = updateTimes === true ? timeSetupStart : fillNewTime(timeSetupStart);
		let sendSetupE = updateTimes === true ? timeSetupEnd : fillNewTime(timeSetupEnd);
		let sendShootS = updateTimes === true ? timeShootStart : fillNewTime(timeShootStart);
		let sendShootE = updateTimes === true ? timeShootEnd : fillNewTime(timeShootEnd);

		let json={
			"sid": id,
			"v_x": selectedVX.value,
			"suffix": selectedSetupSuffix.value,
			"uid": parseInt( localStorage.getItem("uid") ),
			"piid": props.productionItem.piid,
			"bid": selectedBudgetImpact.value,
			"shoot_date": dateComp,
			"shoot_day": shootDay.value,
			"notes": selectedNote,
			"hdri_ref_files": selectedHDRI,
			"setup_start": (sendSetupS === "" || sendSetupS === "00:00:00") ? null : sendSetupS,
			"setup_end": (sendSetupE === "" || sendSetupE === "00:00:00") ? null : sendSetupE,
			"shoot_start": (sendShootS === "" || sendShootS === "00:00:00") ? null : sendShootS,
			"shoot_end": (sendShootE === "" || sendShootE === "00:00:00") ? null : sendShootE
		};
		await axios.put( window.domain + "setup", json, returnHeaders()).then(response => {});
	}
	const save_refresh = async (id, updateTimes = false) =>{
		const options = { year: 'numeric', month: 'numeric', day: 'numeric' };
		let date = shootDate.toLocaleDateString('en-US', options);
		let split = date.split("/");
		let dateComp = split[2]+"-"+split[0]+"-"+split[1];

		let sendSetupS = updateTimes === true ? timeSetupStart : fillNewTime(timeSetupStart);
		let sendSetupE = updateTimes === true ? timeSetupEnd : fillNewTime(timeSetupEnd);
		let sendShootS = updateTimes === true ? timeShootStart : fillNewTime(timeShootStart);
		let sendShootE = updateTimes === true ? timeShootEnd : fillNewTime(timeShootEnd);

		let json={
			"sid": id,
			"v_x": selectedVX.value,
			"suffix": selectedSetupSuffix.value,
			"uid": parseInt( localStorage.getItem("uid") ),
			"piid": props.productionItem.piid,
			"bid": selectedBudgetImpact.value,
			"shoot_date": dateComp,
			"shoot_day": shootDay.value,
			"notes": selectedNote,
			"hdri_ref_files": selectedHDRI,
			"setup_start": (sendSetupS === "" || sendSetupS === "00:00:00") ? null : sendSetupS,
			"setup_end": (sendSetupE === "" || sendSetupE === "00:00:00") ? null : sendSetupE,
			"shoot_start": (sendShootS === "" || sendShootS === "00:00:00") ? null : sendShootS,
			"shoot_end": (sendShootE === "" || sendShootE === "00:00:00") ? null : sendShootE
		};
		await axios.put( window.domain + "setup", json, returnHeaders()).then(response => {			
			props.setUdso(new Date());
			setSetupSlateName( GetSlateName(selectedSetupSuffix.value));
		});
	}
	const save_refresh_2 = async (id, SetupS, SetupE, ShootS, ShootE) =>{
		const options = { year: 'numeric', month: 'numeric', day: 'numeric' };
		let date = shootDate.toLocaleDateString('en-US', options);
		let split = date.split("/");
		let dateComp = split[2]+"-"+split[0]+"-"+split[1];

		let json={
			"sid": id,
			"v_x": selectedVX.value,
			"suffix": selectedSetupSuffix.value,
			"uid": parseInt( localStorage.getItem("uid") ),
			"piid": props.productionItem.piid,
			"bid": selectedBudgetImpact.value,
			"shoot_date": dateComp,
			"shoot_day": shootDay.value,
			"notes": selectedNote,
			"hdri_ref_files": selectedHDRI,
			"setup_start": (SetupS === "" || SetupS === "00:00:00") ? null : SetupS,
			"setup_end": (SetupE === "" || SetupE === "00:00:00") ? null : SetupE,
			"shoot_start": (ShootS === "" || ShootS === "00:00:00") ? null : ShootS,
			"shoot_end": (ShootE === "" || ShootE === "00:00:00") ? null : ShootE
		};
		await axios.put( window.domain + "setup", json, returnHeaders()).then(response => {
			props.setUdso(new Date());
		});
	}
	const create_Setup = async(suffix, shootdate, startTime)=>{
		const options = { year: 'numeric', month: 'numeric', day: 'numeric' };
		let date = shootdate.toLocaleDateString('en-US', options);
		let split = date.split("/");
		let dateComp = split[2]+"-"+split[0]+"-"+split[1];
		let json={
			"uid": parseInt( localStorage.getItem("uid") ),
			"piid": props.productionItem.piid,
			"bid": props.selectedbi.value,
			"shoot_date": dateComp,
			"notes":"",
			"hdri_ref_files": "",
			"setup_start": startTime,
			"setup_end": null,
			"shoot_start": null,
			"shoot_end": null,
			"v_x": props.productionItem.v_x,
			"suffix": suffix.toUpperCase(),
			"shoot_day": props.selectDay.value
		};
		await axios.post( window.domain + "setup", json, returnHeaders()).then( async(res) => {
			setSid(res.data.sid);
			localStorage.setItem( 'sid', res.data.sid);
			props.setUdso(new Date());
			props.gettake();
		}).catch(error => {
			console.log(error.response);
		});
	}
	const Next_Setup = () =>{
		if(sid && props.productionItem){
			save_refresh( sid );
			let suffix = fillLetterList().find( obj => obj.value ===  nextSuffix());
			create_Setup( suffix.value, shootDate, fillNewTime(timeShootEnd) );
		}
		else{
			if(!props.productionItem){
				triggerAlert('Select a shot first');
			}
			else{
				triggerAlert('Select a setup first');
			}
		}
	}
	const New_Setup = () =>{
		if( props.productionItem ){
			if(props.setup.length !== 0){
				save_refresh( sid );
			}
			var SaveFormat = new Intl.DateTimeFormat('az',{year:'numeric', month:'numeric', day:'numeric', hour:'numeric', minute:'numeric', second:'numeric', hour12:false });
			let suffix = fillLetterList().find( obj => obj.value ===  nextSuffix());
			create_Setup( suffix.value, new Date(), SaveFormat.format(new Date()) );
		}
		else{
			triggerAlert('Select a shot first');
		}
	}
	const openTimeModal = async(type)=>{
		if (isDisabled){return}
		setTimeField(type);
		let value = ""
		switch (type) {
			case "SetupStart":
				value = timeSetupStart;
				break;
			case "SetupEnd":
				value = timeSetupEnd;
				break;
			case "ShootStart":
				value = timeShootStart;
				break;
			case "ShootEnd":
				value = timeShootEnd;
				break;
			default:
				//do nothing
				break;
		}
		setTimeSelected(value);
		setShowTimerModal(true);
	}
	return(
	<div className = "">
		<DwModalTimeSelect timeSelected={timeSelected} setModalTime={updateModalTime} field={timeField} timerModal={showTimerModal} onHide={() => setShowTimerModal(false)} />
		<div className="row">
			<div className = "col-xl-5 col-lg-12 col-md-12 ">
				<h4>SETUP SLATE {"\u00A0 \u00A0 \u00A0" + setupSlateName}</h4>
			</div>
			<div className = "col-xl-1 col-lg-6 col-md-6 padding-r padding-l">
				<FontAwesomeIcon  className="fa-camera image-table delete-setup" icon={faTrash} onClick={()=>{delete_Setup()}}/>
			</div>
			<div className = "col-xl-2 col-lg-6 col-md-6">
				<ButtonSecondary className="width-100" label={"+ NEW SETUP"} action={()=>{ New_Setup() }} required={true}/>
			</div>
			<div className = "col-xl-2 col-lg-6 col-md-6">
				<ButtonSecondary className="width-100" label={"→ NEXT SETUP"} action={()=>{ Next_Setup() }} required={true}/>
			</div>
			<div className = "col-xl-2 col-lg-6 col-md-6">
				<ButtonPrimary className={"width-100"}  required={true} label={"SAVE & REFRESH"} action={()=>{ save_refresh(sid) }}/>
			</div>
		</div>
		<div className = "row">
			<div className = "col-lg-2 col-sm-12 no-padding x-scroller" style={{height: 400}}>
				<div className = "mb-3 m-2">
					<h6> SETUPS <FontAwesomeIcon onClick={(e) =>{setSortList(!sortList)}} className="fa-camera sort-icon" icon={faSort}/></h6>
				</div>
				{ sortingSetups() }
			</div>
			<div className = "col-lg-10 col-sm-12 padding-l">
				<div className = "dw-color-card p-3 mb-2">
					<div className="row">
						<div className="mb-3 col-lg-2">
							<label htmlFor="basic-url" className="form-label"><span>Episode</span></label>
							<div className="input-group">
								{/* <DropDown isDisabled={true} id="episode" value={selectedEpisode} className="width-100" required={true} placeholder="" options={fillEpisodeList()} onChange={(e) => {setSelectedEpisode(e)}}/> */}
								<TextInput isDisabled={true} id="episode" value={selectedEpisode.value} className="width-100" required={false} placeholder="" options={fillEpisodeList()} onChange={(e) => {setSelectedEpisode(e)}}/>
							</div>
						</div>
						<div className="mb-3 col-lg-2">
							<label htmlFor="basic-url" className="form-label"><span>V/X</span></label>
							<div className="input-group">
								<TextInput className="width-100" isDisabled={true} id="vx" required={false} value={selectedVX.value} placeholder="" options={xvOptions} onChange={(e) => {setSelectedVX(e)}}/>
							</div>
						</div>
						<div className="mb-3 col-lg-2">
							<label htmlFor="basic-url" className="form-label"><span>PRE LET</span></label>
							<div className="input-group">
								<TextInput className="width-100" isDisabled={true} id="vx" required={false} value={selectedPreLet.value} placeholder="" options={fillLetterList()} onChange={(e) => {setSelectedPreLet(e)}}/>
							</div>
						</div>
						<div className="mb-3 col-lg-2">
							<label htmlFor="basic-url" className="form-label"><span>SCENE</span></label>
							<div className="input-group">
								<TextInput isDisabled={true} id="scene" value={selectedScene.value} className="width-100" required={false} placeholder="" options={fillSceneList()} onChange={(e) => {setSelectedScene(e)}}/>
							</div>
						</div>
						<div className="mb-3 col-lg-2">
							<label htmlFor="basic-url" className="form-label"><span>POST LET</span></label>
							<div className="input-group">
								<TextInput className="width-100" isDisabled={true} id="postlet" value={selectedPostLet.value} required={false} placeholder="" options={fillLetterList()} onChange={(e) => {setSelectedPostLet(e)}}/>
							</div>
						</div>
						<div className="mb-3 col-lg-2">
							<label htmlFor="basic-url" className="form-label"><span>SETUP</span></label>
							<div className="input-group">
								<DropDown className="width-100" isDisabled={isDisabled} id="setup" required={false} placeholder="" options={fillLetterList()} value={selectedSetupSuffix} onChange={(e) => {setSelectedSetupSuffix(e)}}/>
							</div>
						</div>
						
					</div>
					<div className="row">
						<div className="mb-3 col-lg-4">
							<label htmlFor="basic-url" className="form-label"><span>CREW</span></label>
							<div className="input-group">
								<TextInput isDisabled={isDisabled} readOnly value={SelectedCrew} required={false} placeholder="<Short Name>" />
							</div>
						</div>
						<div className="mb-3 col-lg-4">
							<label htmlFor="basic-url" className="form-label"><span>BUDGET IMPACT</span></label>
							<div className="input-group">
							<DropDown className="width-100" isDisabled={isDisabled} id="budget" required={false} placeholder="" options={props.budgetImpact} onChange={(e) => {setSelectedBudgetImpact(e)}}  value={selectedBudgetImpact}/>
							</div>
						</div>
					</div>
					<div className="row">
						<div className="mb-3 col-lg-8">
							<label htmlFor="basic-url" className="form-label"><span>NOTE</span></label>
							<div className="input-group">
								<TextArea isDisabled={isDisabled} id={"note-id"} className="width-100 dw-height-100" required={true} placeholder="Enter note" onChange={(e) => {setSelectedNote(e.target.value)}}/>
							</div>
						</div>
						<div className="mb-3 col-lg-4">
							<label htmlFor="basic-url" className="form-label"><span>REFERENCE IMAGE FILE NAMES</span></label>
							<div className="input-group">
								<TextArea isDisabled={isDisabled} id={"hdri-id"} className="width-100 dw-height-100" required={false} placeholder="Enter"  onChange={(e) => {setSelectedHDRI(e.target.value)}}/>
							</div>
						</div>
					</div>
					<div className="row">
						<div className=" col-lg-4 col-sm-12">
							<div className="row">
								<div className="mb-3 col-6 line bp-line">
									<label htmlFor="basic-url" className="form-label"><span>SETUP START</span></label>
									<div className="input-group">
										<TimeInput timerClick={()=>{openTimeModal("SetupStart")}} isDisabled={isDisabled} className={"time-color-blue"} value={displayTimeValue(timeSetupStart)} required={false} onClick={(e) => { updateTime("SetupStart", e); }}/>
									</div>
								</div>
								<div className="mb-3 col-6">
									<label htmlFor="basic-url" className="form-label"><span>SETUP END</span></label>
									<div className="input-group">
										<TimeInput timerClick={()=>{openTimeModal("SetupEnd")}} isDisabled={isDisabled} className={"time-color-blue"} value={displayTimeValue(timeSetupEnd)} required={false}  onClick={(e) => { updateTime("SetupEnd", e); }}/>
									</div>
									<div className="form-text"><span className="time-label">TOTAL MIN </span><span className="time-color-blue">{totalTimeSetup}</span></div>
								</div>
							</div>
						</div>
						<div className=" col-lg-4 col-sm-12">
							<div className="row">
								<div className="mb-3 col-6">
									<label htmlFor="basic-url" className="form-label"><span>SHOOT START</span></label>
									<div className="input-group">
										<TimeInput timerClick={()=>{openTimeModal("ShootStart")}} isDisabled={isDisabled} className={"time-color-blue"} value={displayTimeValue(timeShootStart)} divclassName="width-100" required={false} onClick={(e) => { updateTime("ShootStart", e); }}/>
									</div>
								</div>
								<div className="mb-3 col-6">
									<label htmlFor="basic-url" className="form-label"><span>SHOOT END</span></label>
									<div className="input-group">
										<TimeInput timerClick={()=>{ openTimeModal("ShootEnd")} } isDisabled={isDisabled} className={"time-color-blue"} value={displayTimeValue(timeShootEnd)} divclassName="width-100" required={true} onClick={(e) => { updateTime("ShootEnd", e); }}/>
									</div>
									<div className="form-text">
										<span className="time-label">TOTAL MIN </span> <span className="time-color-blue">{totalTimeShoot}</span>
										<br/>
										<span className="time-label">GRAND TOTAL </span> <span className="time-color-blue">{totalTimeComplete}</span>
									</div>
								</div>
							</div>
						</div>						
						<div className=" col-lg-4 col-sm-12">
							<div className="row">
								<div className="mb-3 col-6">
									<label htmlFor="basic-url" className="form-label"><span>SHOOT DATE</span></label>
									<div className="input-group">
										<DateInput isDisabled={isDisabled} className="width-100 time-color-blue" required={false} startDate={shootDate} setStartDate={setShootDate}/>
									</div>
								</div>
								<div className="mb-3 col-6">
									<label htmlFor="basic-url" className="form-label"><span>SHOOT DAY</span></label>
									<div className="input-group">
										<DropDown className={"width-100"} isDisabled={isDisabled} required={false} options={props.dayOptions}  onChange={(e) => {setShootDay(e)}} value={shootDay}/>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	)
}
export default DwSetup;