import React, { useState ,useEffect } from "react";
import axios from "axios";
import { ButtonSecondary, ButtonPrimary } from "./forms/Buttons";
import {DwAdvanceOption} from '../components/DWModalAdvance'
import {DwOptions} from '../components/DwOptions'
import {DwDeleteOptions} from '../components/DwDeleteOptions'
import {DwTableDiv} from "../components/DwTableDiv";
import {DwGenericModal} from "../components/DwGenericModal"

const DwTakes = (props) =>{
  
  const [modalAdvanceShow, setModalAdvanceShow] = useState(false);
  
  const [modalOptionsShow, setModalOptionsShow] = useState(false);
  const [modalUploadImg, setUploadImg] = useState(false);
  
  const [modalDeleteShow, setModalDeleteShow] = useState(false);
  const [tStop, setTStop] = useState([]);
  const [tilts, setTilts] = useState([]);
  const [resolution, setResolution] = useState([]);
  const [fps, setFps] = useState([]);
  const [shutter, setShutter] = useState([]);
  // const [dollyDistance, setDollyDistance] = useState([]);
  // const [height, setHeight] = useState([]);
  const [whiteBalance, setWhiteBalance] = useState([]);
  // const [distance, setDistance] = useState([]);
  const [iso, setIso] = useState([]);
  const [significantObj, setSignificantObj] = useState([]);
  const [focal_length, setFocal_length] = useState([]);
  const [lens, setlens] = useState([]);
  const [productionCamera, setPcamera] = useState([]);
  const [selectedTake, setSelectedTake] = useState({});
  const [secondTake, setSecondTake] = useState([]);
  const [currentSelection, setCurrentSelection] = useState("");

  const [optionsdistance, setOptionsDistance] = useState([]);
  const [optionsheight, setOptionsHeight] = useState([]);
  const [optionsdollyDistance, setOptionsDollyDistance] = useState([]);

  const [letterColors, setLetterColors] = useState();

  const [update, setUpdate] = useState();

  const [filttersTemp, setfilttersTemp] = useState();

  const [Cameras, setCameras] = useState([]);
  const [ProductionCameras, setProductionCameras] = useState([]);

  // let filttersTemp=[
  //   {value: "Pro Mist 1/2", label:"Pro Mist 1/2"},
  //   {value: "Pro Mist", label:"Pro Mist"},
  //   {value: "Black Pro Mist 1/2", label:"Black Pro Mist 1/2"},
  //   {value: "Black Pro Mist", label:"Black Pro Mist"},
  //   {value: "Kojak Filters", label:"Kojak Filters"},
  //   {value: "ND.09", label:"ND.09"},
  //   {value: "ND.06", label:"ND.06"},
  //   {value: "Fisheye", label:"Fisheye"},
  //   {value: "Wide Angle", label:"Wide Angle"},
  //   {value: "Standar", label:"Standar"},
  //   {value: "Short Telephoto", label:"Short Telephoto"},
  //   {value: "Super Telephoto", label:"Super Telephoto"},
  //   {value: "Macro", label:"Macro"}
  // ]

  useEffect(()=>{
    getAll();
    getFilters();
    // eslint-disable-next-line
  },[])
  useEffect(()=>{
    getFilters();
    // eslint-disable-next-line
  },[localStorage.getItem("oid")])
  useEffect(()=>{
    if(currentSelection !=="" && currentSelection !== undefined){
      renderAgain();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[props.take])
  useEffect(()=>{
    if(localStorage.getItem("pid") !== [] && localStorage.getItem("pid") !== undefined && localStorage.getItem("pid") !== null){
      fillLetterList();
    }
    updateListPID();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[localStorage.getItem("pid")]);
  useEffect(()=>{
    if(update !== null && update !== undefined){
      props.gettake(true);
    }    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[update])
  const getFilters = async() =>{
    const url = window.domain + "options/config?level=org&level_id=" + localStorage.getItem("oid") + "&entity=filter";
    await axios.get( url, returnHeaders()).then(response => {
        if(response.status === 200){
          let list = [];
          for (let index = 0; index < response.data.length; index++) {
            list.push({value:response.data[index], label:response.data[index]});
          }
          setfilttersTemp(list);
        }
      });
  }
  const getShootingCameras = async() =>{
    let Get_url =  window.domain + "shootingCamera?oid=" + localStorage.getItem("oid")+"&pid=" + localStorage.getItem("pid");
    await axios.get( Get_url, returnHeaders()).then(response => {
        if(response.status === 200){
          let list = [];
          for (let index = 0; index < response.data.length; index++) {
            let obj = response.data[index];
            obj.value = response.data[index].name;
            obj.label = response.data[index].name;
            list.push(obj);
          }
          setCameras(list);
        }
      });
  }
  const getProductionCameras = async() =>{
    let Get_url = window.domain + "productionCamera?oid=" + localStorage.getItem("oid");
    await axios.get( Get_url, returnHeaders()).then(response => {
        if(response.status === 200){
          let list = [];      
          for (let index = 0; index < response.data.length; index++) {
            let obj={value:response.data[index].pcid, label:response.data[index].name};
            list.push(obj);
          }
          setProductionCameras(list);
        }
      });
  }

  const getAll = async function(){
    try{      
      await axios.get( window.domain+"options/tStop?level_id=" + localStorage.getItem("oid"),returnHeaders()).then(res => {
        setTStop(res["data"]);
      });
      await axios.get( window.domain+"options/tilts?level_id=" + localStorage.getItem("oid"),returnHeaders()).then(res => {
        setTilts(res["data"]);
      });
      await axios.get( window.domain+"options/resolution?level_id=" + localStorage.getItem("oid"),returnHeaders()).then(res => {
        setResolution(res["data"]);
      });
      await axios.get( window.domain+"options/fps?level_id=" + localStorage.getItem("oid"),returnHeaders()).then(res => {
        setFps(res["data"]);
      });
      await axios.get( window.domain+"options/shutter?level_id=" + localStorage.getItem("oid"),returnHeaders()).then(res => {
        setShutter(res["data"]);
      });
      await axios.get( window.domain+"options/dollyDistance?level_id=" + localStorage.getItem("oid") + "&system=imperial",returnHeaders()).then(res => {
        // setDollyDistance(res["data"]);
        setOptionsDollyDistance(fillOptions(res["data"]));
      });
      await axios.get( window.domain+"options/height?level_id=" + localStorage.getItem("oid") + "&system=imperial",returnHeaders()).then(res => {
        // setHeight(res["data"]);
        setOptionsHeight(fillOptions(res["data"]));
      });
      await axios.get( window.domain+"options/whiteBalance?level_id=" + localStorage.getItem("oid"),returnHeaders()).then(res => {
        setWhiteBalance(res["data"]);
      });
      await axios.get( window.domain+"options/distance?level_id=" + localStorage.getItem("oid") + "&system=imperial",returnHeaders()).then(res => {
        // setDistance(res["data"]);
        setOptionsDistance(fillOptions(res["data"]));
      });
      await axios.get( window.domain+"options/iso?level_id=" + localStorage.getItem("oid"),returnHeaders()).then(res => {
        setIso(res["data"]);
      });
      await axios.get( window.domain+"options/significantObject?level_id="+localStorage.getItem("oid"),returnHeaders()).then(res => {
        setSignificantObj(res["data"]);
      });
      await axios.get( window.domain+"options/focalLength?level_id="+localStorage.getItem("oid"),returnHeaders()).then(res => {
        setFocal_length(res["data"]);
      });
      await axios.get( window.domain+"lens?oid="+localStorage.getItem("oid"),returnHeaders()).then(res => {
        setlens(res["data"]);
      });
      await axios.get( window.domain + "shootingCamera?pid=" + localStorage.getItem("pid"),returnHeaders()).then(res => {
        setPcamera(res["data"]);
      });
      getProductionCameras();
    }
    catch{}
  }
  const updateListPID = async() =>{
    try{
      await axios.get( window.domain+"lens?oid="+localStorage.getItem("oid"),returnHeaders()).then(res => {
        setlens(res["data"]);
      });
      await axios.get( window.domain + "shootingCamera?pid=" + localStorage.getItem("pid"),returnHeaders()).then(res => {
        setPcamera(res["data"]);
      });
      getShootingCameras();
      getProductionCameras();
    }
    catch{}
  }
  const fillOptionsSpecial = (op, type)=>{
    // let valueID = 0;
    // let labelName ="";
    
    var optionsArray = [];
    for (let index = 0; index < op.length; index++) {
      let object = {};
      switch (type) {
        case "SO":
          // object.value = op[index].soid;
          // object.label = op[index].name;
          object.value = index;
          object.label = op[index];
          break;
        case "FL":
          // object.value = op[index].focal_length_min;
          // object.label = op[index].focal_length;
          object.value = index;
          object.label = op[index];
          break;
        case "LS":
          object.value = op[index].lid;
          object.label = op[index].name;
          break;
        case "PC":
          object.value = op[index].pcid;
          object.label = op[index].name;
          break;
        default:
          // do nothing
          break;
      }
      optionsArray.push(object);
    }
    // op.map(function(key){
    //   switch(type){
    //     case "SO":
    //       valueID = key.soid;
    //       labelName = key.name;
    //     break;
    //     case "FL":
    //       valueID = key.focal_length_min;
    //       labelName = key.focal_length;
    //     break;
    //     case "LS":
    //       valueID = key.lid;
    //       labelName = key.name;
    //     break;
    //     case "PC":
    //       valueID = key.scid;
    //       labelName = key.name;
    //     break;
    //   }
    //   optionsArray.push({value:valueID, label:labelName})
    // });
    return optionsArray;
  }
  const fillOptions = function(op){
    var optionsArray = []
    for (let index = 0; index < op.length; index++) {
      optionsArray.push({value:index, label:op[index].toString()})
    }
    // op.map(function(key, index ){
    //   optionsArray.push({value:index, label:key})
    // });
    return optionsArray;
  }
  const getCookie = function( name ) {
    var r = document.cookie.match( "\\b" + name + "=([^;]*)\\b" );
    return r ? r[ 1 ] : null;
  }
  const returnHeaders = function(){
    var token = getCookie( 'token' );
    let headerProposal ={
    headers: {
        'X-BP-Token': token,
      }
    };
    return headerProposal;
    
  }
  const newCamera = async ()=>{
    props.setTimeControl(new Date());
    let maxValue = 1;
    let nextLetter = "A"
    let cameras = [];
    for(var i = props.take.length; i >= 0; i--) {
      if(props.take[i]){
        if (maxValue < props.take[i].number){
          maxValue = props.take[i].number;
        }
        // eslint-disable-next-line no-loop-func
        if ( !cameras.filter(e => e.camera === props.take[i].camera ).length > 0 ){
          cameras.push({camera:props.take[i].camera , take:props.take[i].number})
        }
      }
    }
    for(var x = 0; x < cameras.length; x++){
      if (cameras[x].take === maxValue){
        nextLetter = (x + 11).toString(36).toUpperCase()
      }
    }
    
    const json = {
        "number": maxValue,
        "uid": parseInt( localStorage.getItem("uid")),
        "sid": parseInt( localStorage.getItem("sid")),
        "camera": nextLetter,
        "image": "",
        "focal_length":'',
        "lid": 1,
        "sig_object": "",
        "distance": '',
        "tilt": '',
        "height": '',
        "dolly_dist": '',
        "scid": 1,
        "resolution": "",
        "t_stop": "-",
        "fps": 24,
        "white_balance": 0,
        "shutter": 0,
        "iso": '',
        "filter_on_lens": "",
        "filters_to_add": "",
        "notes": ""
       }
     try {
      await axios.post( window.domain+"take",json,returnHeaders()).then(res => { });
    }
    catch{}
    props.gettake();
  }
  const nextTake =async()=>{
    let maxValue = 1;
    // let nextLetter = "A"
    let cameras = [];
    for(var i = props.take.length; i >= 0; i--) {
      if(props.take[i]){
        if (maxValue < props.take[i].number){
          maxValue = props.take[i].number;
        }
        // eslint-disable-next-line no-loop-func
        if ( !cameras.filter(e => e.camera === props.take[i].camera ).length > 0 ){
          cameras.push(props.take[i])
        }
      }
    }
    let json ={}
    for(var x = 0; x < cameras.length; x++){
        if (cameras[x].number === maxValue){
          json = {
            "number": maxValue +1,
            "uid": parseInt( localStorage.getItem("uid")),
            "sid": parseInt( localStorage.getItem("sid")),
            "camera": cameras[x].camera,
            "image":  cameras[x].image === window.domainBase + "/img/placeholder.png" ? "" : cameras[x].image,
            "focal_length":  cameras[x].focal_length,
            "lid": cameras[x].lid,
            "sig_object": cameras[x].sig_object,
            "distance": cameras[x].distance,
            "tilt": cameras[x].tilt,
            "height": cameras[x].height,
            "dolly_dist": cameras[x].dolly_dist,
            "scid": cameras[x].scid,
            "resolution": cameras[x].resolution,
            "t_stop": cameras[x].t_stop,
            "fps": cameras[x].fps,
            "white_balance": cameras[x].white_balance,
            "shutter": cameras[x].shutter,
            "iso": cameras[x].iso,
            "filter_on_lens": cameras[x].filter_on_lens,
            "filters_to_add": cameras[x].filters_to_add,
            "notes": cameras[x].notes
          }
          try {
            await axios.post( window.domain + "take", json,returnHeaders()).then(response => { });
          }
          catch{
          }
        }
        props.setTimeControl(new Date());
      }
    props.gettake(); 
  }
  const renderAgain=()=>{
    if(currentSelection !== ""){
      let similarCameras=[]
      switch(currentSelection){
        case "getTakes":
          for(var i = 0; i <= props.take.length; i++){
            if(props.take[i]){
              if(props.take[i].number === selectedTake.number){
                similarCameras.push(props.take[i]);
              }
            }
          }
          similarCameras.sort((a, b)=> {
            if (a.number === b.number){
              return a.camera < b.camera ? -1 : 1
            } else {
              return a.number > b.number ? -1 : 1
            }
          })
          setSecondTake(similarCameras);
        break;
        case "getCameras":
          for(var index = 0; index <= props.take.length; index++){
            if(props.take[index]){
              if(props.take[index].camera === selectedTake.camera){
                similarCameras.push(props.take[index]);
              }
            }
          }
          similarCameras.sort((a, b)=> {
            if (a.number === b.number){
              return a.camera < b.camera ? -1 : 1
            } else {
              return a.number > b.number ? -1 : 1
            }
          })
          setSecondTake(similarCameras);
        break;
        case "getSelected":
          for (let index = 0; index < props.take.length; index++) {
            if(props.take[index]){
              if(props.take[index].tkid === selectedTake.tkid){
                similarCameras.push(props.take[index]);
              }
            }
          }
          setSecondTake(similarCameras);
        break;
        case "viewAll":
          getalltakes();
        break;
        default:
          // do nothing
          break;
      }
  } 
  
  }
  const getalltakes=()=>{
    if(props.take.length > 0){
      let similarCameras=[];
      for(var x = props.take.length; x >= 0; x--){
        if(props.take[x]){
          similarCameras.push(props.take[x]);
        }
      }
  
      similarCameras.sort((a, b)=> {
        if (a.number === b.number){
          return a.camera < b.camera ? -1 : 1
        } else {
          return a.number > b.number ? -1 : 1
        }
      })
      setSecondTake(similarCameras);
      setModalAdvanceShow(true)
      setCurrentSelection("viewAll");
    }    
  }
  const UpdateUnits = async(unit) => {
    await axios.get( window.domain+"options/height?level_id=" + localStorage.getItem("oid") + "&system=" + unit,returnHeaders()).then(res => {
      // setHeight(res["data"]);
      setOptionsHeight(fillOptions(res["data"]));
    });
    await axios.get( window.domain+"options/distance?level_id=" + localStorage.getItem("oid") + "&system=" + unit,returnHeaders()).then(res => {
      // setDistance(res["data"]);
      setOptionsDistance(fillOptions(res["data"]));
    });
    await axios.get( window.domain+"options/dollyDistance?level_id=" + localStorage.getItem("oid") + "&system=" + unit,returnHeaders()).then(res => {
      // setDollyDistance(res["data"]);
      setOptionsDollyDistance(fillOptions(res["data"]));
    });
  }
  const fillLetterList = () =>{
    let list = [];
    for (let index = 0; index < 26; index++) {
      let letter = (index + 10).toString(36).toUpperCase();
      list.push( {value:letter, label:letter, color:"#ffffff"} );
    }
    getCameraColors(list);
  }
  const getCameraColors = async(list) =>{ 
    await axios.get( window.domain+"cameraColor?pid=" + localStorage.getItem("pid"), returnHeaders()).then(response=>{
      list.forEach(element =>{
        let colorObject = "#ffffff";
        try {
          colorObject = response["data"].find(x => x.letter === element.label).color;
        } 
        catch (error) {
          colorObject = "#ffffff";
        }
        element.color = colorObject;
      });
      setLetterColors(list);
    });
  }
  const _update = () =>{
    setUpdate(new Date())
  }
  const _update2 = ()=>{
    props.gettake(true);
  }
  // const getFilename = (str) => {
  //   if(str){
  //     return str.substring(str.lastIndexOf('/')+1);
  //   }
  //   else{
  //     return ""
  //   }
  // }
  return (
      <div className = "row">
        <div  className = "col-xl-5 ">
          <h4>TAKES</h4>
        </div>
        <div className = "col-xl-7">
          <div className="row">
            <div className = "col-xl-4"></div>
            <div  className = "col-xl-2">
              <ButtonSecondary className="width-100" required={true} label="+ NEW CAMERA" action={() =>{ newCamera() }}/>
            </div>
            <div  className = "col-xl-2">
              <ButtonSecondary className="width-100" required={false} label="→ NEXT TAKE" action={() =>{ nextTake() }}/>
            </div>
            <div  className = "col-xl-2">
              <ButtonSecondary className="width-100" required={false} label="VIEW ALL TAKES" action={() =>{ getalltakes() }}/>
            </div>
            <div  className = "col-xl-2 ">
              <ButtonPrimary className="width-100 " required={true} label={"SAVE & REFRESH"} action={() =>{ props.gettake() }}/>
            </div>
          </div>
        </div>
       
        <div  className = "col-xl-12 ">
        <DwAdvanceOption 
        update={_update2 }
        colors={letterColors}
        gettake={props.gettake}
        show={modalAdvanceShow} 
        onHide={() => setModalAdvanceShow(false)} 
        tstop={fillOptions(tStop)}
        tilts ={fillOptions(tilts)}
        resolution ={fillOptions(resolution)}
        fps ={fillOptions(fps)}
        shutter ={fillOptions(shutter)}
        dollydistance ={optionsdollyDistance}
        height ={optionsheight}
        whitebalance ={fillOptions(whiteBalance)}
        distance ={optionsdistance}
        iso ={fillOptions(iso)}
        selectedtake={selectedTake}
        focal_length={fillOptionsSpecial(focal_length,"FL")}
        tilt={fillOptions(tilts)}
        significantobj ={fillOptionsSpecial(significantObj,"SO")}
        filtters={filttersTemp}
        lens={fillOptionsSpecial(lens,"LS")}
        productioncam={fillOptionsSpecial(productionCamera,"PC")}
        secondTake={secondTake}
        render_again={renderAgain}
        take={props.take}
        setcurrentselection={setCurrentSelection}
        currentSelection={currentSelection}
        cameras={Cameras}
        ProductionCameras={ProductionCameras}
        />
        <DwOptions
        show={modalOptionsShow} 
        onHide={() => setModalOptionsShow(false)} 
        setmodaladvanceshow={setModalAdvanceShow} 
        setsecondtake={setSecondTake} 
        take={props.take} 
        selectedTake={selectedTake}
        setcurrentselection={setCurrentSelection}
        
        />

        <DwDeleteOptions  
        show={modalDeleteShow} 
        onHide={() => setModalDeleteShow(false)} 
        selectedTake={selectedTake}
        gettake={props.gettake}
        />
        <DwGenericModal 
        show={modalUploadImg} 
        onHide={() => setUploadImg(false)}
        selectedtake={selectedTake}
        id={selectedTake.tkid}
        image={selectedTake.image}
        entity={"take"}
        update={_update}
        />

        <DwTableDiv
        take={props.take}
        updateUnits={UpdateUnits}
        setmodaloptionsshow={setModalOptionsShow}
        setmodaldeleteshow = {setModalDeleteShow}
        setimagenupload={setUploadImg}
        focal_length={fillOptionsSpecial(focal_length,"FL")}
        tilt={fillOptions(tilts)}
        significantobj ={fillOptionsSpecial(significantObj,"SO")}
        height={optionsheight}
        dollydistance ={optionsdollyDistance}
        distance ={optionsdistance}
        filtters={filttersTemp}
        setselectedtake={setSelectedTake}
        gettake={props.gettake}
        heightwin={props.heightwin}
        colors={letterColors}
        cameras={Cameras}
        />

        </div>
        
      </div>
  )    
}
export default DwTakes;