import React, { useState } from "react";
import Modal from 'react-bootstrap/Modal'
import { ButtonPrimary } from "./forms/Buttons";
import Swal from 'sweetalert2/dist/sweetalert2.js'

const DwUploadProjectImg = (props) => {
  
  const [link, setLink] = useState('');

  const getCookie = function( name ) {
    var r = document.cookie.match( "\\b" + name + "=([^;]*)\\b" );
    return r ? r[ 1 ] : null;
  }
  // const returnHeaders = function(){
  //   var token = getCookie( 'token' );
  //   let headerProposal ={
  //   headers: {
  //       'X-BP-Token': token,
  //     }
  //   };
  //   return headerProposal;
    
  // };
  const ClickUpload=function()
  {
    let fileInput = document.querySelector(".file-input");
    fileInput.click();
  }
  async function sendData(data) {
    var token = getCookie( 'token' );
    let fileInput = document.querySelector(".file-input");
    const formData  = new FormData();
    let url =  window.domain+"image?entity=" + props.entity + "&id=" + props.project
    formData.append('img', fileInput.files[0], fileInput.files[0].name);
    await fetch(url, {
      method: 'POST',
      body: formData,
      headers:{
        'X-BP-Token': token,
      }
    }).then(response  => response.json())
    .then(data => {
      // window.alert("Image uploaded");
      setLink(data.file);
      Swal.fire({
        title: 'Image update',
        // showDenyButton: true,
        // confirmButtonText: 'Change',
        // denyButtonText: `Don't change`,
      }).then((result) => {
        if (result.isConfirmed) {
          props.onHide();
        }
      })      
    }      
    );
  };
  const changeInput = (target)=>{
    let progressArea = document.querySelector(".progress-area");
    let file = target.files[0]; //getting file [0] this means if user has selected multiple files then get first one only
    if(file){
      let fileName = file.name; //getting file name
      if(fileName.length >= 12){ //if file name length is greater than 12 then split it and add ...
        let splitName = fileName.split('.');
        fileName = splitName[0].substring(0, 13) + "... ." + splitName[1];
      }
    }
    let progressHTML = `<li class="row">
                            <i class="fas fa-file-alt"></i>
                            <div class="content">
                              <div class="details">
                                <span class="name">${file.name} • Pending</span>
                              </div>
                              
                            </div>
                          </li>`;
    progressArea.innerHTML = progressHTML;
  };
  // function uploadFile(name){
  //   let form = document.querySelector("form");
  //   let progressArea = document.querySelector(".progress-area");
  //   let uploadedArea = document.querySelector(".uploaded-area");
  //   let xhr = new XMLHttpRequest(); //creating new xhr object (AJAX)
  //   xhr.open("POST", "php/upload.php"); //sending post request to the specified URL
  //   xhr.upload.addEventListener("progress", ({loaded, total}) =>{ //file uploading progress event
  //     let fileLoaded = Math.floor((loaded / total) * 100);  //getting percentage of loaded file size
  //     let fileTotal = Math.floor(total / 1000); //gettting total file size in KB from bytes
  //     let fileSize;
  //     // if file size is less than 1024 then add only KB else convert this KB into MB
  //     (fileTotal < 1024) ? fileSize = fileTotal + " KB" : fileSize = (loaded / (1024*1024)).toFixed(2) + " MB";
  //     let progressHTML = `<li class="row">
  //                           <i class="fas fa-file-alt"></i>
  //                           <div class="content">
  //                             <div class="details">
  //                               <span class="name">${name} • Uploading</span>
  //                               <span class="percent">${fileLoaded}%</span>
  //                             </div>
  //                             <div class="progress-bar">
  //                               <div class="progress" style="width: ${fileLoaded}%"></div>
  //                             </div>
  //                           </div>
  //                         </li>`;
  //     uploadedArea.innerHTML = ""; //uncomment this line if you don't want to show upload history
  //     uploadedArea.classList.add("onprogress");
  //     progressArea.innerHTML = progressHTML;
  //     if(loaded == total){
  //       progressArea.innerHTML = "";
  //       let uploadedHTML = `<li class="row">
  //                             <div class="content upload">
  //                               <i class="fas fa-file-alt"></i>
  //                               <div class="details">
  //                                 <span class="name">${name} • Uploaded</span>
  //                                 <span class="size">${fileSize}</span>
  //                               </div>
  //                             </div>
  //                             <i class="fas fa-check"></i>
  //                           </li>`;
  //       uploadedArea.classList.remove("onprogress");
  //       uploadedArea.innerHTML = uploadedHTML; //uncomment this line if you don't want to show upload history
  //     }
  //   });
  //   let data = new FormData(form); //FormData is an object to easily send form data
  //   xhr.send(data); //sending form data
  // }
  // const image = ()=>{
  //   let imageUrl = window.domainBase + "/img/takes/"+ props.selectedtake.image;
  //   return imageUrl
  // };
  
  return (
    <Modal {...props} aria-labelledby="contained-modal-title-vcenter" centered >
      <Modal.Body>
        <div className="wrapper">
          <header>{props.title}</header>
          <div className="width-100">
            <img className="width-100" src={link} alt=""></img>
          </div>
          <form action="#" onClick={() => ClickUpload()}>
            <input className="file-input" type="file" name="file" hidden onChange={(e)=>{changeInput(e.target)}} accept=".png, .jpg, .jpeg"></input>
            <i className="fas fa-cloud-upload-alt"></i>
            <p>Browse File to Upload</p>
          </form>
          <section className="progress-area"></section>
          <section className="uploaded-area"></section>
        </div>
        <ButtonPrimary required={true} className={"width-100"} label="Upload" action={()=>{sendData("sendImage")}}/>
      </Modal.Body>
    </Modal>
  );
}

DwUploadProjectImg.defaultProps = {
  show: false,
  onHide: null
}

export {DwUploadProjectImg}