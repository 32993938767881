import React, { useState } from "react";
import DwNav from "../components/DwNav";
import { DropDown} from "../components/forms/TextInputs";
import { ButtonPrimary, ButtonSecondary } from "../components/forms/Buttons";
import Swal from 'sweetalert2/dist/sweetalert2.js'
import { TextInput } from "../components/forms/TextInputs";
import axios from "axios";

import SortableList from "../components/SortItemList";

const Defaults = () =>{
  const [selectedField, setSelectedField] = useState("");
  const [newItemName, setNewItemName] = useState("");
  const [itemList, setItemList] = useState([]);

// level      org
// level_id   id de la organizacion
// entity     campo
// value      valor del campo
// sort_order index de orden
// system     imperial o metrico

  const options = [
    {value: "1", entity:"distance", system: "imperial", label:"Distance (Imperial)"},
    {value: "2", entity:"distance", system: "metric", label:"Distance (Metric)"},
    {value: "3", entity:"dolly_distance", system: "imperial", label:"Dolly distance (Imperial)"},
    {value: "4", entity:"dolly_distance", system: "metric", label:"Dolly distance (Metric)"},
    {value: "5", entity:"height", system: "imperial", label:"Height (Imperial)"},
    {value: "6", entity:"height", system: "metric", label:"Height (Metric)"},

    {value: "7", entity:"fps", system: "", label:"FPS"},
    {value: "8", entity:"iso", system: "", label:"ISO"},
    {value: "9", entity:"resolution", system: "", label:"Resolution"},
    {value: "10", entity:"shutter", system: "", label:"Shutter"},
    {value: "11", entity:"tilt", system: "", label:"Tilt"},
    {value: "12", entity:"t_stop", system: "", label:"TStop"},
    {value: "13", entity:"white_balance", system: "", label:"White balance"},
    {value: "14", entity:"significant_object", system: "", label:"Significant object"},
    {value: "15", entity:"focal_length", system: "", label:"focal length"},
    {value: "16", entity:"filter", system: "", label:"Filters"}
  ]

  const getCookie = function( name ){
    var r = document.cookie.match( "\\b" + name + "=([^;]*)\\b" );
    return r ? r[ 1 ] : null;
  }

  const returnHeaders = function(){
    var token = getCookie( 'token' );
    let headerProposal ={headers: {'X-BP-Token': token}};
    return headerProposal;    
  }

  const updateList = async(e) =>{
    setSelectedField(e)
    let system = (e.system !== "") ? "&system=" + e.system : "";
    const url = window.domain + "options/config?level=org&level_id=" + localStorage.getItem("oid") + "&entity=" + e.entity + system;
		await axios.get( url, returnHeaders()).then(response => {
      if(response.status === 200){
        let newList = [];
        for (let index = 0; index < response.data.length; index++) {
          newList.push({name:response.data[index]})
        }
        setItemList(newList);
      }
    });
	}
  const addNewItem = () =>{
    if(selectedField !== "" && selectedField !== null) {
      if(newItemName !== "" && newItemName !== null){
        const list = [...itemList, {name:newItemName}];
        setItemList(list);
        setNewItemName("");
      }
      else{
        Swal.fire({icon:"warning", text:"please add the value"});
      }
    }
    else{
      Swal.fire({iconL:"Wait", text:"please select a valid option"});
    }
  }
  const updateValues = async() =>{
    let json = [];
    for (let index = 0; index < itemList.length; index++) {
      let obj = {value: itemList[index].name, sort_order:index};
      json.push(obj);
    }
    const system = selectedField.system !== "" ? "&system=" + selectedField.system : "";
    const url = window.domain + "options/config?level=org&level_id=" + localStorage.getItem("oid") + "&entity=" + selectedField.entity + system;
    await axios.post(url, json, returnHeaders()).then(response => {
      if(response.status === 200){
        Swal.fire({icon:"success", text:"Updated data"});
      }
      else{
        Swal.fire({icon:"error", text:"oops something went wrong"});
      }
    }).catch(
      Swal.fire({icon:"error", text:"oops something went wrong"})
    );
  }
  const editItem = async(index) =>{
    let newList = [...itemList];
    const { value: formValues } = await Swal.fire({
      title: 'Edit',
      html: '<label style="display: flex; margin-left: inherit; margin: 10 40 0 40">Name:</label>'+
            '<input id="swal-input1" value="' + newList[index].name + '" class="swal2-input" style="width: -webkit-fill-available; display: flex; margin-top: 0px;">',
      focusConfirm: false,
      showCancelButton: true,
      preConfirm: () => {return (document.getElementById('swal-input1').value)}
    })
    if(formValues !== undefined){
      newList[index].name = formValues;
      setItemList(newList);
    }    
  }

  return (
    <>
      <DwNav/> 
      <div className = "row d-flex justify-content-center align-items-center h-100">
        <div className="col-12 col-md-9 col-lg-7 col-xl-5">
          <div className="" style={{borderRadius: "1rem"}}>
            <div className="card-body p-5 " >
              <div className="row">
                <div className="col-md-12 mb-3">
                  <h1 className="text-center" style={ { color: "#ffffff", } }>Defaults</h1>
                  <DropDown required={false} placeholder="Select option" options={options} onChange={(e) => updateList(e)} />
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <TextInput className={"width-100"} required={false} placeholder="Value" value={newItemName} onChange={(e) => {setNewItemName(e.target.value)}} />
                </div>
                <div className="col-md-3">
                  <ButtonSecondary className="width-100 mb-3" label="Add" action={()=>{ addNewItem() }}/>
                </div>
                <div className="col-md-3">
                  <ButtonPrimary required={true} className="width-100 mb-3" label="Update" action={()=>{ updateValues() }}/>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <SortableList items={itemList} updateList={setItemList} editItem={editItem} isDisable={false}/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default Defaults;


