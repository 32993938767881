import React from "react";
import Modal from 'react-bootstrap/Modal';
import { ButtonSecondary } from "./forms/Buttons";

const DwOptions = (props) => {

  const getThisTake=(takes, selected)=>{
    let similarCameras = [selected];
    props.setsecondtake(similarCameras);
    props.setcurrentselection("getSelected")
  }
  const getSimilarCamera=(takes,selected)=>{
    let similarCameras=[]
    for(var i = takes.length; i >= 0; i--){
      if(takes[i]){
        if(takes[i].camera === selected.camera){
          similarCameras.push(takes[i]);
        }
      }
    }

    similarCameras.sort((a, b)=> {
      if (a.number === b.number){
        return a.camera < b.camera ? -1 : 1
      } else {
        return a.number > b.number ? -1 : 1
      }
    })

    props.setsecondtake(similarCameras);
    props.setcurrentselection("getCameras")
  }
  const getSimilarTakes=(takes,selected)=>{
    let similarCameras=[]
    for(var i = takes.length; i >= 0; i--){
      if(takes[i]){
        if(takes[i].number === selected.number){
          similarCameras.push(takes[i]);
        }
      }
    }

    similarCameras.sort((a, b)=> {
      if (a.number === b.number){
        return a.camera < b.camera ? -1 : 1
      } else {
        return a.number > b.number ? -1 : 1
      }
    })
    props.setsecondtake(similarCameras);
    props.setcurrentselection("getTakes")
  }
  const OpenThisTake = () =>{
    getThisTake(props.take, props.selectedTake);
    props.setmodaladvanceshow(true);
    props.onHide();
  }
  const OpenSimilarCameras = () =>{
    getSimilarCamera(props.take, props.selectedTake);
    props.setmodaladvanceshow(true);
    props.onHide();
  }
  const OpenSimilarTakes =()=>{
    getSimilarTakes(props.take, props.selectedTake);
    props.setmodaladvanceshow(true);
    props.onHide();
  }

  return (
    <Modal show={props.show} onHide={props.onHide} aria-labelledby="contained-modal-title-vcenter" centered >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          <h4>
            OPTIONS
          </h4>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
      <ButtonSecondary className="width-100 mb-3" label={"OPEN THIS TAKE"} action={()=>{OpenThisTake()}}/>
        <ButtonSecondary className="width-100 mb-3" label={"OPEN SIMILAR CAMERAS"} action={()=>{OpenSimilarCameras()}}/>
        <ButtonSecondary className="width-100 mb-3" label={"OPEN SIMILAR TAKES"} action={()=>{OpenSimilarTakes()}}/>
      </Modal.Body>
      {/* <Modal.Footer>
        <ButtonPrimary  label="Close" action={props.onHide}/>
      </Modal.Footer> */}
    </Modal>
  );
}

DwOptions.defaultProps = {
  show: false,
  onHide: null
}

export {DwOptions}