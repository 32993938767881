import React, { useState, useEffect } from "react";
import DwNav from "../components/DwNav";
import { DropDown} from "../components/forms/TextInputs";
import { ButtonPrimary, ButtonSecondary } from "../components/forms/Buttons";
import Swal from 'sweetalert2/dist/sweetalert2.js'
import { TextInput, TextArea } from "../components/forms/TextInputs";
import axios from "axios";

import SortableList from "../components/SortItemList";

const Lens = () =>{
  const [name, setName] = useState("");
  const [serial, setSerial] = useState("");
  const [description, setDescription] = useState("");
  const [objList, setObjList] = useState([]);
  const [list, setList] = useState([]);

  
  useEffect(() => {
    getList();
    // eslint-disable-next-line
  },[]);

  const getCookie = function( name ){
    var r = document.cookie.match( "\\b" + name + "=([^;]*)\\b" );
    return r ? r[ 1 ] : null;
  }

  const returnHeaders = function(){
    var token = getCookie( 'token' );
    let headerProposal ={headers: {'X-BP-Token': token}};
    return headerProposal;    
  }

  const getList = async() =>{
    const url = window.domain + "lens?oid=" + localStorage.getItem("oid");
    await axios.get( url, returnHeaders()).then(response => {
        if(response.status === 200){
            setObjList(response.data);
            setList(response.data);
            // let newList = [];
            // for (let index = 0; index < response.data.length; index++) {
            //     newList.push(response.data[index].name);                
            // }
          // setList(newList);
        }
      });
  }
  const addLens = async() =>{
    if(name === "" || serial === "" || description === ""){
      Swal.fire({icon:"warning", text:"please fill in all the fields"});
    }
    else{
      const url = window.domain + "lens"
      let json = {
          "oid": localStorage.getItem("oid"),
          "name": name,
          "description": description,
          "serial": serial
      }
      await axios.post( url, json,  returnHeaders()).then(response => {
          if(response.status === 200){
              getList();
              Swal.fire("success", "Updated data");            
          }
          else{
            Swal.fire("error", "oops something went wrong");
          }
        });
    }
    
  }
  const deleteItem = async(index) =>{
    const auxlist = [...objList];
    let item = auxlist.splice(index, 1)[0];
    console.log(index, item, auxlist);
    const url = window.domain + "lens";
    let headers = returnHeaders();
    let data = {"oid":localStorage.getItem("oid"), "lid":item.lid};
    headers.data = data;
    await axios.delete( url, headers).then(response => {
      if(response.status === 200){
        getList();
        Swal.fire({icon:"success", text:"Updated data"});
      }
      else{
        Swal.fire({icon:"error", text:"oops something went wrong"});
      }
    });
  }
  const editItem = async(index) =>{   
    let newList = [...list];
    const { value: formValues } = await Swal.fire({
      title: 'Edit data',
      html:    
            '<label style="display: flex; margin-left: inherit; margin: 10 40 0 40">Name:</label>'+
            '<input id="swal-input1" value="' + newList[index].name + '" class="swal2-input" style="width: -webkit-fill-available; display: flex; margin-top: 0px;">'+
            '<label style="display: flex; margin-left: inherit; margin: 10 40 0 40">Serial:</label>'+
            '<input id="swal-input2" value="' + newList[index].serial + '" class="swal2-input" style="width: -webkit-fill-available; display: flex; margin-top: 0px;">'+
            '<label style="display: flex; margin-left: inherit; margin: 10 40 0 40">Description:</label>'+
            '<textarea id="swal-input3" class="swal2-textarea" style="width: -webkit-fill-available; display: flex; margin-top: 0px;"> ' + newList[index].description + ' </textarea>',
      focusConfirm: false,
      showCancelButton: true,
      preConfirm: () => {
        return ([
        document.getElementById('swal-input1').value, 
        document.getElementById('swal-input2').value, 
        document.getElementById('swal-input3').value])
      }})
      if(formValues !== undefined){
        let headers = returnHeaders();
        let data = {lid:newList[index].lid, name:formValues[0], serial:formValues[1], description:formValues[2]};
        // headers.data = data;
        const url = window.domain + "lens?oid=" + localStorage.getItem("oid");
        await axios.put( url, data, headers ).then(response => {
          console.log(response)
        });
      }
  }

  return (
    <>
      <DwNav/> 
      <div className = "row d-flex justify-content-center align-items-center h-100">
        <div className="col-12 col-md-9 col-lg-7 col-xl-5">
          <div className="" style={{borderRadius: "1rem"}}>
            <div className="card-body p-5 " >
              <div className="row">
                <div className="col-md-12 mb-3">
                  <h1 className="text-center" style={ { color: "#ffffff", } }>Lens</h1>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <TextInput className={"width-100"} required={false} placeholder="Name" value={name} onChange={(e) => {setName(e.target.value)}} />
                </div>
                <div className="col-md-6">
                  <TextInput className={"width-100"} required={false} placeholder="Serial" value={serial} onChange={(e) => {setSerial(e.target.value)}} />
                </div>
                <div className="col-md-12">
                    <TextArea id="newBlank" className="width-100 dw-height-input" required={false} placeholder="Description" onChange={(e) => {setDescription(e.target.value)}}/>
                </div> 
                <div className="col-md-12">
                  <ButtonPrimary required={true} className="width-100 mb-3" label="Add" action={()=>{ addLens() }}/>
                </div>               
              </div>
              <div className="row">
                <div className="col-md-12">
                  <SortableList items={list} updateList={setList} deleteItem={deleteItem} editItem={editItem} isDisable={true}/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default Lens;


