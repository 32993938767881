import React from "react";
import DwNav from "../components/DwNav";

const OrgSettings = (props) => {
  return (
    <div className="app-container">
      <DwNav />
      <header>
        <h1>OrgSettings</h1>
      </header>
    </div>
  )
}

export default OrgSettings