import React, { useState, useEffect } from "react";
import DwNav from "../components/DwNav";
import {TextInput, DropDown} from "../components/forms/TextInputs";
import FormControlLabel from "@mui/material/FormControlLabel";
import {ButtonSecondary} from "../components/forms/Buttons";
import CollapsibleTable from "../components/DwCollapsableTable";
import Switch from "@mui/material/Switch";
import Swal from "sweetalert2/dist/sweetalert2.js"
import axios from "axios";

const Shotgrid=(props)=>{
    const [getEnable, setEnable] = useState(false);
    const [getTestResult, setTestResult] = useState(false);
    const [url, setURL] = useState();
    const [key, setKEY] = useState();
    const [getScriptName, setScriptName] = useState();
    const [getProjects, setProjects] = useState([]);
    const [getSelectedProject, setSelectedProject] = useState();

    const [getSGProjects, setSGProjects] = useState([]);
    const [getSelectedSGProject, setSelectedSGProject] = useState();
    const [getRows, setRows] = useState();
    // const [getRowsEdit, setRowsEdit] = useState();

    useEffect(()=>{
        getProjectsList();        
    // eslint-disable-next-line
    },[])
    useEffect(()=>{
        if(getSelectedSGProject !== undefined){
            getDataFromShotgrid();
        }
    // eslint-disable-next-line
    },[getSelectedSGProject])

    useEffect(()=>{
        if(getSelectedProject !== undefined && getSelectedProject !== null){
            preconf(getSelectedProject);
        }
    // eslint-disable-next-line
    },[getSelectedProject])

    const getProjectsList = async() =>{
        await axios.get( window.domain+"project?oid=" + localStorage.getItem("oid"), returnHeaders()).then(response=>{
            var newArray = [];
            response["data"].forEach(element => {
              newArray.push({value:element.pid, label:element.name});
            });
            setProjects(newArray);
          });
    }
    const returnHeaders = () =>{
        var token = getCookie( 'token' );
        let headerProposal ={ headers: { 'X-BP-Token': token } };
        return headerProposal;
    }
    const getCookie = function( name ){
        var r = document.cookie.match( "\\b" + name + "=([^;]*)\\b" );
        return r ? r[ 1 ] : null;
    }
    const checkValue = function(value){
        let errors = [null, undefined, "", "null", "undefined"]
        return !errors.includes(value);
    }
    const preconf = async(pid) =>{
        await axios.get( window.domain + "sg/preconf?pid=" + pid.value, returnHeaders()).then(response=>{
            if(response.data){
                setEnable(response.data.active === 1);
                setURL(response.data.domain);
                setKEY(response.data.key);
                setScriptName(response.data.script_name);
                setSelectedProject(pid);
                testCredentials(response.data.active === 1, response.data.domain, response.data.script_name, response.data.key, false);
            }
        });
    }
    const testCredentials = async (active_ = null, url_ = null, scriptName_ = null, key_ = null, show = true) =>{
        
        let urlLocal = url_ ? url_ : url;
        let scriptNameLocal = scriptName_ ? scriptName_ : getScriptName;
        let keyLocal = key_ ? key_ : key;

        if( !checkValue(getSelectedProject) || !checkValue(urlLocal) || !checkValue(scriptNameLocal) || !checkValue(keyLocal)){
            Swal.fire({icon: "warning", title:"warning", text: "Please validate entered data"}); 
            return;
        }
        let json = {
            "pid": getSelectedProject.value,
            "domain": urlLocal.replace("https//","").replace("http//",""),
            "script_name": scriptNameLocal,
            "key": keyLocal            
        }

        await axios.post( window.domain + "sg/test", json, returnHeaders()).then(response=>{
            if(!response.data.success){
                Swal.fire({icon: "error", title:"ERROR", text:response.data.error });
                setTestResult(false);
            }
            else{
                if(show){
                    Swal.fire({icon: "success", title:"OK", text:response.data.msg });
                    
                }
                else{
                    getData(active_, urlLocal, scriptNameLocal, keyLocal, false);
                }
                setTestResult(true);
            }
        }).catch(function (error) {
            if (error.response) {
              Swal.fire({icon: "error", title:"ERROR", text:error.response.data.error });
              setTestResult(false);
            }
          });
    }
    const getData = async(active_ = null, url_ = null, scriptName_ = null, key_ = null, show = true) =>{
        let localEnable = active_ ? active_ : getEnable;
        let urlLocal = url_ ? url_ : url;
        let scriptNameLocal = scriptName_ ? scriptName_ : getScriptName;
        let keyLocal = key_ ? key_ : key;

        if(!checkValue(getSelectedProject) || !checkValue(urlLocal) || !checkValue(scriptNameLocal) || !checkValue(keyLocal)){
            Swal.fire({icon: "warning", title:"warning", text: "Please validate entered data"});
            return;
        }
        let json = {
            "pid": getSelectedProject.value,
            "domain": urlLocal.replace("https//","").replace("http//",""),
            "script_name": scriptNameLocal,
            "key": keyLocal,
            "active": localEnable
        }

        await axios.post( window.domain + "sg/integrate", json, returnHeaders()).then(response=>{
            if(!response.data.success){
                Swal.fire({icon: "error", title:"ERROR", text:response.data.error });
                setTestResult(false);
            }
            else{
                if(show){
                    Swal.fire({icon: "success", title:"OK", text:response.data.msg });
                }
                setTestResult(true);
                getProjectsData();
            }
        }).catch(function (error) {
            if (error.response) {
              Swal.fire({icon: "error", title:"ERROR", text:error.response.data.error });
              setTestResult(false);
            }
          });
    }
    const getProjectsData = async() =>{
        await axios.get( window.domain+"sg/project?pid="+getSelectedProject.value, returnHeaders()).then(response=>{
            var newArray = [];
            response["data"].forEach(element => {
              newArray.push({value:element.id, label:element.name});
            });
            setSGProjects(newArray);
          });
    }
    const getDataFromShotgrid = async()=>{
        await axios.get( window.domain+"sg/records?pid=" + getSelectedProject.value + "&sg_pid=" + getSelectedSGProject.value, returnHeaders()).then(response=>{         
            if(response.data.episodes.length === 0){
                Swal.fire({
                    icon: 'info',
                    title: 'No data found',
                    showDenyButton: false,
                  });
                  return;
            }
            setRows(response.data);
        }).catch(error => {
            setRows(undefined);
            Swal.fire({
                icon: 'info',
                title: 'No data found',
                showDenyButton: false,
              });
        });
    }
    const showButtons = () =>{
        if(getRows !== undefined && getRows !== null){
            return(
                <div className="row">
                    <div className="col-sm-6"/>
                    <div className="col-sm-3">
                        <ButtonSecondary className="width-100" label={"Import Selection"} required={true} action={()=>{sendSelectedData()}}/>
                    </div>
                    <div  className="col-sm-3">
                        <ButtonSecondary className="width-100" label={"Import Everything"} required={true} action={()=>{sendAllData()}}/>
                    </div>
                </div>
            );
        }
        else{
            return(<></>);
        }
    }
    const sendData = async(data) =>{
        await axios.post( window.domain+"sg/records?pid=" + getSelectedProject.value + "&sg_pid=" + getSelectedSGProject.value, data, returnHeaders()).then(response => {
            window.location.href = "/view";
        }).catch(error => {});
    }
    const sendAllData = () =>{
        if(getRows !== undefined && getRows !== null){
            let total = countData(getRows);
            Swal.fire({
                icon: 'info',
                title: 'Import Data',
                text: total + " items will be imported",
                showDenyButton: true,
                confirmButtonText: 'Yes',
                denyButtonText: `No`,
              }).then((result) => {
              if (result.isConfirmed) {
                let data = getRows;
                delete data.assocs;
                delete data.codes;
                delete data.episodes_serial;
                delete data.sequences_serial;
                sendData(data);
              } 
              else if (result.isDenied) { }
              })
        }
    }
    const sendSelectedData = async() =>{
        let cleanData = cleanDataSelected(getRows);
        if(getRows !== undefined && getRows !== null){
            Swal.fire({
                icon: 'info',
                title: 'Selected items will be imported',
                html: "<h6>Episodes: " + cleanData.episodes + "</h6><h6> Sequences: " + cleanData.sequences + "</h6><h6> Shots: " + cleanData.shots + "</h6><h6>Total: " +  (cleanData.episodes +  cleanData.sequences +  cleanData.shots) + "</h6>",
                showDenyButton: true,
                confirmButtonText: 'Yes',
                denyButtonText: `No`,
              }).then((result) => {
              if (result.isConfirmed) {
                sendData(cleanData.data);
              } 
              else if (result.isDenied) { }
              });
        }
    }
    const countData=(data)=>{
        let count = 0;
        for (let indexEpisode = data.episodes.length-1; indexEpisode > -1; indexEpisode--) {
            count = count + 1;
            for (let indexSequences = data.episodes[indexEpisode].sequences.length-1; indexSequences > -1; indexSequences--) {
                count = count + 1;
                for (let indexShot = data.episodes[indexEpisode].sequences[indexSequences].shots.length-1; indexShot > -1; indexShot--) {
                    count = count + 1;
                }
            }
        }
        return count;
    }
    const cleanDataSelected = (info) =>{
        let data = JSON.parse(JSON.stringify(info));
        delete data.assocs;
        delete data.codes;
        delete data.episodes_serial;
        delete data.sequences_serial;

        let epCount = 0;
        let seqCount = 0;
        let shotCount = 0;
        let remove = 0;

        for (let indexEpisode = data.episodes.length-1; indexEpisode > -1; indexEpisode--) {
            if( data.episodes[indexEpisode].indeterminate === false && data.episodes[indexEpisode].checked === false){
                data.episodes.splice(indexEpisode, 1);
                remove = remove + 1;
            }
            else{
                epCount = epCount + 1;
                for (let indexSequences = data.episodes[indexEpisode].sequences.length-1; indexSequences > -1; indexSequences--) {
                    if( data.episodes[indexEpisode].sequences[indexSequences].indeterminate === false && data.episodes[indexEpisode].sequences[indexSequences].checked === false){
                        data.episodes[indexEpisode].sequences.splice(indexSequences, 1);
                        remove = remove + 1;
                    }
                    else{
                        seqCount = seqCount + 1;
                        for (let indexShot = data.episodes[indexEpisode].sequences[indexSequences].shots.length-1; indexShot > -1; indexShot--) {
                            if(data.episodes[indexEpisode].sequences[indexSequences].shots[indexShot].checked === false){
                                data.episodes[indexEpisode].sequences[indexSequences].shots.splice(indexShot, 1);
                                remove = remove + 1;
                            }
                            else{
                                shotCount = shotCount + 1;
                            }
                        }
                    }
                }
            }
        }
        return {data:data, episodes: epCount, sequences:seqCount, shots:shotCount, remove:remove};
    }
    
    return(
        <>
            <DwNav/>
            <header>
                <h1>Shotgrid</h1>
            </header>
            <div className="row">
                <div className="col-sm-2">
                    <DropDown options={getProjects} value={getSelectedProject} onChange={(e)=>setSelectedProject(e)} placeholder="DATA WRANGLER PROJECT"/>
                </div>
                <div className="col-sm-1">
                    <FormControlLabel control={<Switch checked={getEnable} onChange={(e) => setEnable(e.target.checked)} color="warning"/>} label="ENABLE" />
                </div>
            </div>
            <br></br>
            <div className="row">
                {/* <div className="col-sm-1">
                    <FormControlLabel control={<Switch checked={getEnable} onChange={(e) => setEnable(e.target.checked)} color="warning"/>} label="ENABLE" />
                </div> */}
                <div className="col-sm-2">
                    <TextInput type="text" autoComplete="off" required={getEnable} isDisabled={!getEnable} value={url} onChange={(e) => setURL(e.target.value)} placeholder="Shotgrid domain" className="width-100"/>
                </div>
                <div className="col-sm-2">
                    <TextInput type="password" autoComplete="new-password" required={getEnable} isDisabled={!getEnable} value={key} onChange={(e) => setKEY(e.target.value)} placeholder="API Key" className="width-100"/>
                </div>
                <div className="col-sm-2">
                    <TextInput type="text" autoComplete="off" required={getEnable} isDisabled={!getEnable} value={getScriptName} onChange={(e) => setScriptName(e.target.value)} placeholder="Client id / Script name" className="width-100"/>
                </div>
                {/* <div className="col-sm-2">
                    <DropDown options={getProjects} value={getSelectedProject} onChange={(e)=>setSelectedProject(e)} placeholder="DATA WRANGLER PROJECT"/>
                </div> */}
                <div className="col-sm-2">
                    <ButtonSecondary isDisabled={!getEnable} className="width-100" label={"TEST CREDENTIALS"} required={true} action={()=>{testCredentials()}}/>
                </div>
                <div className="col-sm-2">
                    <ButtonSecondary isDisabled={!getTestResult} className="width-100" label={"SAVE"} required={true} action={()=>{getData()}}/>
                </div>
                <div className="col-sm-2">
                    <DropDown options={getSGProjects} value={getSelectedSGProject} onChange={(e)=>setSelectedSGProject(e)} placeholder="SHOTGRID PROJECT"/>
                </div>
            </div>
            <br/>
            {showButtons()}
            <br/>
            <div>
                < CollapsibleTable rows={getRows} />
            </div>
            <br/>
            {showButtons()}
        </>
    );
}

export default Shotgrid;