import React, { useState ,useEffect } from "react";
import DwNav from "../components/DwNav";
import axios from "axios";
import { ButtonSecondary } from "../components/forms/Buttons";
import { TextInput, DropDown} from "../components/forms/TextInputs";
import { useNavigate } from "react-router-dom";
import { DwUploadProjectImg } from "../components/DwUploadProjectImg";

const CreateProject = () =>{
  let navigate = useNavigate();

  const [currentName, setName] = useState('');
  const [budgetImpactID, setBudgetImpactID] = useState();
  const [budgetImpact, setBudgeted] = useState([]);
  const [shortName, setShortName] = useState('');

  const [selectedProjectID, setselectedProjectID] = useState('');
  const [projects, setProjects] = useState('');

  const [btnLabel, setbtnLabel] = useState('CREATE');
  const [projectImage, setProjectImage] = useState(false);
  // const [projectBanner, setProjectBanner] = useState(false);
  const [file, setFile] = useState('');

  useEffect(() =>{
    getAll();
    getprojects();
    // eslint-disable-next-line
  },[]);
  const getprojects = async function(){
    await axios.get( window.domain+"project?oid="+getLocStg("oid"),returnHeaders()).then(res => {
      setProjects(res["data"]);
    });
  };
  const fillProjects = function(){
    var newArray = []
    newArray.push({value:null, label:"NEW PROJECT"})
    if(projects.length > 0){
      for (let index = 0; index < projects.length; index++) {
        newArray.push({value:projects[index].pid, label:projects[index].name, bid:projects[index].bid, short_name:projects[index].short_name, image:projects[index].image});
      }
      // projects.map(function(key, index ){
      //   newArray.push({value:key.pid, label:key.name, bid:key.bid, short_name:key.short_name, image:key.image})
      // });
    }
    return newArray;
  };
  const updateProject = function(e){
    setselectedProjectID(e.value);
    if(e.value === null){
      setbtnLabel('CREATE')
      setName('');
      setShortName('');
      setBudgetImpactID(1);
    }
    else{
      setbtnLabel('UPDATE')
      setName(e.label);
      setShortName(e.short_name);
      setBudgetImpactID(e.bid);
      setFile(e.image)
    }
  };
  // const getBIValue = function(bID){
  //   if(budgetImpact.length === 0){return}
  //   let bidSelected = budgetImpact.find( obj => obj.bid === bID );
  //   if(bidSelected === null){return}
  //   return {value: bidSelected.bid, label:bidSelected.type}
  // };
  const getAll = async function(){
    try{
      await axios.get( window.domain+"budgetImpact?oid="+getLocStg("oid"),returnHeaders()).then(res => {
        setBudgeted(res["data"]);
        if(res["data"].length > 0){
          setBudgetImpactID({value:res["data"][0].bid, label:res["data"][0].type});
        }        
      });
    }
    catch{ }
  };
  const getLocStg=function(e){
    return localStorage.getItem(e)
  };
  const fillOptionsBudgeted = function(){
    var optionsArray = []
    if(budgetImpact){
      for (let index = 0; index < budgetImpact.length; index++) {
        optionsArray.push({value:budgetImpact[index].bid, label:budgetImpact[index].type})
      }
      // budgetImpact.map(function(key, index ){
      //   optionsArray.push({value:key.bid, label:key.type})
      // });
    }
    return optionsArray;
  };  
  const returnHeaders = function(){
    var token = getCookie( 'token' );
    let headerProposal ={
    headers: {
        'X-BP-Token': token,
      }
    };
    return headerProposal;
  };
  const getCookie = function( name ) {
    var r = document.cookie.match( "\\b" + name + "=([^;]*)\\b" );
    return r ? r[ 1 ] : null;
  };
  const CreateOrgAPI = async()=>{
    let json = {
      "oid": parseInt(getLocStg("oid")),
      "short_name": shortName.toUpperCase(),
      "bid": budgetImpactID.value,
      "name": currentName
    }
    try {
      await axios.post( window.domain +"project", json, returnHeaders()).then(res => {
        navigate("/view");        
      });      
    } 
    catch (error) {
        alert("Error");
        return;
    }
  };
  const modalLoad = function(){
    if(btnLabel === "UPDATE"){
      return(<DwUploadProjectImg entity={"project"} project={selectedProjectID} file={file} show={projectImage}  title={"Upload Image"} onHide={() => setProjectImage(false)} />)
    }
    else{
      return;
    }
  };
  const btnModalLoad = function(){
    if(btnLabel === "UPDATE"){
      return(<ButtonSecondary className="width-100 mb-3" label={"Upload project Image"} action={()=>{ setProjectImage(true) }}/>)
    }
    else{
      return;
    }
  };
  const dropEdit = () =>{
    if(projects.length > 0){
      return <DropDown required={false} defaultValue={{value:null, label:"NEW PROJECT"}} options={fillProjects()} onChange={(e) => updateProject(e)} placeholder="PROJECT"/>
    }
  }
  
  return (
    <>
    <DwNav/>
    {modalLoad()}
    {/* <DwUploadProjectImg entity={"projects"} project={selectedProjectID} show={projectImage}  title={"Upload Image"} onHide={() => setProjectImage(false)} /> */}
    {/* <DwUploadProjectImg entity={"Banner"}   project={selectedProjectID} show={projectBanner} title={"Upload Banner"} onHide={() => setProjectBanner(false)} /> */}
    <div className = "row d-flex justify-content-center align-items-center h-100">
      <div className="col-12 col-md-9 col-lg-7 col-xl-5">
        <div className="" style={{borderRadius: "1rem"}}>
          <div className="card-body p-5 " >
            <div className="mb-md-5 mt-md-4 pb-5">
              <h1 className="text-center" style={ { color: "#ffffff", } }>Projects</h1>
              <div className={" mb-4"}>
                {dropEdit()}
              </div>              
              <TextInput className={"width-100 mb-4"} value={currentName} required={false} placeholder="Project name" onChange={(e) => setName(e.target.value)} />
              <TextInput className={"width-100 mb-4"} value={shortName} required={false} placeholder="Short name" onChange={(e) => setShortName(e.target.value)} />
              <div className="mb-4" >
              {/* <DropDown options={fillOptionsBudgeted()} value={budgetImpactID} onChange={(e) => {setBudgetImpactID(e);}} /> */}
              </div>
              {btnModalLoad()}
              {/* <ButtonSecondary className="width-100 mb-3" label={"Upload project Image"} action={()=>{ setProjectImage(true) }}/> */}
              {/* <div className = "row ">
                <div className="col-lg-6">
                  <ButtonSecondary className="width-100 mb-3" label={"Upload project Image"} action={()=>{ setProjectImage(true) }}/>
                </div>
                <div className="col-lg-6">
                  <ButtonSecondary className="width-100 mb-3" label={"Upload project Banner"} action={()=>{ setProjectBanner(true) }}/>
                </div>
              </div> */}
              <ButtonSecondary className="width-100 mb-3" label={btnLabel} action={()=>{ CreateOrgAPI() }}/>
            </div>
          </div>
        </div>
      </div>
    </div>
    </>
  );
}
export default CreateProject;