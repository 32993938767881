import React, { useState, useEffect } from "react";
import DwNav from "../components/DwNav";
import { DropDown} from "../components/forms/TextInputs";
import { ButtonPrimary, ButtonSecondary } from "../components/forms/Buttons";
import Swal from 'sweetalert2/dist/sweetalert2.js'
import { TextInput, TextArea } from "../components/forms/TextInputs";
import axios from "axios";

import SortableList from "../components/SortItemList";
import {ColorPickerButton} from "../components/forms/ColorPickerButton"

const ShootingCameras = () =>{
  const [projects, setProjects] = useState([]);
  const [selectedProject, setSelectedProject] = useState({});
  const [Cameras, setCameras] = useState([]);
  const [filterCameras, setFilterCameras] = useState([]);
  const [selectedCamera, setSelectedCamera] = useState({});
  const [name, setName] = useState("");
  const [color, setColor] = useState();
  const [description, setDescription] = useState("");
  const [list, setList] = useState([]);

  const url = window.domain + "shootingCamera"

  useEffect(() => {
    getProjects();
    getProductionCameras();
    // eslint-disable-next-line
  },[]);
  useEffect(() => {
    getShootingCameras();
    // eslint-disable-next-line
  },[selectedProject]);
  useEffect(() => {
    clearProductionCameras();
    // eslint-disable-next-line
  },[list]);

  const getCookie = function( name ){
    var r = document.cookie.match( "\\b" + name + "=([^;]*)\\b" );
    return r ? r[ 1 ] : null;
  }

  const returnHeaders = function(){
    var token = getCookie( 'token' );
    let headerProposal ={headers: {'X-BP-Token': token}};
    return headerProposal;    
  }

  const getProjects = async() =>{
    await axios.get( window.domain+"project?oid="+localStorage.getItem("oid"),returnHeaders()).then(response => {
      let list = [];      
      for (let index = 0; index < response.data.length; index++) {
        let obj={value:response.data[index].pid, label:response.data[index].name};
        list.push(obj);
      }
      setProjects(list);
    });
  }
  const getProductionCameras = async() =>{
    let Get_url = window.domain + "productionCamera?oid=" + localStorage.getItem("oid");
    await axios.get( Get_url, returnHeaders()).then(response => {
        if(response.status === 200){
          let list = [];      
          for (let index = 0; index < response.data.length; index++) {
            let obj={value:response.data[index].pcid, label:response.data[index].name};
            list.push(obj);
          }
          setCameras(list);
        }
      });
  }
  const clearProductionCameras = () =>{
    let camsToRemove = list.map(e => e.pcid);
    let camsClear = Cameras.filter(e => !camsToRemove.includes(e.value));
    setFilterCameras(camsClear);
  }
  const getShootingCameras = async() =>{
    let Get_url = url + "?oid=" + localStorage.getItem("oid")+"&pid=" + selectedProject.value;
    await axios.get( Get_url, returnHeaders()).then(response => {
        if(response.status === 200){
            setList(response.data);
        }
      });
  }

  const createCamera = async() =>{
    if(name === "" || description === "" || color === ""){
      Swal.fire({icon:"warning", text:"please fill in all the fields"});      
    } 
    else{
      let json =  {
        "pcid": selectedCamera.value,
        "pid": selectedProject.value,
        "name": name,
        "description": description,
        "color": color
    }
    await axios.post( url, json,  returnHeaders()).then(response => {
        if(response.status === 200){
            getShootingCameras();
            Swal.fire({icon:"success", text:"Updated data"});            
        }
        else{
          Swal.fire({icon:"error", text:"oops something went wrong"});
        }
      });
    }
    
  }
  const deleteItem = async(index) =>{
    const auxlist = [...list];
    let item = auxlist.splice(index, 1)[0];
    let headers = returnHeaders();
    let data = {"oid":localStorage.getItem("oid"), "scid":item.scid};
    headers.data = data;
    await axios.delete( url, headers).then(response => {
      if(response.status === 200){
        getShootingCameras();
        Swal.fire({icon:"success", text:"Updated data"});
      }
      else{
        Swal.fire({icon:"error", text:"oops something went wrong"});
      }
    });
  }
  const listPCams = (pcid) =>{
    let options = "";    
    let cam = Cameras.find((obj) => obj.value === pcid);
    options += '<option selected value="' + cam.value + '">' + cam.label + '</option>';  
    for (let index = 0; index < filterCameras.length; index++) {
      options += '<option value="' + filterCameras[index].value + '">' + filterCameras[index].label + '</option>';      
    }
    console.log(options)
    return options;
  }
  const editItem = async(index) =>{   
    let newList = [...list];
    const { value: formValues } = await Swal.fire({
      title: 'Edit data',
      width: 600,
      html:
            '<label style="display: flex; margin-left: inherit; margin: 10 40 0 40">Production Camera:</label>'+
            '<select id="swal-input4" class="swal2-input"style="width: -webkit-fill-available; display: flex; margin-top: 0px;">' + listPCams(newList[index].pcid) + '</select>'+
            '<label style="display: flex; margin-left: inherit; margin: 10 40 0 40">Name:</label>'+
            '<input id="swal-input1" value="' + newList[index].name + '" class="swal2-input" style="width: -webkit-fill-available; display: flex; margin-top: 0px;">'+
            '<label style="display: flex; margin-left: inherit; margin: 10 40 0 40">Color:</label>'+
            '<input id="swal-input2" type="color" class="swal2-input" value="' + newList[index].color + '" style="padding:0; width: -webkit-fill-available; display: flex; margin-top: 0px;"></input>'+
            '<label style="display: flex; margin-left: inherit; margin: 10 40 0 40">Description:</label>'+
            '<textarea id="swal-input3" class="swal2-textarea" style="width: -webkit-fill-available; display: flex; margin-top: 0px;">' + newList[index].description + '</textarea>',
      focusConfirm: false,
      showCancelButton: true,
      preConfirm: () => {
        return ([
        document.getElementById('swal-input1').value, 
        document.getElementById('swal-input2').value, 
        document.getElementById('swal-input3').value,
        document.getElementById('swal-input4').value])
      }})
      if(formValues !== undefined){
        let headers = returnHeaders();
        let data = {
          scid:newList[index].scid,
          pcid:parseInt(formValues[3]), 
          pid:newList[index].pid,
          name:formValues[0],
          color:formValues[1],
          description:formValues[2]
        };
        // headers.data = data;
        const edit_url = url +"?oid=" + localStorage.getItem("oid");
        await axios.put( edit_url, data, headers ).then(response => {
          getShootingCameras();
        });
      }
  }
  return (
    <>
      <DwNav/> 
      <div className = "row d-flex justify-content-center align-items-center h-100">
        <div className="col-12 col-md-9 col-lg-7 col-xl-5">
          <div className="" style={{borderRadius: "1rem"}}>
            <div className="card-body p-5 " >
              <div className="row">
                <div className="col-md-12 mb-3">
                  <h1 className="text-center" style={ { color: "#ffffff", } }>Shooting cameras</h1>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <label className="form-label"><span>Projects:</span></label>
                  <DropDown required={true} value={selectedProject} placeholder="Project" options={projects} onChange={(e) => setSelectedProject(e)} />
                </div>
                <div className="col-md-6">
                  <label className="form-label"><span>Production Camera:</span></label>
                  <DropDown required={true} value={selectedCamera} placeholder="Production Camera" options={filterCameras} onChange={(e) => setSelectedCamera(e)} />
                </div>
              </div>
              <br/>
              <div className="row">
                <div className="col-md-6">
                  <label className="form-label"><span>Name:</span></label>
                  <TextInput className={"width-100"} required={false} placeholder="Name" value={name} onChange={(e) => {setName(e.target.value)}} />
                </div>
                <div className="col-md-6">
                    <label className="form-label"><span>Color:</span></label>
                    <ColorPickerButton color={color} setColor={setColor}/>
                </div>                               
              </div>
              <br/>
              <div className="row">
                <div className="col-md-12">
                  <label className="form-label"><span>Description:</span></label>
                  <TextArea id="newBlank" className="width-100 dw-height-input" required={false} placeholder="Description" onChange={(e) => {setDescription(e.target.value)}}/>
                </div> 
              </div>
              <br/>
              <div className="row">
                <div className="col-md-12">
                  <ButtonPrimary required={true} className="width-100 mb-3" label="Create camera" action={()=>{ createCamera() }}/>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <SortableList items={list} updateList={setList} deleteItem={deleteItem} editItem={editItem} isDisable={true}/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default ShootingCameras;


