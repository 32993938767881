import React from "react";
import Modal from 'react-bootstrap/Modal';
import DwNewScene from"../components/DwNewScene"

const DwNewSceneModal = (props) => {
  return (
    <Modal show={props.show} onHide={() =>{ props.setcurrentselection(""); props.onHide();}}
      dialogClassName="dw-modal-90w"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      
      <Modal.Body>
        <DwNewScene props={props} setnewshot={props.setnewshot}/>
      </Modal.Body>
      {/* <Modal.Footer>
      <ButtonPrimary label="Close" action={props.onHide}/>
      </Modal.Footer> */}
    </Modal>
  );
}

DwNewSceneModal.defaultProps = {
  show: false,
  onHide: null
}

export {DwNewSceneModal}