import { React } from "react";

const DwCheckBox = ({ checked, className, onChange}) => {
  return (
    <input type="checkbox"
      className={className+" dw-checkbox"}
      checked={checked}
      onChange={onChange}
    />
  )
}


DwCheckBox.defaultProps = {
  checked: null,
  onChange: null
}
  
export {DwCheckBox}