import { useEffect } from "react";

const CookieCheck = () =>{
  useEffect(() => {
    cookieCheck();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);
  const getCookie = async function(name) {
    var r = document.cookie.match( "\\b" + name + "=([^;]*)\\b" );
    return r ? r[ 1 ] : null;
  };
  const cookieCheck = async function() {
    getCookie( 'token' ).then(function(result) {
      if ( result !== null ) {  }
      else{window.location.href = "/login";}
    });
  };
}
export default CookieCheck;