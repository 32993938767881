import React, { useState } from "react";
import axios from "axios";
import DwNav from "../components/DwNav";
import { ButtonSecondary } from "../components/forms/Buttons";
import Swal from 'sweetalert2/dist/sweetalert2.js'
import { TextInput} from "../components/forms/TextInputs";
import { useNavigate } from "react-router-dom";

const CreateOrg = () =>{
	let navigate = useNavigate();
	const [organizationNew, setOrganization] = useState('');

	const returnHeaders = function(){
		var token = getCookie( 'token' );
		let headerProposal ={ headers: {'X-BP-Token': token } };
		return headerProposal;
	}
	const getCookie = function( name ) {
		var r = document.cookie.match( "\\b" + name + "=([^;]*)\\b" );
		return r ? r[ 1 ] : null;
	}
	const CreateOrgAPI = async(name)=>{
		if(name === "" || name === null || name === undefined){
			Swal.fire({ icon: "warning", title: "Wait!", text: "Please add a name to the organization" });
		}
		else{
			let json = { "name": name }
			try {
				await axios.post( window.domain + "organization", json, returnHeaders()).then(response => { navigate("/organizations") });
			} 
			catch (error) {
				alert("Error");
				return;
			}
		}    
	}

	return (
	<>
		<DwNav/> 
		<div className = "row d-flex justify-content-center align-items-center h-100">
			<div className="col-12 col-md-9 col-lg-7 col-xl-5">
				<div className="" style={{borderRadius: "1rem"}}>
					<div className="card-body p-5 " >
						<div className="mb-md-5 mt-md-4 pb-5">
							<h1 className="text-center" style={ { color: "#ffffff", } }>Create Organization</h1>
							<TextInput className={"width-100"} required={false} placeholder="Organization name" onChange={(e) => setOrganization(e.target.value)} />
							<ButtonSecondary className="width-100 mb-3" label="Create" action={()=>{ CreateOrgAPI(organizationNew) }}/>
						</div>
					</div>
				</div>
			</div>
		</div>
	</>
	);
}
export default CreateOrg;