import React from "react";
import '@sweetalert2/theme-dark/dark.scss';
import TextField from '@mui/material/TextField';
import { ThemeProvider, createTheme } from '@mui/material/styles';

const darkTheme = createTheme({
  palette: {
    primary: {
      // light: will be calculated from palette.primary.main,
      main: '#ff4400',
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    secondary: {
      light: '#0066ff',
      main: '#0044ff',
      // dark: will be calculated from palette.secondary.main,
      contrastText: '#ffcc00',
    },
     // Provide every color token (light, main, dark, and contrastText) when using
     // custom colors for props in Material UI's components.
     // Then you will be able to use it like this: `<Button color="custom">`
     // (For TypeScript, you need to add module augmentation for the `custom` value)
    custom: {
      light: '#ffa726',
      main: '#f57c00',
      dark: '#ef6c00',
      contrastText: 'rgba(0, 0, 0, 0.87)',
    }
  },
  components:{
    MuiSvgIcon:{
      styleOverrides:"secondary"
    }
  }
});


const TimerInput = ({placeholder, required, type, onKeyDown, onChange, value, className, readOnly,defaultValue, isDisabled, id}) => {
  return (
    <ThemeProvider theme={darkTheme}>
      <TextField color="secondary" id="datetime-local" label="Next appointment" type="datetime-local" defaultValue="2017-05-24T10:30:00"  InputLabelProps={{shrink: true, }} inputProps={{"step":1}}/>
    </ThemeProvider>
  );
}

TimerInput.defaultProps = {
  type :"text",
  placeholder : "...",
  required: false,
  className:""
}

export {TimerInput}