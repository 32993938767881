import React, { useState, useEffect } from "react";
import Modal from 'react-bootstrap/Modal'
import { DropDown } from "../components/forms/TextInputs";
import { DateInput} from "../components/forms/TextInputs";
import { ButtonSecondary } from "./forms/Buttons";
import Swal from 'sweetalert2/dist/sweetalert2.js'

const DwModalTimeSelect = (props) => {	
	const defaultTime = {value:0, label: "00"};
	const [startDate, setStartDate] = useState(new Date());
	const [hour, setHour] = useState(defaultTime);
	const [minute, setMinute] = useState(defaultTime);
	const [second, setSecond] = useState(defaultTime);

	useEffect(()=>{
		if(props.timeSelected){
			setHour(returnValue(0));
			setMinute(returnValue(1));
			setSecond(returnValue(2));
			setStartDate(new Date(props.timeSelected));
		}
	// eslint-disable-next-line
	},[props.timeSelected])

	const getObject = (label) =>{
		let array = fillOptionsNumber(60);
		let index = array.findIndex(x => x.label === label);
		return array[index]
	}
	const returnValue = (index) =>{
		if(!props.timeSelected){
			return defaultTime;
		}
		let t = props.timeSelected.split(" ")[1].split(":");
		return getObject(t[index])
	}

	const fillOptionsNumber = function(num){
		var optionsArray =[];
		for(var i = 0; i <= num; i++) {
			optionsArray.push({value:i, label: i.toString().padStart(2, 0)});
		}
		return optionsArray;
	}
	const save = function(){
		if(props.timeSelected !== ""){
			Swal.fire({
				title: 'Do you want to change the time?',
				showDenyButton: true,
				confirmButtonText: 'Change',
				denyButtonText: `Don't change`
			}).then((result) => {
				if (result.isConfirmed) {
					var SaveFormat = new Intl.DateTimeFormat('az',{year:'numeric', month:'numeric', day:'numeric', hour:'numeric', minute:'numeric', second:'numeric', hour12:false });
					let time = SaveFormat.format(startDate).split(" ")[0] + " " + hour.value + ":" + minute.value + ":" + second.value;
					props.setModalTime(time, props.field);
					props.onHide();
				}
				else if (result.isDenied){}
			});
		}    
	}
	return (
		<Modal show={props.timerModal} onHide={() =>{props.onHide();}} aria-labelledby="contained-modal-title-vcenter" centered >      
			<Modal.Body>
				<div className="row mb-4">
				<div className = "col-11">
				<h4>Select Time</h4>
				</div>
				<div className = "col-1" onClick={props.onHide}>
				<h5 className="text-right">X</h5>
				</div>
				</div>
				<div className="row">
					<div className="col-lg-1"/>
					<div className="col-lg-4">
						<span className="mb-1">Date</span>
						<DateInput className="width-100 time-color-blue" required={true} startDate={startDate} setStartDate={setStartDate}/>
					</div>
					<div className="col-lg-2">
						<span className="mb-1">Hours</span>
						<DropDown className="width-100 textTimer" defaultValue={returnValue(0)} options={fillOptionsNumber(23)} onChange={(e)=>{setHour(e)}} required={false} placeholder="" />
					</div>
					<div className="col-lg-2">
						<span className="mb-1">Minutes</span>
						<DropDown className="width-100 textTimer" defaultValue={returnValue(1)} options={fillOptionsNumber(59)} onChange={(e)=>{setMinute(e)}}  required={false} placeholder="" />
					</div>
					<div className="col-lg-2">
						<span className="mb-1">Seconds</span>
						<DropDown className="width-100 textTimer" defaultValue={returnValue(2)} options={fillOptionsNumber(59)} onChange={(e)=>{setSecond(e)}}  required={false} placeholder="" />
					</div>
					<div className="col-lg-1"/>
				</div>
				<ButtonSecondary className="width-100 mt-4" action={()=>save()} label={"Update Time"}></ButtonSecondary>
			</Modal.Body>
		</Modal>
	)
}

DwModalTimeSelect.defaultProps = { show: false, onHide: null }

export {DwModalTimeSelect}