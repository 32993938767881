import React from "react";
import DwNav from "../components/DwNav";

const Setups = (props) => {
  return (
    <div className="app-container">
      <DwNav />
      <header>
        <h1>Setups Page</h1>
      </header>
    </div>
  )
}

export default Setups