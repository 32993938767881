import React from "react";
import { Navbar, Offcanvas, Nav, NavDropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import axios from "axios";

const domain = window.domain

const DwNav = (props) => {
  
  const getCookie = ( name ) => {
    
    var r = document.cookie.match( "\\b" + name + "=([^;]*)\\b" );
    return r ? r[ 1 ] : null;
  };

  const logout = async() =>{
    // axios.delete(url, { data: { foo: "bar" }, headers: { "Authorization": "***" } });
    axios.delete( domain + "auth", {data:{token:getCookie("token"),headers:{"X-BP-token":getCookie("token")}}}).then(response=>{
      document.cookie.replace(
        // eslint-disable-next-line
        /(?<=^|;).+?(?=\=|;|$)/g,
        name => window.location.hostname
        // eslint-disable-next-line
          .split(/\.(?=[^\.]+\.)/)
          .reduceRight((acc, val, i, arr) => i ? arr[i]='.'+val+acc : (arr[i]='', arr), '')
          .map(domain => document.cookie=`${name}=;max-age=0;path=/;domain=${domain}`)
      );
    });
  }

  return (
    <Navbar expand={false} className="mb-3">
      <Navbar.Toggle />
        <Navbar.Offcanvas
          id="nav"
          placement="start"
          className="dark"
        >
          <Offcanvas.Header closeButton>
            <Offcanvas.Title>BP-DataWrangler</Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <Nav className="navLinks justify-content-end flex-grow-1 pe-3">
            <Link to="/view">View</Link>
            <NavDropdown title="Organizations">
              <Link className="dropdown-item" to="/organizations">Organizations</Link>
              <Link className="dropdown-item" to="/create-org">Create Organizations</Link>
            </NavDropdown>
              {/* <Link to="/organizations">Organizations</Link>
              <Link to="/create-org">Create Organizations</Link> */}
              <Link to="/projects">Projects</Link>
              <Link to="/create-episode">Episodes</Link>
              {/* <Link to="/shots">Shots</Link> */}
              {/* <Link to="/setups">Setups</Link> */}
              <Link className="disableLink" to="#">Takes</Link>
              <NavDropdown title="Reports">
               <Link className="dropdown-item" to="/reports/daily">Daily Production Report</Link>
               <Link className="dropdown-item" to="/reports/camera">Camera Report</Link>
              </NavDropdown>
              <Link className="disableLink" to="#">Export</Link>
              <NavDropdown title="Settings">
                <Link className="dropdown-item" to="/defaults">Defaults</Link>
                <Link className="dropdown-item" to="/lens">Lens</Link>
                <Link className="dropdown-item" to="/create-cameras">Production Cameras</Link>
                <Link className="dropdown-item" to="/create-shooting-cameras">Shooting Cameras</Link>
                <Link className="dropdown-item" to="/shotgrid">Shotgrid</Link>
                {/* <NavDropdown className="dropdown-item" title="Colors">
                  <Link className="dropdown-item" to="/update-colors">Update Colors</Link>
                </NavDropdown> */}
              </NavDropdown>
              <Link onClick={()=>logout()} to="/login" >Logout</Link>
              {/* <NavDropdown title="Settings">
                <NavDropdown title="Organization">
                  <NavDropdown.Item href="#TBA">Production Cameras</NavDropdown.Item>
                  <NavDropdown.Item href="#TBA">Lenses</NavDropdown.Item>
                  <NavDropdown.Item href="#TBA">VFX Types</NavDropdown.Item>
                  <NavDropdown.Item href="#TBA">Filters</NavDropdown.Item>
                  <NavDropdown.Item href="#TBA">Members</NavDropdown.Item>
                  <NavDropdown.Item href="#TBA">Integrations</NavDropdown.Item>
                  <NavDropdown.Item href="#TBA">Budget Impacts</NavDropdown.Item>
                  <NavDropdown.Divider />
                </NavDropdown>
                </NavDropdown> */}
                {/* 
                <NavDropdown title="Project">
                  <NavDropdown.Item href="#TBA">Episodes</NavDropdown.Item>
                  <NavDropdown.Item href="#TBA">Shooting Cameras</NavDropdown.Item>
                  <NavDropdown.Item href="#TBA">Significant Objects</NavDropdown.Item>
                  <NavDropdown.Item href="#TBA">Resolutions</NavDropdown.Item>
                  <NavDropdown.Item href="#TBA">Focal Lengths</NavDropdown.Item>
                  <NavDropdown.Item href="#TBA">T-Stops</NavDropdown.Item>
                  <NavDropdown.Item href="#TBA">Tilts</NavDropdown.Item>
                  <NavDropdown.Item href="#TBA">FPS</NavDropdown.Item>
                  <NavDropdown.Item href="#TBA">Shutters</NavDropdown.Item>
                  <NavDropdown.Item href="#TBA">White Balances</NavDropdown.Item>
                  <NavDropdown.Item href="#TBA">ISOs</NavDropdown.Item>
                  <NavDropdown.Item href="#TBA">Groups / Crews</NavDropdown.Item>
                  <NavDropdown.Divider />
                </NavDropdown>
                <NavDropdown title="User">
                  <NavDropdown.Item href="#TBA">Credentials</NavDropdown.Item>
                  <NavDropdown.Item href="#TBA">Preferences</NavDropdown.Item>
                  <NavDropdown.Divider />
                </NavDropdown>
              </NavDropdown> */}
              
            </Nav>
          </Offcanvas.Body>
        </Navbar.Offcanvas>
    </Navbar>
  )
}

export default DwNav