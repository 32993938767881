import React, { useEffect } from "react";
import axios from "axios";
import Swal from 'sweetalert2/dist/sweetalert2.js'

const Activation = () =>{

    useEffect(()=>{
        getData();
        // eslint-disable-next-line
        },[]);

        const getData = async() =>{
            let url = new URLSearchParams(window.location.search);
            let token = url.get("token");
            await axios.get( window.domain + "user?token=" + token).then(response => {
                if(response.data.success ){
                    window.location.href = "/login";
                }
                else{
                    Swal.fire({ 
                        title: 'Error', 
                        text: "Oops, something went wrong!", 
                        confirmButtonText: 'OK'
                    });
                }
            }).catch((e) =>{
                if(e.response.data.success){
                    window.location.href = "/login";
                }
                else{
                    Swal.fire({ 
                        title: "ERROR!",
                        text: "Invalid activation code.", 
                        confirmButtonText: 'OK'
                    })
                    .then((result) => {
                        if (result.isConfirmed) {
                            window.location.href = "/login";
                        }});
                }
            }
                
            );
        }
    return (<></>);
}
export default Activation;