import React, { useState } from "react";
import Header from "../components/Header";
import {TextInput, DropDown, MultiDropDown, DateInput, TimeInput } from "../components/forms/TextInputs";
import {ButtonPrimary, ButtonSecondary} from "../components/forms/Buttons";
import {TimerInput} from "../components/forms/TimeInput";
import DwNav from "../components/DwNav";
import {DwNewShotModal} from '../components/DwModals';
import EnhancedTable from "../components/DwTakesTable";

import DwCookie from"../components/DwCookieCheck"
import Swal from 'sweetalert2/dist/sweetalert2.js'
import '@sweetalert2/theme-dark/dark.scss';

// import dayjs from 'dayjs';

import { useNavigate } from "react-router-dom";

function triggerAlert() {
  Swal.fire({
    title: 'Error!',
    text: 'Do you want to continue',
    icon: 'error',
    confirmButtonText: 'Cool'
  })
}


let options2=[{ value: 'y', label: 'y' },{ value: 'z', label: 'z' },{ value: 'w', label: 'w' },{ value: 'r', label: 'r' },{ value: 'v', label: 'v' },{ value: 'a', label: 'a' }]
const Home = (props) => {
  const [modalShow, setModalShow] = useState(false);
  let navigate = useNavigate();
  // let location = useLocation();
  // let params = useParams();

  // const handleChange = (newValue) => {
  //   setValue(newValue);
  // };

  // const [value, setValue] = React.useState(dayjs('2014-08-18T21:11:54'));



// const darkTheme = createTheme({
//   palette: {
//     mode: 'dark',
//   }
// });
  return (
    <div className="app-container">
      <DwCookie/>
      <DwNav/>
      <Header title="Bleh asd" />
      <TextInput required={true}/>
      <ButtonPrimary label="Take me to Projects" required={true} action={() => {  navigate("/projects" )}} />
      <ButtonPrimary label="Not Required" />
      <ButtonSecondary label="Do Nothing"/>
      <ButtonSecondary label="Do Nothing required" required={true}/>
      <ButtonSecondary label="Alert me!" required={true} action={() => { triggerAlert() }}/>
      <br/>
      <DropDown required={false} placeholder="Single-Dropdown"/>
      <DropDown required={true} placeholder="Required Single-Dropdown"/>
      <MultiDropDown options={options2} required={false} placeholder="Multi-Dropdown"/>
      <MultiDropDown required={true} placeholder="Multi-Dropdown Required"/>
      <DateInput/>
      <DateInput required={true}/>
      {/* <ThemeProvider theme={darkTheme}>
      <CssBaseline /> */}
      <TimerInput/>

    {/* </ThemeProvider> */}
      <br/><br/>
      <TimeInput/>
      <TimeInput required={true}/>
      <ButtonSecondary label="Launch Modal" action={() => setModalShow(true)} required={true}/>
      <DwNewShotModal
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
      <EnhancedTable />
    </div>
  )
}


export default Home;

