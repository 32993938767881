import React, { useState ,useEffect } from "react";
import axios from "axios";
import { DropDown} from "../components/forms/TextInputs";
import { useNavigate } from "react-router-dom";

const Organization = () =>{
	let navigate = useNavigate();
	// let location = useLocation();
	// let params = useParams();
	// var optionsArray = [];
	const [organization, setOrganization] = useState('');

	useEffect(() => {
		getOrganizations()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	},[])
	const getOrganizations = async function(){
		try {
			await axios.get( window.domain +"organization",returnHeaders() ).then(res => {
				setOrganization(res["data"])
				if(res["data"].length === 0){
					navigate("/create-org")
				}
			});
		} 
		catch (error) {
			alert("Error");
			return;
		}
	}
	const returnHeaders = function(){
		var token = getCookie('token');
		let headerProposal ={headers:{'X-BP-Token':token}};
		return headerProposal;
	}
	const getCookie = function( name ){
		var r = document.cookie.match( "\\b" + name + "=([^;]*)\\b" );
		return r ? r[ 1 ] : null;
	}
	// eslint-disable-next-line no-unused-vars
	const cookieCheck = async function (){
		var token = getCookie( 'token' );
		if ( token !== null ) { window.location.href = "/main"; }
	}
	const changeDropDown = function(e){
		localStorage.setItem( 'oid', e.value )
		navigate("/view")
	}
	const fillOptions = function(){
		var optionsArray = []
		if(organization.length>0){
			for (let index = 0; index < organization.length; index++) {
				optionsArray.push({value:organization[index].oid, label:organization[index].name})
			}
			// organization.map(function(key, index ){
			//   optionsArray.push({value:key.oid, label:key.name})
			// });
		}    
		return optionsArray;
	}
	return(
		<>
			<div className = "row d-flex justify-content-center align-items-center h-100">
				<div className="col-12 col-md-8 col-lg-4 col-xl-3">
					<div className="" style={{borderRadius: "1rem"}}>
						<div className="card-body p-5 " >
							<div className="mb-md-5 mt-md-4 pb-5">
								<h1 className="text-center" style={ { color: "#ffffff", } }>Organization</h1>
								<DropDown required={false} placeholder="Select " options={fillOptions()} onChange={(e) => changeDropDown(e)} /> 
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="centerContent">
				<br/>
				<p className="bp-p">“ If the Organization you are looking for is not visible please contract your Department Producer or Coordinator to set it up. ”</p></div>
			<div className="centerContent">
				<br/>
				<p className="bp-p">“ If you are starting a new project and /or need to set up a new Organization, <a className="orangeLink" href="/create-org">click here</a> ”</p>
			</div>
		</>
	)
}
export default Organization;