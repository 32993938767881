import React, {useState, useEffect} from "react"
import reactCSS from "reactcss"
import { ChromePicker } from "react-color"

const ColorPickerButton = (props) =>{
	const [color, setColor] = useState(props.color);
	const [displayColorPicker, setdisplayColorPicker] = useState(false);
	const styles = reactCSS({
		"default": {
			color: {
				width: "100%",
				height: "100%",
				borderRadius: "2px",
				background: color,
			},
			swatch: {
				padding: "5px",
				background: "#fff",
				borderRadius: "1px",
				boxShadow: "0 0 0 1px rgba(0,0,0,.1)",
				display: "inline-block",
				cursor: "pointer",
				width:"100%",
				height:"38px"
			},
			popover: {
				position: "absolute",
				zIndex: "2",
			},
			cover: {
				position: "fixed",
				top: "0px",
				right: "0px",
				bottom: "0px",
				left: "0px",
			},
		},
	});
	useEffect(() =>{
		setColor(props.color);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	  },[props.color]);

	const handleClick = () => {
		setdisplayColorPicker(!displayColorPicker);
	}
	const handleClose = () => {
		setdisplayColorPicker(false);
	}
	const handleChange = (color) => {
		setColor(color.hex);
		props.setColor(color.hex);
	}

	return (
        <div>
            <div style={ styles.swatch } onClick={ ()=>{handleClick()} }>
                <div style={ styles.color } />
            </div>
            { 
                displayColorPicker ? <div style={ styles.popover }>
                <div style={ styles.cover } onClick={ ()=>{handleClose()} }/>
                <ChromePicker disableAlpha color={ color } onChange={ (e)=>{handleChange(e)} } />
                </div> : null 
            }
        </div>
    )
}

export {ColorPickerButton}