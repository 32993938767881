import React, { useState, useEffect } from "react";
import axios from "axios";
import { ButtonSecondary } from "../components/forms/Buttons";
import '@sweetalert2/theme-dark/dark.scss';
import {TextInput, DropDown } from "../components/forms/TextInputs";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCamera, faTrash } from "@fortawesome/free-solid-svg-icons";

import Chip from '@mui/material/Chip';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';


const DwTableDiv =(props)=>{
  // const [selectedValues,setSelectedValues] = useState([{value: 'Pro Mist', label: 'Pro Mist'}]);
  const [unit, setUnit] = useState("ft");

  const getCookie = function( name ) {
    var r = document.cookie.match( "\\b" + name + "=([^;]*)\\b" );
    return r ? r[ 1 ] : null;
  }
  const returnHeaders = function(){
    var token = getCookie( 'token' );
    let headerProposal ={
    headers: {
        'X-BP-Token': token,
      }
    };
    return headerProposal;
    
  }
  const updateTake=async(tkid,value,name)=>{
    let json = {"tkid" : tkid, "uid": parseInt(localStorage.getItem("uid"))};
    json[name] = (name === "tilt") ? value.replace("+","").replace(" ","") : value;
    try {
      await axios.put( window.domain + "take",json,returnHeaders()).then(response => {
        if(name === "camera" || name === "number"){
          props.gettake();
        }
        else{
          props.gettake(true);
        }
      });
    }
    catch{ }

    // let json = {}
    // let names= "";
    // switch(name){
    //   case "number":
    //     json = {
    //       "tkid" : tkid,
    //       "uid": parseInt( localStorage.getItem("uid")),
    //       "number": value
    //     }
        
    //   break; 
    //   case "camera":
    //     json = {
    //       "tkid" : tkid,
    //       "uid": parseInt( localStorage.getItem("uid")),
    //       "camera": value
    //     }
    //   break; 
    //   case "image":
    //     json = {
    //       "tkid" : tkid,
    //       "uid": parseInt( localStorage.getItem("uid")),
    //       "image": value
    //     }
    //   break;
    //   case "focal_length":
    //     json = {
    //       "tkid" : tkid,
    //       "uid": parseInt( localStorage.getItem("uid")),
    //       "focal_length": value
    //     }
    //   break;
    //   case "lid":
    //     json = {
    //       "tkid" : tkid,
    //       "uid": parseInt( localStorage.getItem("uid")),
    //       "lid": value
    //     }
    //   break;
    //   case "sig_object":
    //     json = {
    //       "tkid" : tkid,
    //       "uid": parseInt( localStorage.getItem("uid")),
    //       "sig_object": value
    //     }
    //   break; 
    //   case "distance":
    //     json = {
    //       "tkid" : tkid,
    //       "uid": parseInt( localStorage.getItem("uid")),
    //       "distance": value
    //     }
    //   break;
    //   case "tilt":
        
    //     value = value.replace("+","")
    //     value = value.replace(" ","")
    //     json = {
    //       "tkid" : tkid,
    //       "uid": parseInt( localStorage.getItem("uid")),
    //       "tilt": parseInt(value)
    //     }
    //   break;
    //   case "height":
    //     json = {
    //       "tkid" : tkid,
    //       "uid": parseInt( localStorage.getItem("uid")),
    //       "height": value
    //     }
    //   break;
    //   case "dolly_dist":
    //     json = {
    //       "tkid" : tkid,
    //       "uid": parseInt( localStorage.getItem("uid")),
    //       "dolly_dist": value
    //     }
    //   break;
    //   case "scid":
    //     json = {
    //       "tkid" : tkid,
    //       "uid": parseInt( localStorage.getItem("uid")),
    //       "scid": value
    //     }
    //   break;
    //   case "resolution":
    //     json = {
    //       "tkid" : tkid,
    //       "uid": parseInt( localStorage.getItem("uid")),
    //       "resolution": value
    //     }
    //   break; 
    //   case "t_stop":
    //     json = {
    //       "tkid" : tkid,
    //       "uid": parseInt( localStorage.getItem("uid")),
    //       "t_stop": value
    //     }
    //   break;
    //   case "fps":
    //     json = {
    //       "tkid" : tkid,
    //       "uid": parseInt( localStorage.getItem("uid")),
    //       "fps": value
    //     }
    //   break;
    //   case "white_balance":
    //     json = {
    //       "tkid" : tkid,
    //       "uid": parseInt( localStorage.getItem("uid")),
    //       "white_balance": value
    //     }
    //   break;
    //   case "shutter":
    //     json = {
    //       "tkid" : tkid,
    //       "uid": parseInt( localStorage.getItem("uid")),
    //       "shutter": value
    //     }
    //   break;
    //   case "iso":
    //     json = {
    //       "tkid" : tkid,
    //       "uid": parseInt( localStorage.getItem("uid")),
    //       "iso": value
    //     }
    //   break;
    //   case "filter_on_lens":
    //     // for(var i = 0; i <= value.length; i++){
    //     //   if(value[i]){
    //     //     if (names === ""){
    //     //       names=value[i]["label"]
    //     //     }
    //     //     else{
    //     //       names=names+","+value[i]["label"]
    //     //     }
    //     //   }
    //     // }
    //     json = {
    //       "tkid" : tkid,
    //       "uid": parseInt( localStorage.getItem("uid")),
    //       "filter_on_lens": value
    //     }
    //   break;  
    //   case "filters_to_add":
    //     // for(var i = 0; i <= value.length; i++){
    //     //   if(value[i]){
    //     //     if (names === ""){
    //     //       names=value[i]["label"]
    //     //     }
    //     //     else{
    //     //       names=names+","+value[i]["label"]
    //     //     }
    //     //   }
    //     // }
    //     json = {
    //       "tkid" : tkid,
    //       "uid": parseInt( localStorage.getItem("uid")),
    //       "filters_to_add": value
    //     }
    //   break;
    //   case "notes":
    //     json = {
    //       "tkid" : tkid,
    //       "uid": parseInt( localStorage.getItem("uid")),
    //       "notes": value
    //     }
    //   break;
    // }

    // try {
    //   await axios.put( window.domain+"take",json,returnHeaders()).then(res => {
    //     if(name === "camera" || name === "number"){
    //       props.gettake()
    //     }
    //     else{
    //       props.gettake(true)
    //     }
    //   });
    // }
    // catch{}
  }
  // const multiDropDownValues=(option, values)=>{
  //   var variable =[];
  //   let valueReturn=[]
  //   if(option === null){
  //     return
  //   }
  //   if(option.includes(",")){
  //     valueReturn=option.split(",");
  //     if(valueReturn[i])
  //     for(var i = 0; i <= valueReturn.length; i++){
  //         for(var x = 0; x <= values.length; x++){
  //           if(values[x]){
  //             if(valueReturn[i] === values[x].label){
  //               variable.push(values[x])
  //             }
  //           }
  //         }
  //     }
  //   }
  //   else{
  //     for(var i = 0; i <= values.length; i++){
  //       if(values[i]){
  //         if(option === values[i].label){
  //           variable.push(values[i])
  //         }
  //       }
  //     }
  //   }
  //   if(typeof variable !== "undefined"){
      
  //   }
  //   else{
  //       setTimeout(variable, 250);
  //   }
  //   return variable;
  // }
  // const printNotes =(notes)=>{
  // }
  const defaultTake =(index)=>{
    var optionsArray ={value:index, label:(index).toString()}
    return optionsArray;
  }
  // const returndefaultValue=(options, value)=>{
  //   let valueReturn={};
  //   for(var i = 0; i <= options.length; i++){
  //     if(options[i]){
  //       if(options[i].label.toString() === value.toString()){
  //         valueReturn= options[i];
  //       }
  //     }

  //   }
  //   if(valueReturn === {}){
  //     valueReturn = {value:value, label:value.toString()}
  //   }  
  //   return valueReturn;
  // }
  // const returndefaultValueTilt=(options, value)=>{
  //   let valueReturn={};
  //   for(var i = 0; i <= options.length; i++){
  //     if(options[i]){
  //       let numberVal = options[i].label.toString()
  //       numberVal = numberVal.replace("+","")
  //       numberVal = numberVal.replace(" ","")
  //       if(parseInt(numberVal) === value){
  //         valueReturn= options[i];
  //       }
  //     }

  //   }
  //   if(valueReturn === {}){
  //     valueReturn = {value:value, label:value.toString()}
  //   }  
  //   return valueReturn;
  // }
  // const returndefaultValueFocalL=(options, value)=>{
  //   let valueReturn={};
  //   for(var i = 0; i <= options.length; i++){
  //     if(options[i]){
  //       if(options[i].label.toString() === value.toString()+"mm"){
  //         valueReturn= options[i];
  //       }
  //     }

  //   }
  //   if(valueReturn === {}){
  //     valueReturn = {value:value, label:value.toString()}
  //   }  
  //   return valueReturn;
  // }
  const defaultCamera =(camera)=>{
    var optionsArray ={value:camera, label:camera}
    return optionsArray;
  }
  const fillOptionsNumber = function(){
    var optionsArray =[]
    for(var i = 1; i <= 200; i++) {
      optionsArray.push({value:i, label:i.toString()})
    }
    return optionsArray;
  }
  const fillOptionsAlfabet = function(){
    var optionsArray =[]
    optionsArray.push({value:-1, label:""})
    for(var i = 0; i <= 25; i++) {
      optionsArray.push({value:(i+10).toString(36).toUpperCase(), label:(i+10).toString(36).toUpperCase()})
    }
    return optionsArray;
  }
  const imageCont=(image)=>{
    let class_name ="";
    if(image === "" || image === null){
      class_name = "fa-camera image-table transparent"
    }
    else{
      class_name = "fa-camera image-table"
    }
    return class_name
  }
  // const backCameraColor=(camera)=>{
  //   let value = 0
  //   value = (camera.charCodeAt(0) - 65)

  //   return value;
  // }
  // const deleteTask=async(tkid)=>{
  //   var token = getCookie( 'token' );
  //   let json ={"tkid":tkid}
  //   try {
  //     await axios.delete( window.domain+"take",{headers: {'X-BP-Token': token}, data: {"tkid":tkid}}).then(response => {props.gettake()});
  //   }
  //   catch{
  //   }
  // }
  const multiChange = (tkid, value, field)=>{    
    let newValue = [];    
    if(["distance", "dolly_dist", "height"].includes(field) && value[0] !== undefined ){
      let pop = value.pop();
      if(!isNaN(pop)){
        pop = pop + (unit === "ft" ? "'" : "m");
      }      
      value.push(pop);
    }
    if(field === "tilt" && value[0] !== undefined){
      let pop = value.pop();
      pop = pop + "°";
      value.push(pop);
    }
    newValue = value.join(",");
    updateTake(tkid, newValue, field);
  }
  const returnSplit=(options)=>{
    if (options !== null && options !== ""){
      return options.toString().split(",");
    }
    return;
  }
  const updateUnit=()=>{
    let newUnit = unit === "ft" ? "m" : "ft";
    setUnit(newUnit);
    let name = newUnit === "m" ? "metric" : "imperial";
    props.updateUnits(name);
  }

  const keyboardEvent = new KeyboardEvent('keydown', {code: 'Enter', key: 'Enter', charCode: 13, keyCode: 13, view: window, bubbles: true });

  const enterFunction = function(e, value){
    if(e.target.value !== ""){
      e.target.dispatchEvent(keyboardEvent);
    }    
  }
  const getColor = (letter) =>{
    // let color = props.colors.find((obj) => obj.value === letter).color;
    let color = props.cameras.find((obj) => obj.value === letter).color;
    return color;
  }
  
  return (
    <div className="" >
      <table className="table" >
        <thead>
          <tr>
            <th scope="col"><span className="dw-text-important">CAM</span></th>
            <th scope="col"><span className="dw-text-important">TAKE</span></th>
            <th scope="col"><span>IMG</span></th>
            <th scope="col"><span className="dw-text-important">FOCAL LENGTH (mm)</span></th>
            <th scope="col"><span className="dw-text-important" onClick={()=>updateUnit()}>DISTANCE ({unit})</span></th>
            <th scope="col" className="specialSpaceTH"><span>SIGNIFICANT OBJ</span></th>
            <th scope="col"><span className="dw-text-important">TILT (°)</span></th>
            <th scope="col"><span onClick={()=>updateUnit()}>HEIGHT ({unit})</span></th>
            <th scope="col"><span onClick={()=>updateUnit()}>DOLLY DIST. ({unit})</span></th>
            <th scope="col"><span>FILTERS ON CAMERA</span></th>
            <th scope="col"><span>ADD FILTERS IN POST</span></th>
            <th scope="col"><span>NOTE</span></th>
            <th scope="col"></th>
            <th scope="col"></th>
          </tr>
        </thead>
        <tbody>
        {props.take.map( function(key, index) {
              return <tr key={index} style={{ backgroundColor: getColor(key.camera)}}>
                      <th className="table-th" id="camera" scope="row" style={{ width: "5%"}}><DropDown required={true} options={props.cameras} defaultValue={defaultCamera(key.camera)} onChange={(e) => {updateTake(key.tkid, e.value,"camera")}}/></th>
                      <th className="table-th" id="take" scope="row" style={{ width: "5%"}}><DropDown required={true} onChange={(e) => {updateTake(key.tkid, e.value,"number")}} options={fillOptionsNumber()} defaultValue={defaultTake(key.number)}/></th>
                      <th className="table-th" id="img" scope="row" style={{ width: "2%"}} onClick={() => {props.setselectedtake(key); props.setimagenupload(true);}  }><FontAwesomeIcon className={imageCont(key.image)} icon={faCamera} /></th>
                      {/* <th className="table-th" id="focalLength" scope="row" style={{ width: 150}}><DropDown required={true} onChange={(e) => {updateTake(key.tkid, e.value,"focal_length")}} options={props.focal_length} defaultValue={returndefaultValueFocalL(props.focal_length, key.focal_length)}/></th> */}
                      <th className="table-th" id="focalLength" scope="row" style={{ width: "8%"}}><Autocomplete onBlur={(e)=>{enterFunction(e)}} className="autocomplete important" onChange={(event, value)  => {multiChange(key.tkid, value,"focal_length")}} multiple limitTags={1} id="multiple-limit-tags" defaultValue={returnSplit(key.focal_length)} options={props.focal_length.map((option) => option.label.toString())} freeSolo renderTags={(value, getTagProps) => value.map((option, index) => (<Chip variant="outlined" label={option} {...getTagProps({ index })}/>))} renderInput={(params) => (<TextField {...params} variant="filled" placeholder="" />)}/></th>
                      {/* <th className="table-th" id="distance" scope="row" style={{ width: 80}}><DropDown required={true}  onChange={(e) => {updateTake(key.tkid, e.value,"distance")}} options={props.distance} defaultValue={returndefaultValue(props.distance, key.distance)}/></th> */}
                      <th className="table-th" id="distance" scope="row" style={{ width: "7%"}}><Autocomplete onBlur={(e)=>{enterFunction(e)}} className="autocomplete important" onChange={(event, value)  => {multiChange(key.tkid, value,"distance")}} multiple limitTags={1} id="multiple-limit-tags" defaultValue={returnSplit(key.distance)} options={props.distance.map((option) => option.label.toString())} freeSolo renderTags={(value, getTagProps) => value.map((option, index) => (<Chip variant="outlined" label={option} {...getTagProps({ index })}/>))} renderInput={(params) => (<TextField {...params} variant="filled" placeholder="" />)}/></th>
                      {/* <th className="table-th" id="significantObj" scope="row" style={{ width: 120}}><DropDown onChange={(e) => {updateTake(key.tkid, e.label,"sig_object")}} options={props.significantobj} defaultValue={returndefaultValue(props.significantobj, key.sig_object)}/></th> */}
                      <th className="table-th" id="significantObj" scope="row" style={{ width: "7%"}}><Autocomplete onBlur={(e)=>{enterFunction(e)}} className="autocomplete important" onChange={(event, value)  => {multiChange(key.tkid, value,"sig_object")}} multiple limitTags={1} id="multiple-limit-tags" defaultValue={returnSplit(key.sig_object)} options={props.significantobj.map((option) => option.label.toString())} freeSolo renderTags={(value, getTagProps) => value.map((option, index) => (<Chip variant="outlined" label={option} {...getTagProps({ index })}/>))} renderInput={(params) => (<TextField {...params} variant="filled" placeholder="" />)}/></th>
                      {/* <th className="table-th" id="tilt" scope="row" style={{ width: 150}}> <DropDown onChange={(e) => {updateTake(key.tkid, e.label,"tilt")}} options={props.tilt} defaultValue={returndefaultValueTilt(props.tilt, key.tilt)} /> </th> */}
                      <th className="table-th" id="tilt" scope="row" style={{ width: "7%"}}><Autocomplete onBlur={(e)=>{enterFunction(e)}} className="autocomplete important" onChange={(event, value)  => {multiChange(key.tkid, value,"tilt")}} multiple limitTags={1} id="multiple-limit-tags" defaultValue={returnSplit(key.tilt)} options={props.tilt.map((option) => option.label.toString())} freeSolo renderTags={(value, getTagProps) => value.map((option, index) => (<Chip variant="outlined" label={option} {...getTagProps({ index })}/>))} renderInput={(params) => (<TextField {...params} variant="filled" placeholder="" />)}/></th>
                      {/* <th className="table-th" id="height" scope="row" style={{ width: 150}}><DropDown onChange={(e) => {updateTake(key.tkid, e.label,"height")}} options={props.height} defaultValue={returndefaultValue(props.height, key.height)}/></th> */}
                      <th className="table-th" id="height" scope="row" style={{ width: "7%"}}><Autocomplete onBlur={(e)=>{enterFunction(e)}} className="autocomplete" onChange={(event, value)  => {multiChange(key.tkid, value,"height")}} multiple limitTags={1} id="multiple-limit-tags" defaultValue={returnSplit(key.height)} options={props.height.map((option) => option.label.toString())} freeSolo renderTags={(value, getTagProps) => value.map((option, index) => (<Chip variant="outlined" label={option} {...getTagProps({ index })}/>))} renderInput={(params) => (<TextField {...params} variant="filled" placeholder="" />)}/></th>
                      {/* <th className="table-th" id="dollyDist" scope="row" style={{ width: 150}}><DropDown onChange={(e) => {updateTake(key.tkid, e.label,"dolly_dist")}}  height options={props.dollydistance} defaultValue={returndefaultValue(props.dollydistance, key.dolly_dist)}/></th> */}
                      <th className="table-th" id="dollyDist" scope="row" style={{ width: "7%"}}><Autocomplete onBlur={(e)=>{enterFunction(e)}} className="autocomplete" onChange={(event, value)  => {multiChange(key.tkid, value,"dolly_dist")}} multiple limitTags={1} id="multiple-limit-tags" defaultValue={returnSplit(key.dolly_dist)} options={props.dollydistance.map((option) => option.label.toString())} freeSolo renderTags={(value, getTagProps) => value.map((option, index) => (<Chip variant="outlined" label={option} {...getTagProps({ index })}/>))} renderInput={(params) => (<TextField {...params} variant="filled" placeholder="" />)}/></th>
                      {/* <th className="table-th" id="filtersOncamera" scope="row" style={{ width: 250 , height : 50} }><MultiDropDown options={props.filtters} onChange={(e) =>{updateTake(key.tkid,e,"filter_on_lens")} } defaultValue={multiDropDownValues(key.filter_on_lens,props.filtters)} /></th> */}
                      <th className="table-th" id="filtersOncamera" scope="row" style={{ width: "8%"}}><Autocomplete onBlur={(e)=>{enterFunction(e)}} className="autocomplete" onChange={(event, value)  => {multiChange(key.tkid, value,"filter_on_lens")}} multiple limitTags={1} id="multiple-limit-tags" defaultValue={returnSplit(key.filter_on_lens)} options={props.filtters.map((option) => option.label.toString())} freeSolo renderTags={(value, getTagProps) => value.map((option, index) => (<Chip variant="outlined" label={option} {...getTagProps({ index })}/>))} renderInput={(params) => (<TextField {...params} variant="filled" placeholder="" />)}/></th>
                      {/* <th className="table-th" id="addFilters" scope="row" style={{ width: 250}}><MultiDropDown options={props.filtters} onChange={(e) =>{updateTake(key.tkid,e,"filters_to_add")} } defaultValue={multiDropDownValues(key.filters_to_add,props.filtters)}/></th> */}
                      <th className="table-th" id="addFilters" scope="row" style={{ width: "8%"}}><Autocomplete onBlur={(e)=>{enterFunction(e)}} className="autocomplete" onChange={(event, value)  => {multiChange(key.tkid, value,"filters_to_add")}} multiple limitTags={1} id="multiple-limit-tags" defaultValue={returnSplit(key.filters_to_add)} options={props.filtters.map((option) => option.label.toString())} freeSolo renderTags={(value, getTagProps) => value.map((option, index) => (<Chip variant="outlined" label={option} {...getTagProps({ index })}/>))} renderInput={(params) => (<TextField {...params} variant="filled" placeholder="" />)}/></th>
                      <th className="table-th" id="note" scope="row" style={{ width: "25%"}}><TextInput className={"width-100"} defaultValue={key.notes} onChange={(e) =>{updateTake(key.tkid,e.target.value,"notes")}}/></th>
                      {/* <th className="table-th" id="autocompleteTags" scope="row" style={{ width: 200}}><Autocomplete onChange={(event, value)  => {multiChange(key.tkid, value,"focal_length")}} multiple limitTags={2} id="multiple-limit-tags" defaultValue={returnSplit(key.filter_on_lens)} options={props.filtters.map((option) => option.label.toString())} freeSolo renderTags={(value, getTagProps) => value.map((option, index) => (<Chip variant="outlined" label={option} {...getTagProps({ index })}/>))} renderInput={(params) => (<TextField {...params} variant="filled" placeholder="" />)}/></th> */}
                      <th className="table-th" id="options" scope="row"><ButtonSecondary label={"\u22EE"} action={() => { props.setselectedtake(key); props.setmodaloptionsshow(true);}} /></th>
                      <th className="table-th" id="img" scope="row" style={{ width: "3%"}} ><FontAwesomeIcon onClick={(e) =>{props.setselectedtake(key); props.setmodaldeleteshow(true);}} className="fa-camera image-table" icon={faTrash} /></th>
                    </tr>
            })}
        </tbody>
      </table>
    </div>
    

  );
    
}
export {DwTableDiv} ;