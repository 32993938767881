import React, { useState,useEffect } from "react";
import Modal from 'react-bootstrap/Modal'
import { ButtonPrimary } from "./forms/Buttons";

const DwGenericModal = (props) => {

  const [img, setimg] = useState();

  useEffect(()=>{    
    setimg(props.image);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[props.image])

  const getCookie = function( name ) {
    var r = document.cookie.match( "\\b" + name + "=([^;]*)\\b" );
    return r ? r[ 1 ] : null;
  };
  const ClickUpload=function()
  {
    let fileInput = document.querySelector(".file-input");
    fileInput.click();
  };
  async function sendData(data) {
    var token = getCookie( 'token' );
    let fileInput = document.querySelector(".file-input");

    if(props.wait === "true"){
      setimg(URL.createObjectURL(fileInput.files[0]));
      props.setImg(URL.createObjectURL(fileInput.files[0]));
      props.setFile(fileInput.files[0]);
      document.getElementById("loadPending").remove();
    }
    else{
      const formData  = new FormData();
      let url =  window.domain+"image?entity=" + props.entity + "&id=" + props.id;
      formData.append('img', fileInput.files[0], fileInput.files[0].name.toLowerCase());
  
      await fetch(url, {method: 'POST', body: formData, headers:{'X-BP-Token': token}}).then(response => response.json()).then(data => {
        setimg(data.file);
        document.getElementById("loadPending").remove();
        props.update();
        
        let img = document.getElementById("img_" + props.keyMap);
        img.src = data.file;
      });
    }    
    
  };
  async function sendDelete(data) {
    var token = getCookie( 'token' );
    let fileInput = document.querySelector(".file-input");

    if(props.wait === "true"){
      setimg(URL.createObjectURL(fileInput.files[0]));
      props.setImg(URL.createObjectURL(fileInput.files[0]));
      props.setFile(fileInput.files[0]);
      document.getElementById("loadPending").remove();
    }
    else{
      let url =  window.domain+"image?entity=" + props.entity + "&id=" + props.id;
  
      await fetch(url, {method: 'DELETE', headers:{'X-BP-Token': token}}).then(response => response.json()).then(data => {
        setimg(window.domainBase + "/img/placeholder.png");
        props.update();        
        let img = document.getElementById("img_" + props.keyMap);
        img.src = window.domainBase + "/img/placeholder.png";
      });
    }    
    
  };
  const changeInput = (target)=>{
    let progressArea = document.querySelector(".progress-area");
    let file = target.files[0]; //getting file [0] this means if user has selected multiple files then get first one only
    if(file){
      let fileName = file.name; //getting file name
      if(fileName.length >= 12){ //if file name length is greater than 12 then split it and add ...
        let splitName = fileName.split('.');
        fileName = splitName[0].substring(0, 13) + "... ." + splitName[1];
      }
    }
    let html = `<li id="loadPending" class="row">
                            <i class="fas fa-file-alt"></i>
                            <div class="content">
                              <div class="details">
                                <span class="name">${file.name} • Pending</span>
                              </div>
                              
                            </div>
                          </li>`;
    progressArea.innerHTML = html;
  };
  return (
    <Modal {...props} aria-labelledby="contained-modal-title-vcenter" centered >
      <Modal.Body>
        <div className="wrapper">
          <header>File Uploader</header>
          <div className="width-100">
            <img className="width-100 ratio_noCursor" src={img} alt=""></img>
          </div>          
          <form action="#" onClick={() => ClickUpload()}>
            <input className="file-input" type="file" name="file" hidden onChange={(e)=>{changeInput(e.target)}} accept=".png, .jpg, .jpeg, .JPG"></input>
            <i className="fas fa-cloud-upload-alt"></i>
            <p>Browse File to Upload</p>
          </form>
          <section className="progress-area"></section>
          <section className="uploaded-area"></section>
        </div>
        <div className="row">
        <div className="col-6">
        <ButtonPrimary required={true} className={"width-100"} label="Upload" action={()=>{sendData("sendImage")}}/>
        </div>
        <div className="col-6">
        <ButtonPrimary required={false} className={"width-100"} label="Remove" action={()=>{sendDelete("senDelete")}}/>
        </div>
        </div>
       
      </Modal.Body>
    </Modal>
  );
}

DwGenericModal.defaultProps = {show: false, onHide: null}
export {DwGenericModal}