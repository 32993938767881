import * as React from 'react';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import Checkbox from '@mui/material/Checkbox';

const darkTheme = createTheme({palette:{mode:'dark'}});

function CollapsibleTable(props) {
    function updateChecked(rows, isChecked){
        for (let index = 0; index < rows.length; index++) {
            rows[index].checked = isChecked;
        }
    }
    function updateIndeterminate(rows){
        let values = [];
        for (let index = 0; index < rows.length; index++) {
            if(rows[index].indeterminate === true){
                return [true, null];
            }
            if(!values.includes(rows[index].checked)){
                values.push(rows[index].checked)
            }
        }
        return [(values.length > 1) ? true : false, (values.length === 1) ? values[0] : null];
    }
    function RowEpisodes(elements){
        const { row } = elements;
        const [ open, setOpen ] = React.useState(false);
        const [ getchecked, setchecked ] = React.useState(false);
        const [ getIndeterminate, setIndeterminate] = React.useState(false);
    
        React.useEffect(()=>{
            row.checked = getchecked;
            if(row.sequences === undefined){
                return;
            }
            updateChecked(row.sequences, getchecked)
            for (let index = 0; index < row.sequences.length; index++) {
                updateChecked(row.sequences[index].shots, getchecked)
            }
        // eslint-disable-next-line
        },[getchecked])

        const _updateIndeterminate = () =>{
            let values = updateIndeterminate(row.sequences)
            row.indeterminate = values[0];
            setIndeterminate( values[0] );
            if( values[1] !== null ){
                setchecked(values[1]);
            }            
        }
    
        return (
        <React.Fragment>
            <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                <TableCell>
                    {
                    row.sequences !== undefined ? 
                        <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                        </IconButton>
                    : ""
                    }
                </TableCell>
                <TableCell size="small"/>
                <TableCell component="th" scope="row"> <Checkbox checked={getchecked} indeterminate={getIndeterminate} onChange={(e) => {setchecked(e.target.checked)}}/> {row.code}</TableCell>
                <TableCell component="th" scope="row">{row.dw_episode}</TableCell>
                <TableCell align="right">{row.sg_id}</TableCell>
            </TableRow>
            { row.sequences !== undefined ? tableSequences(row, open, getchecked, _updateIndeterminate) : <></> }
        </React.Fragment>
        );
    }
    function tableSequences(row, open, isChecked, _updateIndeterminate){
        return(<TableRow>
            <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                <Collapse in={open} timeout="auto" >
                    <Box className='blackBox' sx={{ margin: 1 }}>
                        <Typography variant="h6" gutterBottom component="div">{"Sequences"}</Typography>
                        <Table size="small" aria-label="purchases">
                            <TableHead>
                                <TableRow>
                                <TableCell/>
                                <TableCell/>
                                <TableCell>Code</TableCell>
                                <TableCell>DW Scene</TableCell>
                                <TableCell align="right">Sg id</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {row.sequences.map((row) => (<RowSequences key={row.sg_id} row={row} isChecked={isChecked} ___updateIndeterminate={_updateIndeterminate}/>))}
                            </TableBody>
                        </Table>
                    </Box>
                </Collapse>
            </TableCell>
        </TableRow>);
    }
    function RowSequences(elements){
        const { row, isChecked, ___updateIndeterminate } = elements;
        const [open, setOpen] = React.useState(false);
        const [ getchecked, setchecked ] = React.useState(false);
        const [ getIndeterminate, setIndeterminate] = React.useState(false);
    
        React.useEffect(()=>{
            setchecked(isChecked);
        // eslint-disable-next-line
        },[])

        React.useEffect(()=>{
            setchecked(isChecked);
        // eslint-disable-next-line
        },[isChecked])
        
        React.useEffect(()=>{
            row.checked = getchecked;
            if(row.shots === undefined){
                return;
            }
            updateChecked(row.shots, getchecked);
            ___updateIndeterminate();
        // eslint-disable-next-line
        },[getchecked])

        const _updateIndeterminate = () =>{
            let values = updateIndeterminate(row.shots)
            row.indeterminate = values[0];
            setIndeterminate( values[0] );
            if( values[1] !== null ){
                setchecked(values[1]);
            }
            ___updateIndeterminate();
        }
    
        return (
        <React.Fragment>
            <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                <TableCell>
                    {
                    row.shots !== undefined ? 
                        <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                        </IconButton>
                    : ""
                    }
                </TableCell>
                <TableCell size="small"/>
                <TableCell component="th" scope="row"> <Checkbox checked={getchecked} indeterminate={getIndeterminate} onChange={(e) => setchecked(e.target.checked)}/> {row.code} </TableCell>
                <TableCell component="th" scope="row">{row.dw_scene}</TableCell>
                <TableCell align="right">{row.sg_id}</TableCell>
            </TableRow>
            { row.shots !== undefined ? tableShot(row, open, getchecked, _updateIndeterminate) : <></> }
        </React.Fragment>
        );
    }
    function tableShot(row, open, isChecked, _updateIndeterminate){
        return(<TableRow>
            <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                <Collapse in={open} timeout="auto" >
                    <Box className='blackBox' sx={{ margin: 1 }}>
                        <Typography variant="h6" gutterBottom component="div">{"Shots"}</Typography>
                        <Table size="small" aria-label="purchases">
                            <TableHead>
                                <TableRow>
                                <TableCell/>
                                <TableCell/>
                                <TableCell>Code</TableCell>
                                <TableCell>DW Shot</TableCell>
                                <TableCell align="right">Sg id</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {row.shots.map((row) => (<RowShot key={row.sg_id} row={row} isChecked={isChecked} _updateIndeterminate={_updateIndeterminate} />))}
                            </TableBody>
                        </Table>
                    </Box>
                </Collapse>
            </TableCell>
        </TableRow>);
    }
    function RowShot(elements){
        const { row, isChecked, _updateIndeterminate } = elements;
        const [ getchecked, setchecked ] = React.useState(false);
    
        React.useEffect(()=>{
            setchecked(isChecked);
        // eslint-disable-next-line
        },[])
        React.useEffect(()=>{
            setchecked(isChecked);
        // eslint-disable-next-line
        },[isChecked])
        React.useEffect(()=>{
            row.checked = getchecked;
            _updateIndeterminate();
        // eslint-disable-next-line
        },[getchecked])
        
        return (
            <React.Fragment>
                <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                    <TableCell/>
                    <TableCell size="small"/>
                    <TableCell component="th" scope="row"> <Checkbox checked={getchecked} onChange={(e) => setchecked(e.target.checked)}/> {row.code}</TableCell>
                    <TableCell component="th" scope="row">{row.dw_shot}</TableCell>
                    <TableCell align="right">{row.sg_id}</TableCell>
                </TableRow>
            </React.Fragment>
        );
    }
    return(
        <>
        {
            (props.rows === undefined || props.rows.episodes.length <= 0) ? <></> :
            <ThemeProvider theme={darkTheme}>
                <TableContainer component={Paper}>
                <Typography variant="h6" gutterBottom component="div">Episodes</Typography>
                <Table aria-label="collapsible table">
                    <TableHead>
                        <TableRow>
                            <TableCell />
                            <TableCell />
                            <TableCell>Code</TableCell>
                            <TableCell>DW Episode</TableCell>
                            <TableCell align="right">sg id</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {props.rows.episodes.map((row)=>(<RowEpisodes key={row.sg_id} row={row}/>))}
                    </TableBody>
                </Table>
            </TableContainer>
            </ThemeProvider>
        }
        </>
    );
}

export default CollapsibleTable;