import React, { useState ,useEffect } from "react";
import axios from "axios";
import { ButtonSecondary, ButtonPrimary } from "./forms/Buttons";
import {TextInput, DropDown, TextArea} from "../components/forms/TextInputs";
import { DwCheckBox } from "./forms/CheckBoxes";
import {DwGenericModal} from "../components/DwGenericModal"

const DwProject = (props) =>{

  const [pid,setPid]= useState('');
  const [eid,setEid]= useState('');
  const [type,setType]= useState('shot');
  const [vx,setVX]= useState('');
  const [prelet,setPreLet]= useState('');
  const [postlet,setPostLet]= useState('');
  const [secondprelet,setSecondPreLet]= useState('');
  const [secondpostlet,setSecondPostLet]= useState('');
  const [divider,setDivider]= useState('_');
  const [number,setNumber]= useState('');
  const [intExt,setIntExt]= useState('INT');
  const [priority,setPriority]= useState(50);
  const [scene,setScene]= useState('');
  const [status,setStatus]= useState('active');
  const [statusM,setStatusM]= useState('');
  const [difficulty,setDifficulty]= useState('normal');
  const [location,setLocation]= useState('');
  const [day_night,setDay_night]= useState('day');
  const [script_note,setScript_note]= useState('');
  const [sow,setSow]= useState('');
  const [crew_notes,setCrew_notes]= useState('');
  const [vfx_type,setVfx_type]= useState(1);
  const [shoot_day,setShoot_day]= useState(1);
  // const [bid,setBid]= useState(1);
  const [shot,setShot]= useState('');
  const [nameIntExt, setIntExtName] =useState("EXT");
  const [shotCount,setshotCount]= useState(1);

  const [VXselector , setVXselector]= useState({value:"", label:"-"})
  const [EPreLetD , setEPreLetD]= useState({value:"", label:"-"})
  const [EPostLetD , setEPostLetD]= useState({value:"", label:"-"})
  const [SPreLetD , setSPreLetD]= useState({value:"", label:"-"})
  const [SPostLetD , setSPostLetD]= useState({value:"", label:"-"})
  // const [activeD , setActiveD]= useState( {value:"active", label:"ACTIVE"})
  // const [intExtD , setIntExtD]= useState({value:"INT", label:"INT"})
  // const [difficultyD , setDifficultyD]= useState({value:50, label:"NORMAL"})
  // const [day_nightD , setDay_nightD]= useState( {value:"day", label:"DAY"})
  const [vfxTypes, setVfxTypes]=useState([]);
  const [defaultVFX, setDefaultVFX]=useState({value:1, label:"CGI"});

  const [show, setShow] = useState(false);
  const [img, setImg]= useState(window.domainBase + "/img/placeholder.png");
  const [file, setFile]= useState();

  let numbers10=[
    {value:1, label:"1"},
    {value:2, label:"2"},
    {value:3, label:"3"},
    {value:4, label:"4"},
    {value:5, label:"5"},
    {value:6, label:"6"},
    {value:7, label:"7"},
    {value:8, label:"8"},
    {value:9, label:"9"},
    {value:10, label:"10"}
  ]
  let xvOptions=[
    {value:"", label:"-"},
    {value:"v", label:"V"},
    {value:"vx", label:"VX"},
    {value:"x", label:"X"},
  ]
  let intextOptions=[
    {value:"EXT", label:"INT"},
    {value:"EXT", label:"EXT"},
  ]
  let daynightOptions=[
    {value:"day", label:"DAY"},
    {value:"night", label:"NIGHT"},
    {value:"dawn", label:"DAWN"},
    {value:"dusk", label:"DUSK"},
    {value:"cont", label:"CONT'D"},
  ]
  let difficultyOptions=[
    {value:"easy", label:"EASY"},
    {value:"normal", label:"NORMAL"},
    {value:"hard", label:"HARD"},
  ]
  let priorityOptions=[
    {value:0, label:"EASY"},
    {value:50, label:"NORMAL"},
    {value:100, label:"HARD"},
  ]
  let statusOptions=[
    {value:"active", label:"ACTIVE"},
    {value:"inactive", label:"INACTIVE"},
  ]

  useEffect(() => {
    getVfxTypes();
    // eslint-disable-next-line
  },[]);

  const getLocStg=function(e){
    return localStorage.getItem(e)
  };
  const getVfxTypes = async()=>{
    try {
      await axios.get( window.domain+"vfxType?oid="+getLocStg("oid"),returnHeaders()).then(res => {
        setVfxTypes(res["data"]);
        setVfx_type({value:res["data"][0].vid, label:res["data"][0].type})
      });
    } catch (error) {
      
    }
  };
  const fillVFX = function(){
    var newArray = [];
    // newArray.push({value:"", label:"-"});
    if(vfxTypes.length > 0){
      for (let index = 0; index < vfxTypes.length; index++) {
        newArray.push({value:vfxTypes[index].vid, label:vfxTypes[index].type});
      }
      // vfxTypes.map(function(key, index ){ newArray.push( {value:key.vid, label:key.type})});
    }
    return newArray;
  };
  const returnHeaders = function(){
    var token = getCookie( 'token' );
    let headerProposal ={
    headers: {
        'X-BP-Token': token,
      }
    };
    return headerProposal;
  }
  const getCookie = function( name ) {
    var r = document.cookie.match( "\\b" + name + "=([^;]*)\\b" );
    return r ? r[ 1 ] : null;
  };
  const fillOptionsAlfabet = function(){
    var optionsArray =[]
    optionsArray.push({value:'', label:'-'})
    for(var i = 0; i <= 25; i++) {
      optionsArray.push({value:(i+10).toString(36).toUpperCase(), label:(i+10).toString(36).toUpperCase()})
    }    
    return optionsArray;
  }
  // const fillOptionsNumber = function(){
  //   var optionsArray =[]
  //   for(var i = 100; i <= 300; i++) {
  //     optionsArray.push({value:i, label:i.toString()})
  //   }
  //   return optionsArray;
  // }
  const printVariables = async (refresh = false) => {
    const json = 
      {
      "pid" : pid ,
      "eid" : eid ,
      "type" : type ,
      "v_x" : vx ,
      "prelet" : secondprelet,
      "postlet" : secondpostlet,
      "number" : number ,
      "int_ext" : intExt ,
      "priority" : priority ,
      "status" : status ,
      "status_modifier" : statusM ,
      "difficulty" : difficulty ,
      "location" : location ,
      "day_night" : day_night ,
      "script_note" : script_note ,
      "sow" : sow ,
      "crew_notes" : crew_notes ,
      "vfx_type" : vfx_type.value ,
      "shoot_day" : shoot_day ,
      "bid" : parseInt(localStorage.getItem("bid")) ,
      "shot_count":shotCount,
      "divider": divider,
      "scene_prelet": prelet,
      "scene_number": scene,
      "scene_postlet": postlet
      }
    
    try {
      await axios.post( window.domain+"productionItem",json,returnHeaders()).then(async(res) => {
        if(file){
          var token = getCookie( 'token' );
          const formData  = new FormData();
          let url =  window.domain+"image?entity=shot&id=" + res["data"]["piid"]
          formData.append('img', file, file.name);
      
          await fetch(url, {method: 'POST', body: formData, headers:{'X-BP-Token': token}}).then(response => response.json()).then(data => {
            props.props.setNewPiid(res["data"]["piid"])
            props.setnewshot(new Date());
            if(refresh){
              props.props.onHide();
            }
          });
        }
        else{
          props.props.setNewPiid(res["data"]["piid"])
          props.setnewshot(new Date());
          if(refresh){
            props.props.onHide();
          }          
        }        
        });
    }
    catch(e){
      window.alert("ERROR: " + e.response.data.error);
    }
    
  }
  const fillSelected =(array)=>{
    var optionsArray = []
    for (let index = 0; index < array.length; index++) {
      optionsArray.push({value:array[index].pid, label:array[index].name})
    }
    // array.map(function(key, index ){
    //   optionsArray.push({value:key.pid, label:key.name})
    // });
    return optionsArray;
  }
  const defaultValueSelected=(selected,name)=>{
    var selecction = {}
    switch(name){
      case "episode":
        if(eid ===''){
          setEid(selected.eid)
        }
        selecction = {value:selected.eid, label:selected.number}
        break;
      case "project":
        if(pid ===''){
          setPid(selected.pid)
        }
        selecction = {value:selected.pid, label:selected.name}
        break;
      default:
        // do nothing
        break;
    }    
    return selecction
  }
  const ProjectName =()=>{
    let pName = localStorage.getItem( 'projectName')
    if(prelet !== "" && prelet !== null){
      pName=props.props.project.short_name+"_"+props.props.episode.number+"_"+vx.toUpperCase()+prelet+scene+postlet+divider+secondprelet+number+secondpostlet+" "+nameIntExt+" "+location.toUpperCase()+" "+day_night.toUpperCase();
    }
    return pName;
  }
  // const defautlValueLetter =(letter)=>{
  //   var selecction = {}
  //   switch(letter){
  //     case "pre":
  //       for(var i = 0; i <= 25; i++) {
  //         if (props.props.productionitem.scene_prelet === (i+10).toString(36).toUpperCase()){
  //           selecction ={value:(i+10).toString(36).toUpperCase(), label:(i+10).toString(36).toUpperCase()}
  //           if(prelet ===''){
  //             setPreLet((i+10).toString(36).toUpperCase());
  //             setEPreLetD(selecction);
  //           }
  //         }
  //       }
  //       break;
  //     case "post":
  //       for(var i = 0; i <= 25; i++) {
  //         if (props.props.productionitem.scene_prelet === (i+10).toString(36).toUpperCase()){
  //           selecction ={value:(i+10).toString(36).toUpperCase(), label:(i+10).toString(36).toUpperCase()}
  //           if(postlet ===''){
  //             setPostLet((i+10).toString(36).toUpperCase());
  //             setEPostLetD(selecction);
  //           }
  //         }
  //       }
  //       break;
  //   }
  //   return selecction
  // }
  // const defautlValueLetterShot =(letter)=>{
  //   var selecction = {}
  //   switch(letter){
  //     case "pre":
  //       for(var i = 0; i <= 25; i++) {
  //         if (props.props.productionitem.prelet === (i+10).toString(36).toUpperCase()){
  //           selecction ={value:(i+10).toString(36).toUpperCase(), label:(i+10).toString(36).toUpperCase()};
  //           if(secondprelet ===''){
  //             setSecondPreLet((i+10).toString(36).toUpperCase())
  //             setSPreLetD(selecction);
  //           }
          
           
  //           // setSPreLetD(selecction);
  //         }
  //       }
  //       break;
  //     case "post":
  //       for(var i = 0; i <= 25; i++) {
  //         if (props.props.productionitem.postlet === (i+10).toString(36).toUpperCase()){
  //           selecction ={value:(i+10).toString(36).toUpperCase(), label:(i+10).toString(36).toUpperCase()};
  //           if(secondpostlet ===''){
  //             setSecondPostLet((i+10).toString(36).toUpperCase())
  //             setSPostLetD(selecction);
  //           }
            
  //           // 
  //         }
  //       }
  //       break;
  //   }
  //   return selecction
  // }
  const setNewBlankRecord =()=>{
    setVX("");
    setPreLet("");
    setPostLet("");
    setSecondPreLet("");
    setSecondPostLet("");
    setDivider("");
    setScript_note("");
    setSow("");
    setShot("");
    setCrew_notes("");
    setIntExt("");
    setScene("");
    setLocation("");
    // setNumber("");
    // setPriority("");
    // setStatus("");
    // setStatusM("");
    // setDifficulty("");
    // setDay_night("");
    // setVfx_type("");
    // setShoot_day("");
    // setBid("");
    // setIntExtName("");
    // setshotCount("");

    setVXselector({value:"", label:"-"});
    setEPostLetD({value:"", label:"-"})
    setEPreLetD({value:"", label:"-"})
    setSPreLetD({value:"", label:"-"})
    setSPostLetD({value:"", label:"-"})

    var elements = document.querySelectorAll("[id='newBlank']")
    for (let index = 0; index < elements.length; index++) {
      
      elements[index].value="";
    }
  }
  // const getVfxType = function(id){
  //   let array = fillVFX();
  //   if(array.length == 0){return}
  //   let selected = array.find( obj => obj.value === id );
  //   if(selected == null){return}
  //   return {value: selected.value, label:selected.label}
  // }
  const zfill=(e)=>{
    setScene(e.target.value.padStart(3, "0"));
  }
  return (
    <div className = "col-12 row">
      <DwGenericModal 
        show={show} 
        onHide={() => setShow(false)}
        // id={selectedTake.tkid}
        image={img}
        wait={"true"}
        setImg={setImg}
        setFile={setFile}
        entity={"shot"}
        />
      <div className = "col-11">
        <h2>VFX SHOT - {ProjectName()}</h2>
      </div>
      <div className = "col-1" onClick={props.props.onHide}>
        <h2 className="text-right">X</h2>
      </div>
      <div className = "col-12 row">
        <div className = "col-lg-3 col-sm-12 row">
          <div className = "col-lg-1" ></div>
          <div className = "col-lg-10" >
            <div className="mb-3 text-center"><span ><h6>Option 1: Fill in an new blank record</h6></span></div>
            <div className="mb-3"><ButtonSecondary action={() => {setNewBlankRecord()}} className="width-100 mb-3" label="NEW BLANK RECORD"/></div>
            {/* <div className="mb-3 text-center"><span><h6>Option 2: copy / split this record</h6></span></div>
            <div className="mb-3 text-center"><span>How many new records</span></div>
            <div className="mb-3"><DropDown required={false} placeholder="Single-Dropdown"/></div>
            <div className="mb-3 text-center"><span>Current shot number</span></div>
            <div className="mb-3"><DropDown required={false} placeholder="Single-Dropdown"/></div>
            <div className="mb-3 text-center"><span>Increment by</span></div>
            <div className="mb-5"><DropDown required={false} placeholder="Single-Dropdown"/></div>
            <div className="mb-4"><p>.</p></div> */}
            {/* <div className="mb-4"><ButtonSecondary className="width-100 mb-3" label="COPY RECORD ONLY"/></div>
            <div className="mb-4"><ButtonSecondary className="width-100 mb-3" label="COPY WITH TASK"/></div>
            <div className="mb-4"><ButtonSecondary className="width-100 mb-3" label="COPY ESTIMATES"/></div>
            <div className="mb-4"><ButtonSecondary className="width-100 mb-3" label="COPY TASK AND ESTIMATES"/></div> */}
          </div>          
          <div className = "col-lg-1" ></div>
        </div>
        <div className = "col-lg-9 col-sm-12">
          {/* First  Column */}
          <div className = "col-12 row dw-color-card p-3 mb-2">
            <div className="col-lg-4">
              <span>PROJECT</span>
              <DropDown required={false} placeholder="Single-Dropdown" options={fillSelected(props.props.allprojects)} defaultValue={defaultValueSelected(props.props.project,"project")} onChange={(e) => {setPid(e.value)}}/>
            </div>
            <div className="col-lg-2">
              {/* <span>ACCOUNT</span>
              <DropDown required={false} placeholder="Single-Dropdown" options={{value:1, label:"Shot"}} defaultValue={{value:1, label:"Shot"}} /> */}
            </div>
            <div className="col-lg-2">
              <span>EPISODE</span>
              <DropDown required={true} placeholder="Single-Dropdown" options={defaultValueSelected(props.props.episode,"episode")} defaultValue={defaultValueSelected(props.props.episode,"episode")} onChange={(e) => {setEid(e.value);  }}/>
            </div>
            <div className="col-lg-2"></div>
            <div className="col-lg-2">
              <span className="mb-1"> SHOT COUNT</span>
              <DropDown required={true} placeholder="Single-Dropdown" options={numbers10} defaultValue={{ value:1, label:"1" }} onChange={(e) => {setshotCount(e.value);  }}/>
            </div>
            <div className="col-lg-1">
              <span >V/X</span>
              <DropDown  value={VXselector} required={false} placeholder="" options={xvOptions} onChange={(e) => {setVX(e.value); setVXselector({value:e.value, label:e.label})}}/>
            </div>
            <div className="col-lg-1">
              <span>PRE LET</span>
              <DropDown  value={EPreLetD} required={false} placeholder="" options={fillOptionsAlfabet()} defaultValue={{value:"", label:"-"}} onChange={(e) => {setPreLet(e.value); setEPreLetD({value:e.value, label:e.label}) }}/>
            </div>
            <div className="col-lg-1">
              <span className="mb-1">SCENE</span>
              <TextInput id="newBlank" type="number" value={scene} onBlur={zfill} InputLabelProps={{shrink: false}} className="width-100" required={true} placeholder="" onChange={(e)=>{setScene(e.target.value)}}/>
            </div>
            <div className="col-lg-1">
              <span>POST LET</span>
              <DropDown required={false} placeholder="" options={fillOptionsAlfabet()} defaultValue={{value:"", label:"-"}} value={EPostLetD} onChange={(e) => {setPostLet(e.value); setEPostLetD({value:e.value, label:e.label})}}/>
            </div>
            <div className="col-lg-1">
              <span className="mb-1">DIVIDER</span>
              <TextInput id="newBlank" className="width-100" required={false} placeholder="" value={divider} onChange={(e)=>{setDivider(e.target.value)}} />
            </div>
            <div className="col-lg-1">
              <span>PRE LET</span>
              <DropDown required={false} placeholder="" options={fillOptionsAlfabet()} defaultValue={{value:"", label:"-"}} value={SPreLetD} onChange={(e) => {setSecondPreLet(e.value); setSPreLetD({value:e.value, label:e.label})}}/>
            </div>
            <div className="col-lg-1">
              <span className="mb-1">SHOT</span>
              <TextInput id="newBlank" className="width-100" required={true} defaultValue={"010"} placeholder="" onChange={(e) => {setNumber(e.target.value)}}/>
            </div>
            <div className="col-lg-1">
              <span>POST LET</span>
              <DropDown required={false} placeholder="" options={fillOptionsAlfabet()} defaultValue={{value:"", label:"-"}} value={SPostLetD} onChange={(e) => {setSecondPostLet(e.value); setSPostLetD({value:e.value, label:e.label})}}/>
            </div>
            <div className="col-lg-4"></div>            
            <div className="col-lg-2">
              <span>INT/EXT</span>
              <DropDown required={false} placeholder="" options={intextOptions} defaultValue={{value:"exterior", label:"EXT"}} onChange={(e) => {setIntExt(e.value); setIntExtName(e.label)}}/>
            </div>
            <div className="col-lg-4">
              <span className="mb-1">LOCATION</span>
              <TextInput id="newBlank" className="width-100" required={true} placeholder="" onChange={(e) =>{setLocation(e.target.value)}}/>
            </div>
            <div className="col-lg-2">
              <span>DAY/NIGHT</span>
              <DropDown required={false} placeholder="" defaultValue={{value:"day", label:"DAY"}} options={daynightOptions} onChange={(e) => {setDay_night(e.value)}}/>
            </div>            
          </div>
          {/* Second  Column */}
          <div className = "col-12 row dw-color-card p-3 mb-2">
            <div className="col-lg-2">
                <span>PRIORITY</span>
                <DropDown required={false} placeholder="" defaultValue={{value:50, label:"NORMAL"}} options={priorityOptions} onChange={(e) => {setPriority(e.value)}}/>
              </div>
              <div className="col-lg-2">
                <span>IMMEDIATE ACTION</span>
                <DropDown required={false} placeholder=""/>
              </div>
              <div className="col-lg-2">
                <span>STATUS GROUP</span>
                <DropDown required={false} placeholder=""/>
              </div>
              <div className="col-lg-2">
                <span>STATUS</span>
                <DropDown required={false} placeholder="" options={statusOptions} defaultValue={{value:"active", label:"ACTIVE"}} onChange={(e) => {setStatus(e.value)}}/>
              </div>
              <div className="col-lg-2">
                <span>MODIFIER</span>
                <DropDown required={false} placeholder=""/>
              </div>
              <div className="col-lg-2">
                <span>DIFFICULTY</span>
                <DropDown required={false} placeholder="" options={difficultyOptions} defaultValue={{value:"normal", label:"NORMAL"}} onChange={(e) => {setDifficulty(e.value)}}/>
              </div>              
          </div>
          {/* Third  Column */}
          <div className = "col-12 row mb-2">
            <div className = "col-lg-3 col-sm-12">
              <img src={img} onClick={()=>{setShow(true)}} className="width-100 mb-2 ratio" alt=""></img>
              <span>VFX TYPE</span>
              <DropDown required={false} options={fillVFX()} value={vfx_type} onChange={(e)=>setVfx_type(e)} placeholder=""/>
            </div>
            <div className = "col-lg-9 col-sm-12 dw-color-card p-3" >
              <div>
                <span>SCRIPT NOTE</span>
                <TextArea id="newBlank" className="width-100 dw-height-input" required={false} placeholder="Notes" onChange={(e) => {setScript_note(e.target.value)}}/>
              </div>              
              <div>
                <span>SCOPE OF WORK</span>
                <TextArea id="newBlank" className="width-100 dw-height-input" required={true} placeholder="Notes" onChange={(e) => {setSow(e.target.value)}}/>
              </div>
              <div>
                <span>CREW SHOOTING NOTES</span>
                <TextArea id="newBlank" className="width-100 dw-height-input" required={false} placeholder="Notes" onChange={(e) => {setCrew_notes(e.target.value)}}/>
              </div>
            </div>
          </div>
          {/* Fourth  Column */}
          <div className = "col-12 row dw-color-card p-3 mb-2">
            <div className="col-lg-8 row">
              <div className="col-lg-2">
                <DwCheckBox/>
                <span>Demo</span>
              </div>
              <div className="col-lg-2">
                <DwCheckBox/>
                <span>Camera</span>
              </div>
              <div className="col-lg-2">
                <DwCheckBox/>
                <span>100% Vendor</span>
              </div>
              <div className="col-lg-2">
                <DwCheckBox/>
                <span>Phantom Prep</span>
              </div>
            </div>
            <div className="col-lg-4 row" style={{textAlign:"right"}}>
              <div className="col-lg-3 ">
                <span>TRACKER</span>
              </div>
              <div className="col-lg-9  ">
                <TextInput className="width-100" required={false} placeholder="Tracker"/>
              </div>
              <div className="col-lg-3">
                <span>TRACKER</span>
              </div>
              <div className="col-lg-9  ">
                <TextInput className="width-100" required={false} placeholder="Tracker"/>
              </div>
              <div className="col-lg-3">
                <span>TRACKER</span>
              </div>
              <div className="col-lg-9  ">
                <TextInput className="width-100" required={false} placeholder="Tracker"/>
              </div>              
            </div>            
          </div>
          <div className = "row">
            <div  className = "col-lg-4 ">
            </div>
            <div  className = "col-lg-4 ">
              <ButtonPrimary className="width-100" required={false} label="SAVE AND REFRESH" action={() => {printVariables()}}/>
            </div>
            <div  className = "col-lg-4 ">
              <ButtonPrimary className="width-100" required={true} label="SAVE AND CLOSE" action={() => {printVariables(true)}}/>
            </div>
          </div>          
        </div>
      </div>
    </div>
  );    
}
export default DwProject;