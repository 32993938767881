import React,{useRef} from "react";
import Select from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClock } from '@fortawesome/free-solid-svg-icons'



const TextInput = ({placeholder, autoComplete,  required, type, onKeyDown, onChange, value, className, readOnly,defaultValue, isDisabled, id, onBlur}) => {
  return (
    <input autoComplete={autoComplete} id={id} disabled={isDisabled} onBlur={onBlur} readOnly={readOnly} defaultValue={defaultValue} type={type} className={className+" dw-input"} placeholder={placeholder} required={required} onKeyDown={onKeyDown} onChange={onChange} value={value}></input>
  )
}
const TextArea = ({placeholder, required, type, onKeyDown, onChange, value, className, id, defaultValue, isDisabled}) => {
  return (
    <textarea disabled={isDisabled} id={id} value={value} defaultValue={defaultValue} type={type} className={className+" dw-input"} placeholder={placeholder} required={required} onKeyDown={onKeyDown} onChange={onChange}></textarea>
  )
}
const TimeInput = ({placeholder, required, type, onKeyDown, onChange, value, className, divClass, onClick, isDisabled,timerClick}) => {
  return (
    <div className={"position-relative dw-time-container "+divClass}>
      <input
        disabled ={isDisabled}
        type={type}
        style={{width:"100%"}}
        className={className+" dw-input dw-time-input"}
        placeholder={placeholder}
        required={required}
        onKeyDown={onKeyDown}
        onClick={onClick}
        onChange={onChange}
        value={value} readOnly
      ></input>
      
      <FontAwesomeIcon className="position-absolute fa-input-icon" icon={faClock} onClick={timerClick} />
    </div>
  )
}
const DropDown = ({id,options, required, placeholder,onChange,value,defaultValue, isDisabled , className}) => {
  return (
      <Select isDisabled={isDisabled} id={id} value={value} defaultValue={defaultValue} classNamePrefix="dw-options" placeholder={placeholder} className={`${required ? "dw-dropdown-required" : "" } ` + className} options={options} onChange={onChange} />
  )
}
const MultiDropDown = ({options, required, placeholder, onChange, defaultValue, isDisabled}) => {
  return (
    <Select isDisabled={isDisabled} classNamePrefix="dw-options" onChange={onChange} defaultValue={defaultValue} isMulti={true} placeholder={placeholder} className={`${required ? "dw-dropdown-required" : "" }`} options={options}  />
  )
}
const DateInput =({required, className, startDate, setStartDate, isDisabled, placeholder}) => {
  const datePickerRef = useRef(null);

  const handleOnKeyDown = event => {
    if (datePickerRef?.current && !datePickerRef.current.state.open) {
      if (event?.keyCode === 13 || event?.which === 13) {
        event.preventDefault();
        datePickerRef.current?.setOpen(true);
      }
    }
  };

  return (
    <DatePicker
      disabled = {isDisabled}
      className={className+` dw-date-input${required ? " dw-date-input-required" : "" }`}
      dateFormat="yyyy.MM.dd"
      selected={startDate}
      onChange={(date) => setStartDate(date)}
      placeholderText = {placeholder}
      enableTabLoop={false}
      onKeyDown={handleOnKeyDown}
      ref={datePickerRef}
      preventOpenOnFocus={true}
    />
  );
}
TextArea.defaultProps = {
  type :"text",
  placeholder : "...",
  required: false,
  className:""
}
TextInput.defaultProps = {
  type :"text",
  placeholder : "...",
  required: false,
  className:""
}
TimeInput.defaultProps = {
  type :"text",
  placeholder : "00:00:00",
  required: false,
  className:"",
  divClass:""
}
DropDown.defaultProps = MultiDropDown.defaultProps = {
  required: false,
  placeholder: "Select...",
  options : [
    
  ]
}  
export {TextInput, DropDown, MultiDropDown, DateInput, TextArea, TimeInput}




