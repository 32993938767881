import {useState, useEffect} from 'react';
import axios from "axios";
import PropTypes from 'prop-types';
import DwNav from "../components/DwNav";
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import {visuallyHidden} from '@mui/utils';
import {ButtonSecondary} from "../components/forms/Buttons";
import {DwModalPrint} from '../components/DwModalPrint';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import {DropDown, TextInput, DateInput} from "../components/forms/TextInputs";
import { ThemeProvider, createTheme } from '@mui/material/styles';

const DailyProductionReports =(props)=>{
  const empty = {value:"", label:"-"};
  const [projects, setProjects] = useState('');
  const [selectedProject, setselectedProject] = useState('');
  const [episodes, setEpisodes] = useState('');
  const [selectedEpisode, setselectedEpisode] = useState('');
  const [vx, setVX] = useState('');
  const [selectedVX, setSelectedVX] = useState('');
  const [selectedPreLet, setSelectedPreLet] = useState('');
  const [shots, setShots] = useState('');  
  const [selectedShot, setSelectedShot] = useState('');
  const [selectedPosLet, setSelectedPosLet] = useState('');
  const [setups, setSetups] = useState('');
  const [selectedSetup, setSelectedSetup] = useState('');
  const [selectedCamera, setCamera] = useState('');
  const [selectedTake, setTake] = useState('');
  const [selectedLocation, setLocation] = useState('');
  const [datesent, setDateSent] = useState("");
  const [selectedShootDay, setShootDay] = useState('');
  const [budgedImpact, setBudgetImpact] = useState('');
  const [selectedBudgedImpact, setSelectedBudgedImpact] = useState('');
  const [crew, setCrew] = useState([]);
  const [crewSelected, setCrewSelected] = useState([]);
  const [modalPrint, setmodalPrint] = useState(false);
  const [json, setJson] = useState({});
  const [rows, setRows] = useState([]);
  const [important, setImportant] = useState(false);

  useEffect(()=>{
    setVX([empty, {value:"V", label:"V"}, {value:"VX", label:"VX"}, {value:"X", label:"X"}]);
    setRows([createData("-","-","-","-","-","-","-","-","-","-","-","-","-","-","-","-","-","-","-","-","-","-")]);
    getprojects();
    getBudgetImpact();
    getCrew();
    // eslint-disable-next-line
  },[])
  useEffect(()=>{
    if(isdiferent(selectedProject.value)){
      getEpisodes();
    }
    else{
      setEpisodes([empty]);
    }
    setselectedEpisode("");
    // eslint-disable-next-line
  },[selectedProject])

  useEffect(()=>{
    if(isdiferent(selectedEpisode.value)){
      getShots();
      setImportant(true);
    }
    else{
      setImportant(false);
      setShots([empty]);
    }
    setSelectedShot("");
    // eslint-disable-next-line
  },[selectedEpisode])
  
  useEffect(()=>{
    if(isdiferent(selectedShot.value)){
      getSetups();
    }
    else{
      setSetups([empty]);
    }
    setSelectedSetup("");
    // eslint-disable-next-line
  },[selectedShot])

  const isdiferent =function(item){
    var exceptions = ["","-",null,undefined];
    if(exceptions.includes(item)){
      return false;
    }
    return true;
  }
  const darkTheme = createTheme({
    palette: {
      mode: 'dark',
    },
  })
  function createData( tkid, camera, number, focal_length, distance, sig_object, tilt, height, dolly_dist, filter_on_lens, filters_to_add, notes, project, episode, v_x, scene_prelet, scene_number, scene_postlet, location, setup, shoot_day, shoot_date, type ) {
    return { tkid, camera, number, focal_length, distance, sig_object, tilt, height, dolly_dist, filter_on_lens, filters_to_add, notes, project, episode, v_x, scene_prelet, scene_number, scene_postlet, location, setup, shoot_day, shoot_date, type };
  }
  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }
  function getComparator(order, orderBy) {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }
  const headCells=[
    {id:"setup",            numeric:false, disablePadding:false, label:"SETUP", colSpan:3},
    {id:"camera",           numeric:false, disablePadding:false, label:"CAM", colSpan:1, className:"bp-center"},
    {id:"number",           numeric:true, disablePadding:false, label:"TAKE", colSpan:1},
    {id:"focal_length",     numeric:false, disablePadding:false, label:"FOCAL LENGTH", colSpan:2},
    {id:"distance",         numeric:false, disablePadding:false, label:"DISTANCE", colSpan:2},
    {id:"sig_object",       numeric:false, disablePadding:false, label:"SIG OBJECT", colSpan:2},
    {id:"tilt",             numeric:false, disablePadding:false, label:"TILT", colSpan:2},
    {id:"height",           numeric:false, disablePadding:false, label:"HEIGHT", colSpan:2},
    {id:"dolly_dist",       numeric:false, disablePadding:false, label:"DOLLY DIST", colSpan:2},
    {id:"filter_on_lens",   numeric:false, disablePadding:false, label:"FILTER ON LENS", colSpan:2},
    {id:"filters_to_add",   numeric:false, disablePadding:false, label:"FILTERS TO ADD", colSpan:2},
    {id:"notes",            numeric:false, disablePadding:false, label:"NOTES", colSpan:10},
    // {id:"project",          numeric:false, disablePadding:false, label:"PROJECT", colSpan:2},
    // {id:"episode",          numeric:true, disablePadding:false, label:"EPISODE", colSpan:2},
    // {id:"v_x",              numeric:false, disablePadding:false, label:"V/X", colSpan:2},
    // {id:"scene_prelet",     numeric:false, disablePadding:false, label:"PRELET", colSpan:2},
    // {id:"scene_number",     numeric:false, disablePadding:false, label:"SCENE", colSpan:2},
    // {id:"scene_postlet",    numeric:false, disablePadding:false, label:"POSTLET", colSpan:2},
    {id:"location",         numeric:false, disablePadding:false, label:"LOCATION", colSpan:2},    
    {id:"shoot_day",        numeric:false, disablePadding:false, label:"SHOOT DAY", colSpan:1},
    {id:"shoot_date",       numeric:false, disablePadding:false, label:"SHOOT DATE", colSpan:2},
    {id:"type",             numeric:false, disablePadding:false, label:"BUDGET IMPACT", colSpan:2}
  ]
  function EnhancedTableHead(props) {
    const { order, orderBy, onRequestSort } =
      props;
    const createSortHandler = (property) => (event) => {
      onRequestSort(event, property);
    };
  
    return (
      <TableHead>
        <TableRow>
          <TableCell colSpan={2} padding="checkbox">
          </TableCell>
          {headCells.map((headCell) => (
            <TableCell 
            className={headCell.className}
              colSpan={headCell.colSpan}
              key={headCell.id}
              align='left'
              padding={headCell.disablePadding ? 'none' : 'normal'}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      
    );
  }
  EnhancedTableHead.propTypes = {
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
  }
  const EnhancedTableToolbar = (props) => {
    const { numSelected } = props;  
    return (
      <Toolbar sx={{pl:{sm:2}, pr:{xs:1, sm:1}}}>
        {numSelected > 0 ? (
          <Typography sx={{flex: '1 1 100%'}} color="inherit" variant="subtitle1" component="div">
            {numSelected} selected
          </Typography>
        ) : (
          <Typography sx={{flex: '1 1 100%'}} variant="h6" id="tableTitle" component="div"/>
        )}
      </Toolbar>
    );
  }
  EnhancedTableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired,
  }
  function EnhancedTable() {
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState("");
    const [selected, setSelected] = useState([]);
    const [page, setPage] = useState(0);
    const [dense, setDense] = useState(true);
    const [rowsPerPage, setRowsPerPage] = useState(25);
  
    const handleRequestSort = (event, property) => {
      const isAsc = orderBy === property && order === 'asc';
      setOrder(isAsc ? 'desc' : 'asc');
      setOrderBy(property);
    };
    const handleClick = (event, name) => {
      const selectedIndex = selected.indexOf(name);
      let newSelected = [];  
      if (selectedIndex === -1) {
        newSelected = newSelected.concat(selected, name);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selected.slice(1));
      } else if (selectedIndex === selected.length - 1) {
        newSelected = newSelected.concat(selected.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          selected.slice(0, selectedIndex),
          selected.slice(selectedIndex + 1),
        );
      }  
      setSelected(newSelected);
    };
    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
    };
    const handleChangeDense = (event) => {
      setDense(event.target.checked);
    };
  
    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;  
    return (
      <ThemeProvider theme={darkTheme}>
      <Box sx={{ width: '100%' }}>
        <Paper sx={{ width: '100%', mb: 2 }}>
          <EnhancedTableToolbar numSelected={selected.length} />
          <TableContainer className='headerTable'>
            <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size={dense ? 'small' : 'medium'} >


            <TableHead>
                <TableRow>
                  <TableCell colSpan={2} padding="checkbox"/>
                  <TableCell colSpan={2}/>
                  <TableCell colSpan={2}/>
                  <TableCell colSpan={2}>{"Total: " + rows.length}</TableCell>
                </TableRow>
              </TableHead>

              <EnhancedTableHead numSelected={selected.length} order={order} orderBy={orderBy} onRequestSort={handleRequestSort} rowCount={rows.length} />
              <TableBody>
                {rows.slice().sort(getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {  
                    const labelId = `enhanced-table-checkbox-${index}`;  
                    return (
                      <TableRow hover onClick={(event) => handleClick(event, row.tkid)} role="checkbox" tabIndex={-1} key={row.tkid} >
                        <TableCell colSpan={2} padding="checkbox"/>
                        <TableCell colSpan={3} >{row.setup}</TableCell>
                        <TableCell className='bp-center' colSpan={1} component="th" id={labelId} scope="row" padding="none"> {row.camera} </TableCell>
                        <TableCell colSpan={1} >{row.number}</TableCell>
                        <TableCell colSpan={2} >{row.focal_length}</TableCell>
                        <TableCell colSpan={2} >{row.distance}</TableCell>
                        <TableCell colSpan={2} >{row.sig_object}</TableCell>
                        <TableCell colSpan={2} >{row.tilt}</TableCell>
                        <TableCell colSpan={2} >{row.height}</TableCell>
                        <TableCell colSpan={2} >{row.dolly_dist}</TableCell>
                        <TableCell colSpan={2} >{row.filter_on_lens}</TableCell>
                        <TableCell colSpan={2} >{row.filters_to_add}</TableCell>
                        <TableCell colSpan={10} >{row.notes}</TableCell>
                        {/* <TableCell>{row.project}</TableCell>
                        <TableCell>{row.episode}</TableCell>
                        <TableCell>{row.v_x}</TableCell>
                        <TableCell>{row.scene_prelet}</TableCell>
                        <TableCell>{row.scene_number}</TableCell>
                        <TableCell>{row.scene_postlet}</TableCell> */}
                        <TableCell colSpan={2} >{row.location}</TableCell>                        
                        <TableCell colSpan={1} >{row.shoot_day}</TableCell>
                        <TableCell colSpan={2} >{row.shoot_date}</TableCell>
                        <TableCell colSpan={2} >{row.type}</TableCell>
                        {/* <TableCell><ButtonSecondary className="width-100" label={"..."} action={()=>{ openSetUp(row.sid) }}/></TableCell> */}
                      </TableRow>
                    );
                  })}
                {emptyRows > 0 && (
                  <TableRow style={{height: (dense ? 33 : 53) * emptyRows}}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination rowsPerPageOptions={[ 25, 100]} component="div" count={rows.length} rowsPerPage={rowsPerPage} page={page} onPageChange={handleChangePage} onRowsPerPageChange={handleChangeRowsPerPage} />
        </Paper>
        &nbsp; &nbsp; &nbsp;
        <FormControlLabel control={<Switch checked={dense} onChange={handleChangeDense} />} label="Dense padding" />
      </Box>
      </ThemeProvider>
    );
  }
  const getLocStg=function(e){
    return localStorage.getItem(e)
  }
  const returnHeaders = function(){
    var token = getCookie( 'token' );
    let headerProposal ={headers: {'X-BP-Token': token}};
    return headerProposal;
  }
  const getCookie = function(name) {
    var r = document.cookie.match( "\\b" + name + "=([^;]*)\\b" );
    return r ? r[ 1 ] : null;
  }
  const getprojects = async function(){
    await axios.get( window.domain+"project?oid="+getLocStg("oid"),returnHeaders()).then(response=>{
      var newArray = [empty];
      response["data"].forEach(element => {
        newArray.push({value:element.pid, label:element.name});
      });
      setProjects(newArray);
    });
  }
  const getEpisodes = async function(){
    await axios.get( window.domain+"episode?pid="+selectedProject.value, returnHeaders()).then(response=>{
      var newArray = [empty];
      response["data"].forEach(element => {
        newArray.push({value:element.eid, label:element.number});
      });
      setEpisodes(newArray);
    });
  }
  const getShots = async function(){
    await axios.get( window.domain+"productionItem?eid=" + selectedEpisode.value,returnHeaders()).then(response => {
      var newArray = [empty];
      response["data"].forEach(element => {
        newArray.push({value:element.scene_number, label:element.scene_number, piid:element.piid});
      });
      setShots(newArray);
    });
  }
  const getSetups = async function(){
    await axios.get( window.domain+"setup?piid="+selectedShot.piid,returnHeaders()).then(response => {
      var newArray = [empty];
      response["data"].forEach(element => {
        newArray.push({value:element.sid, label: selectedShot.label + (( element.suffix !== "" ) ? "_" + element.suffix : "")});
      });
      setSetups(newArray)
    });
  }
  const getBudgetImpact = async function(){
    await axios.get( window.domain+"budgetImpact?oid="+getLocStg("oid"),returnHeaders()).then(response => {
      var newArray = [empty];
      response["data"].forEach(element => {
        newArray.push({value:element.bid, label: element.type});
      });
      setBudgetImpact(newArray)
    });
  }
  const getCrew = async function(){
    await axios.get( window.domain+"user?oid="+getLocStg("oid"),returnHeaders()).then(response => {
      var newArray = [empty];
      response["data"].forEach(element => {
        newArray.push({value:element.uid, label: element.name + " " + element.last_name});
      });
      setCrew(newArray)
    });
  }
  const createjson = function(){
    var SaveFormat = new Intl.DateTimeFormat('az',{year:'numeric', month:'numeric', day:'numeric' });
    let newjson={"pid": selectedProject.value}
    let date = null;

    if(isdiferent(datesent))                    { date = SaveFormat.format(datesent) }
    if(isdiferent(selectedProject.value ))     {newjson.pid = selectedProject.value}
    if(isdiferent(selectedEpisode.value ))     {newjson.eid = selectedEpisode.value}
    if(isdiferent(selectedVX.value ))          {newjson.v_x = selectedVX.value}
    if(isdiferent(selectedPreLet.value ))      {newjson.scene_prelet = selectedPreLet.value}
    if(isdiferent(selectedShot.value ))        {newjson.scene_number = selectedShot.value}
    if(isdiferent(selectedPosLet.value ))      {newjson.scene_postlet = selectedPosLet.value}
    if(isdiferent(selectedSetup.value ))       {newjson.setup = selectedSetup.value}
    if(isdiferent(selectedCamera ))      {newjson.camera = selectedCamera}
    if(isdiferent(selectedTake ))        {newjson.number = selectedTake}
    if(isdiferent(selectedLocation ))    {newjson.location = selectedLocation}
    if(isdiferent(date))                       {newjson.shoot_date = date}
    if(isdiferent(selectedShootDay.value ))    {newjson.shoot_day = selectedShootDay.value}
    if(isdiferent(selectedBudgedImpact.value )){newjson.bid = selectedBudgedImpact.value}
    if(isdiferent(crewSelected.value)){newjson.uid = crewSelected.value}

    return newjson;
  }
  const search = async() =>{
    if(isdiferent(selectedProject.value) === false){
      Swal.fire({
        title: 'Please select a project'
      }).then((result) => {})
    }
    else{
      try {
        await axios.post( window.domain+"cameraReportPreview", createjson(), returnHeaders()).then(res => {
          if(res.data.length === 0){
            setRows([createData("-","-","-","-","-","-","-","-","-","-","-","-","-","-","-","-","-","-","-","-","-","-")]);
          }
          else{
            let rowsaux = [];
            setRows(rowsaux);
            res.data.forEach(element => {
              rowsaux.push(createData(
                element.tkid,
                element.camera,
                element.number,
                element.focal_length,
                element.distance,
                element.sig_object,
                element.tilt,
                element.height,
                element.dolly_dist,
                element.filter_on_lens,
                element.filters_to_add,
                element.notes,
                element.project,
                element.episode,
                element.v_x,
                element.scene_prelet,
                element.scene_number,
                element.scene_postlet,
                element.location,
                element.setup,
                element.shoot_day,
                element.shoot_date,
                element.type
                ));
            });
            setRows(rowsaux);
          }          
          });
      }
      catch(error){
        setRows([createData("-","-","-","-","-","-","-","-","-","-","-","-","-","-","-","-","-","-","-","-","-","-")]);
      }
    }
  }
  const print = function(){
    setJson(createjson());
    setmodalPrint(true);
  }
  const fillAlfabet = function(){
    var optionsArray =[empty]
    for(var i = 0; i <= 25; i++) {
      optionsArray.push({value:i, label:(i+10).toString(36).toUpperCase()})      
    }
    return optionsArray;
  }
  const fillOptionsSootDay = function(){
    let optionsArray = [empty]
    for(var i = 1; i <= 100; i++) {
      optionsArray.push({value:i, label:"Day " + i})
    }
    return optionsArray;
  }
  return(
    <>
      <DwNav/>
      <header>
        <h1>Camera Reports</h1>
        <h6>Search for takes or cameras to add to the report.</h6>
      </header>
      <DwModalPrint title={"Create Camera Report"} btnLabel={"OPEN CAMERA REPORT"} url={"cameraReport"} setmodalPrint={setmodalPrint} onHide={()=>setmodalPrint(false)} modalPrint={modalPrint} data={json} />
      <div className='row'>
        <div className="col-xl-10 mb-4">
          <div className='row'>
            <div className="col-sm-1">
              <DropDown required={true} options={projects} value={selectedProject} onChange={(e)=>setselectedProject(e)} placeholder="PROJECT"/>
            </div>
            <div className="col-sm-1">
              <DropDown required={true} options={episodes} value={selectedEpisode} onChange={(e)=>setselectedEpisode(e)} placeholder="EPISODE"/>
            </div>           
            <div className="col-sm-1">
              <DropDown required={false} options={vx} value={selectedVX} onChange={(e) => setSelectedVX(e)} placeholder="V/X"/>
            </div>
            <div className="col-sm-1">
              <DropDown required={false} options={fillAlfabet()} value={selectedPreLet} onChange={(e) => setSelectedPreLet(e)} placeholder="PRELET"/>
            </div>
            <div className="col-sm-1">
              <DropDown required={important} options={shots} value={selectedShot} onChange={(e) => setSelectedShot(e)} placeholder="SCENE"/>
            </div>
            <div className="col-sm-1">
              <DropDown required={false} options={fillAlfabet()} value={selectedPosLet} onChange={(e) => setSelectedPosLet(e)} placeholder="POSTLET"/>
            </div>
            <div className="col-sm-1">
              <DropDown required={important} options={setups} value={selectedSetup} onChange={(e)=>setSelectedSetup(e)} placeholder="SETUP"/>
            </div>
            <div className="col-sm-1">
              <TextInput required={false} value={selectedCamera} onChange={(e) => setCamera(e.target.value.toUpperCase())} placeholder="CAMERA" className="width-100 autocomplete"/>
            </div>
            <div className="col-sm-1">
              <TextInput required={false} value={selectedTake} onChange={(e) => setTake(e.target.value.toUpperCase())} placeholder="TAKE" type="number" className="width-100 autocomplete"/>
            </div>
            <div className="col-sm-1">
              <TextInput required={false} value={selectedLocation} onChange={(e) => setLocation(e.target.value.toUpperCase())} placeholder="Location" className="width-100 autocomplete"/>
            </div>
            <div className="col-sm-1">
              <DateInput required={important} setStartDate={setDateSent} startDate={datesent}  placeholder="SHOOT DATE" className="width-100 autocomplete"/>
            </div>
            <div className="col-sm-1">
              <DropDown required={false} options={fillOptionsSootDay()} value={selectedShootDay} onChange={(e) => setShootDay(e)} placeholder="SHOOT DAY"/>
            </div>
            <div className="col-sm-1">
              <DropDown required={false} options={budgedImpact} value={selectedBudgedImpact} onChange={(e) => setSelectedBudgedImpact(e)} placeholder="BUDGET IMPACT"/>
            </div>
            <div className="col-sm-1">
              <DropDown required={false} options={crew} value={crewSelected} onChange={(e) => setCrewSelected(e)} placeholder="CREW"/>
            </div>
          </div>
        </div>
        <div className="col-xl-2 mb-4">
          <div className='row'>
            <div className="col-sm-6">
              < ButtonSecondary className="width-100" label={"Search"} required={true} action={()=>{search()}}/>
            </div>
            <div className="col-sm-6">
              < ButtonSecondary className="width-100" label={"Create CR"} required={true} action={()=>{print()}}/>
            </div>
          </div>
        </div>
      </div>
      <br/>
      <EnhancedTable/>
    </>
  )
}
export default DailyProductionReports