import React from 'react';
import ReactDOM from 'react-dom/client';
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from './views/Home';
import Setups from './views/Setups';
import Shots from './views/Shots';
import Takes from './views/Takes';
import Login from './views/Login';
import Projects from './views/Projects';
import Organization from './views/Organization';
import AllView from './views/ViewTest';
import Test from './views/Test';
import OrgSettings from './views/OrgSettings';
import DailyProductionReport from './views/DailyProductionReport';
import CreateOrg from './views/CreateOrg'
import CreateProject from './views/CreateProject';
import CreateEpisode from './views/CreateEpisode';
import CreateUser from './views/CreateUser';
import CameraData from './views/CameraData';
import Shotgrid from "./views/shotgrid";
import UpdateColors from "./views/updateColors"
import Activation from './views/Activation';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import Defaults from './views/Defaults';
import Lens from './views/lens';
import Cameras from './views/CreateCameras';
import ShootingCameras from './views/CreateShootingCamera';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(  
    <BrowserRouter>
        <Routes>
          <Route exact path="/" element={<Home/>}/>
          <Route exact path="/login" element={<Login/>}/>
          <Route exact path="/organizations" element={<Organization/>}/>
          <Route exact path="/orgSettings" element={<OrgSettings/>}/>
          {/* <Route exact path="/projects" element={<Projects/>}/> */}
          <Route exact path="/setups" element={<Setups/>}/>
          <Route exact path="/shots" element={<Shots/>}/>
          <Route exact path="/takes" element={<Takes/>}/>
          <Route exact path="/test" element={<Test/>}/>
          <Route exact path="/view" element={<AllView/>}/>
          <Route exact path="/reports/daily" element={<DailyProductionReport/>}/>
          <Route exact path='/create-org' element={<CreateOrg/>}/>
          <Route exact path='/projects' element={<CreateProject/>}/>
          <Route exact path='/create-episode' element={<CreateEpisode/>}/>
          <Route exact path='/register' element={<CreateUser/>}/>
          <Route exact path='/reports/camera' element={<CameraData/>}/>
          <Route exact path='/shotgrid' element={<Shotgrid/>}/>
          <Route exact path='/update-colors' element={<UpdateColors/>}/>
          <Route exact path='/activation' element={<Activation/>}/>
          <Route exact path='/defaults' element={<Defaults/>}/>
          <Route exact path='/lens' element={<Lens/>}/>
          <Route exact path='/create-cameras' element={<Cameras/>}/>
          <Route exact path='/create-shooting-cameras' element={<ShootingCameras/>}/>
          <Route path="*" element={ <div className= "login"> <div className = "divLogin"><h3 className ="ColorWhite">404 - Not found</h3> </div></div> } />
        </Routes>
      <App />
    </BrowserRouter>
  
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
