import React, { useState, useEffect } from "react";
import DwNav from "../components/DwNav";
import { DropDown} from "../components/forms/TextInputs";
import { ButtonPrimary, ButtonSecondary } from "../components/forms/Buttons";
import Swal from 'sweetalert2/dist/sweetalert2.js'
import { TextInput, TextArea } from "../components/forms/TextInputs";
import axios from "axios";

import SortableList from "../components/SortItemList";

const Cameras = () =>{
  const [name, setName] = useState("");
  const [serial, setSerial] = useState("");
  const [description, setDescription] = useState("");
  const [camBack, setCamBack] = useState("");
  const [colorDepth, setColorDepth] = useState("");
  const [oemURL, setOemURL] = useState("");
  const [list, setList] = useState([]);

  const url = window.domain + "productionCamera"

  useEffect(() => {
    getList();
    // eslint-disable-next-line
  },[]);

  const getCookie = function( name ){
    var r = document.cookie.match( "\\b" + name + "=([^;]*)\\b" );
    return r ? r[ 1 ] : null;
  }

  const returnHeaders = function(){
    var token = getCookie( 'token' );
    let headerProposal ={headers: {'X-BP-Token': token}};
    return headerProposal;    
  }

  const getList = async() =>{
    let Get_url = url + "?oid=" + localStorage.getItem("oid");
    await axios.get( Get_url, returnHeaders()).then(response => {
        if(response.status === 200){
            setList(response.data);
        }
      });
  }
  const createCamera = async() =>{
    if(name === "" || description === "" || serial === "" || camBack === "" ||  colorDepth === "" || oemURL === ""){
      Swal.fire({icon:"warning", text:"please fill in all the fields"});
    }
    else{
      let json = {
        "oid": localStorage.getItem("oid"),
        "name": name,
        "description": description,
        "serial": serial,
        "camera_back": camBack,
        "color_depth": colorDepth,
        "oem_url": oemURL
    }
    await axios.post( url, json,  returnHeaders()).then(response => {
        if(response.status === 200){
            getList();
            Swal.fire({icon:"success", text:"Updated data"});            
        }
        else{
          Swal.fire({icon:"error", text:"oops something went wrong"});
        }
      });
    }
    
  }
  const deleteItem = async(index) =>{
    const auxlist = [...list];
    let item = auxlist.splice(index, 1)[0];
    console.log(index, item, auxlist);
    let headers = returnHeaders();
    let data = {"oid":localStorage.getItem("oid"), "pcid":item.pcid};
    headers.data = data;
    await axios.delete( url, headers).then(response => {
      if(response.status === 200){
        getList();
        Swal.fire({icon:"success", text:"Updated data"});
      }
      else{
        Swal.fire({icon:"error", text:"oops something went wrong"});
      }
    });
  }
  const editItem = async(index) =>{   
    let newList = [...list];
    console.log(newList[index]);
    const { value: formValues } = await Swal.fire({
      title: 'Edit data',
      width: 600,
      html:    
            '<label style="display: flex; margin-left: inherit; margin: 10 40 0 40">Name:</label>'+
            '<input id="swal-input1" value="' + newList[index].name + '" class="swal2-input" style="width: -webkit-fill-available; display: flex; margin-top: 0px;">'+
            '<label style="display: flex; margin-left: inherit; margin: 10 40 0 40">Serial:</label>'+
            '<input id="swal-input2" value="' + newList[index].serial + '" class="swal2-input" style="width: -webkit-fill-available; display: flex; margin-top: 0px;">'+
            '<label style="display: flex; margin-left: inherit; margin: 10 40 0 40">Camera back:</label>'+
            '<input id="swal-input3" value="' + newList[index].camera_back + '" class="swal2-input" style="width: -webkit-fill-available; display: flex; margin-top: 0px;">'+
            '<label style="display: flex; margin-left: inherit; margin: 10 40 0 40">color depth:</label>'+
            '<input id="swal-input4" value="' + newList[index].color_depth + '" class="swal2-input" style="width: -webkit-fill-available; display: flex; margin-top: 0px;">'+
            '<label style="display: flex; margin-left: inherit; margin: 10 40 0 40">oem url:</label>'+
            '<input id="swal-input5" value="' + newList[index].oem_url + '" class="swal2-input" style="width: -webkit-fill-available; display: flex; margin-top: 0px;">'+
            '<label style="display: flex; margin-left: inherit; margin: 10 40 0 40">Description:</label>'+
            '<textarea id="swal-input6" class="swal2-textarea" style="width: -webkit-fill-available; display: flex; margin-top: 0px;"> ' + newList[index].description + ' </textarea>',
      focusConfirm: false,
      showCancelButton: true,
      preConfirm: () => {
        return ([
        document.getElementById('swal-input1').value, 
        document.getElementById('swal-input2').value, 
        document.getElementById('swal-input3').value,
        document.getElementById('swal-input4').value,
        document.getElementById('swal-input5').value,
        document.getElementById('swal-input6').value])
      }})
      if(formValues !== undefined){
        let headers = returnHeaders(); 
        let data = {
          pcid:newList[index].pcid, 
          name:formValues[0], 
          serial:formValues[1],          
          camera_back: formValues[2],
          color_depth: formValues[3],
          oem_url: formValues[4],
          description:formValues[5]
        };
        // headers.data = data;
        const edit_url = url +"?oid=" + localStorage.getItem("oid");
        await axios.put( edit_url, data, headers ).then(response => {
          getList();
        });
      }
  }
  return (
    <>
      <DwNav/> 
      <div className = "row d-flex justify-content-center align-items-center h-100">
        <div className="col-12 col-md-9 col-lg-7 col-xl-5">
          <div className="" style={{borderRadius: "1rem"}}>
            <div className="card-body p-5 " >
              <div className="row">
                <div className="col-md-12 mb-3">
                  <h1 className="text-center" style={ { color: "#ffffff", } }>Production cameras</h1>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <TextInput className={"width-100"} required={false} placeholder="Name" value={name} onChange={(e) => {setName(e.target.value)}} />
                </div>
                <div className="col-md-6">
                  <TextInput className={"width-100"} required={false} placeholder="Serial" value={serial} onChange={(e) => {setSerial(e.target.value)}} />
                </div>
                <div className="col-md-12">
                  <TextInput className={"width-100"} required={false} placeholder="oem url" value={oemURL} onChange={(e) => {setOemURL(e.target.value)}} />
                </div>
                <div className="col-md-6">
                  <TextInput className={"width-100"} required={false} placeholder="Camera back" value={camBack} onChange={(e) => {setCamBack(e.target.value)}} />
                </div>
                <div className="col-md-6">
                  <TextInput className={"width-100"} required={false} placeholder="Color depth" value={colorDepth} onChange={(e) => {setColorDepth(e.target.value)}} />
                </div>
                <div className="col-md-12">
                    <TextArea id="newBlank" className="width-100 dw-height-input" required={false} placeholder="Description" onChange={(e) => {setDescription(e.target.value)}}/>
                </div> 
                <div className="col-md-12">
                  <ButtonPrimary required={true} className="width-100 mb-3" label="Create camera" action={()=>{ createCamera() }}/>
                </div>               
              </div>
              <div className="row">
                <div className="col-md-12">
                  <SortableList items={list} updateList={setList} deleteItem={deleteItem} editItem={editItem} isDisable={true}/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default Cameras;


