
import { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal'
import Button from "react-bootstrap/Button";
import { ButtonPrimary } from "./forms/Buttons";
import DwNewShot from"../components/DwNewShot"
import {TextInput, DropDown, DateInput,TextArea, TimeInput} from "../components/forms/TextInputs";
import { ButtonSecondary} from "./forms/Buttons";
import Swal from 'sweetalert2/dist/sweetalert2.js'
import axios from "axios";
import {DwModalTimeSelect} from "../components/DWTimeSelect";

const DWModalSetUp = (props) => {

  const [note, setNote] = useState("");
  const [hdri, setHdri] = useState("");
  const [setupStart, setSetupS] = useState("");
  const [setupEnd, setSetupE] = useState("");
  const [shootStart, setShootS] = useState("");
  const [shootEnd, setShootE] = useState("");
  const [shootDay, setShootDay] = useState(1);
  const [bid, setBid]= useState(1);
  const [startDate, setStartDate] = useState(new Date());    
  const [startTime, setStarttime] = useState("00:00:00");
  const [shootTime, setShootTime] = useState("00:00:00");
  const [TotalTime, setTotalTime] = useState("00:00:00");
  const [selectedbi, setBidSelected] = useState({});
  const [selectedDay, setSelectedDay] = useState({});
  const [timerModal, setTimerModal] = useState(false);
  const [field, setfield] = useState('');

  const [selectedBudgedImpact, setbudgedImpact] = useState('');
  const [budgedImpact, setBudgetImpact] = useState('');

  const [setup, setSetup] = useState([]);

  useEffect(() => {
    getBudgedImpact();
    // getData();
  },[]);
  useEffect(() => {
    if(isdiferent(props.id)){
      getData();
    }    
  },[props.date]);

  const getBIValue = function(bID){
    if(budgedImpact.length == 0){return}
    let bidSelected = budgedImpact.find( obj => obj.bid === bID );
    if(bidSelected == null){return}
    return {value: bidSelected.bid, label:bidSelected.type}
  };

  const getData=async()=>{    
    try {
      await axios.get( window.domain+"setup?sid=" + props.id, returnHeaders()).then(res => {
        var SaveFormat = new Intl.DateTimeFormat('az',{year:'numeric', month:'numeric', day:'numeric', hour:'numeric', minute:'numeric', second:'numeric', hour12:false });
        
        setShootS(SaveFormat.format(new Date(res["data"].shoot_start)));
        setShootE(SaveFormat.format(new Date(res["data"].shoot_end)));
        setSetupS(SaveFormat.format(new Date(res["data"].setup_start)));
        setSetupE(SaveFormat.format(new Date(res["data"].setup_end)));
        setStartDate(new Date(res["data"].shoot_date));
        
        setSelectedDay( {value:res["data"].shoot_day, label:"Day "+ res["data"].shoot_day});

        setHdri(res["data"].hdri_ref_files);
        setNote(res["data"].notes);
        
        setSetup(res["data"]);
        document.getElementById("note-id").value = res["data"].notes;
        document.getElementById("hdri-id").value = res["data"].hdri_ref_files;

        setbudgedImpact(getBIValue(res["data"].budget_impact));

        if(!isdiferent(res["data"].shoot_start)){
          setShootS('');
        }
        if(!isdiferent(res["data"].shoot_end)){
          setShootE('');
        }
        if(!isdiferent(res["data"].setup_start)){
          setSetupS('');
        }
        if(!isdiferent(res["data"].setup_end)){
          setSetupE('');
        }
        if(!isdiferent(res["data"].shoot_date)){
          setStartDate('');
        }
      });
    }
    catch (error) {
        alert("Error");
        return;
    }
  };

  const isdiferent =function(item){
    if(item !== '' && item !=="-" && item !== null && item !== undefined){
      return true;
    }
    return false;
  };  
  const getLocStg=function(e){
    return localStorage.getItem(e)
  };
  const returnHeaders = function(){
    var token = getCookie( 'token' );
    let headerProposal ={headers: {'X-BP-Token': token}};
    return headerProposal;
  };
  const getCookie = function( name ) {
    var r = document.cookie.match( "\\b" + name + "=([^;]*)\\b" );
    return r ? r[ 1 ] : null;
  };
  const getBudgedImpact = async() =>{
    await axios.get( window.domain+"budgetImpact?oid="+getLocStg("oid"),returnHeaders()).then(res => {
      setBudgetImpact(res["data"])
    });
  }
  const fillBudgedImpact = function(){
    var newArray = []
    newArray.push({value:"", label:"-"})
    if(budgedImpact.length > 0){
      budgedImpact.map(function(key, index ){ newArray.push({value:key.bid, label:key.type}) });
    }
    return newArray;
  };
  const updateBudgedImpact  = function(e){
    setbudgedImpact({value:e.value,label:e.label});
  };
  const starClock = async (e) => {
    var SaveFormat = new Intl.DateTimeFormat('az',{year:'numeric', month:'numeric', day:'numeric', hour:'numeric', minute:'numeric', second:'numeric', hour12:false });
    var actual = new Date();
    switch(e){
      case "SetupStart":
        if( ChecktTime(actual,e) == true ){
          alertTime(e);
        }
        else{            
          await setSetupS(SaveFormat.format(actual));
        }
        break;
      case "SetupEnd":
        if( ChecktTime(actual,e) == true ){
          alertTime(e);
        }
        else{
          await setSetupE(SaveFormat.format(actual));
          await setShootS(SaveFormat.format(actual));
        }          
        break;
      case "ShootStart":
        if( ChecktTime(actual,e) == true ){
          alertTime(e);
        }
        else{
          await setShootS(SaveFormat.format(actual));
          var setupEndTime = new Date(setupEnd);
          if(actual < setupEndTime || setupEndTime.toString() === "Invalid Date"){
            await setSetupE(SaveFormat.format(actual));
          }
        }          
        break;
      case "ShootEnd":
        await setShootE(SaveFormat.format(actual));
        var setupEndTime = new Date(setupEnd);
        if(actual < setupEndTime || setupEndTime.toString() === "Invalid Date"){
          await setSetupE(SaveFormat.format(actual));
          await setShootS(SaveFormat.format(actual));
        }
        var shootStartTime = new Date(shootStart);
        if(actual < shootStartTime || shootStartTime.toString() === "Invalid Date"){
          await setShootS(SaveFormat.format(actual));
        }
        break;
    }
  };
  function displayValues(value){
    try {
      var DisplayFormat = new Intl.DateTimeFormat('en-US',{hour:'numeric', minute:'numeric', second:'numeric', hour12:false });
      var time = new Date(value);
      return DisplayFormat.format(time);
    } catch (error) {
      return value;
    }
  };
  function ChecktTime(newTime, caseName){
    let value = false;

    var setupstartTime = new Date(setupStart);
    var setupEndTime = new Date(setupEnd);
    var shootStartTime = new Date(shootStart);
    var shootEndTime = new Date(shootEnd);

    switch (caseName) {
      case "SetupStart":
        setupstartTime = newTime;
        if(newTime > setupEndTime || newTime > shootStartTime || newTime > shootEndTime){
          value = true;
          }
        break;
      case "SetupEnd":
        setupEndTime = newTime;
        break;
      case "ShootStart":
        shootStartTime = newTime
        break;
      case "ShootEnd":
        shootEndTime = newTime
          break;
      default:
        break;
    }
    
    var msSetup = setupEndTime.getTime()-setupstartTime.getTime();
    var diffSetup = get_whole_values( msSetup, [1000,60,60]);
    diffSetup.forEach(element => {
      if(parseInt( element ) <0){
        value = true;
      }
    });
    
    var msShoot = shootEndTime.getTime()-shootStartTime.getTime();
    var diffShoot = get_whole_values( msShoot, [1000,60,60]);
    diffShoot.forEach(element => {
      if(parseInt( element ) <0){
        value = true;
      }
    });

    var diffGardTotal = get_whole_values( msShoot + msSetup, [1000,60,60]);
    diffGardTotal.forEach(element => {
      if(parseInt( element ) <0){
        value = true;
      }
    });

    return value;
  };
  function alertTime(e, time){
    Swal.fire({
      title: 'Do you want to change the time?',
      showDenyButton: true,
      confirmButtonText: 'Change',
      denyButtonText: `Don't change`,
    }).then((result) => {
      if (result.isConfirmed) {
        changeTime(e, time)
      } else if (result.isDenied) {

      }
    })
  };
  function changeTime(e, time){
    var SaveFormat = new Intl.DateTimeFormat('az',{year:'numeric', month:'numeric', day:'numeric', hour:'numeric', minute:'numeric', second:'numeric', hour12:false });
    var actual = new Date(time);
    switch(e){
      case "SetupStart":
         setSetupS(SaveFormat.format(time));
         setSetupE('');
         setShootS('');
         setShootE('');
        break;
      case "SetupEnd":
         setSetupS('');
         setSetupE(SaveFormat.format(time));
         setShootS('');
         setShootE('');   
        break;
      case "ShootStart":
         setSetupS('');
         setSetupE('');
         setShootS(SaveFormat.format(time));
         setShootE('');
        break;
      case "ShootEnd":
         setSetupS('');
         setSetupE('');
         setShootS('');
         setShootE(SaveFormat.format(time));          
        break;
    }
  };
  function get_whole_values(base_value, time_fractions) {
    var time_data = [base_value];
    for (let i = 0; i < time_fractions.length; i++) {
        var value = String(parseInt(time_data[i]/time_fractions[i])).padStart(2, '0');
        if (value == "NaN"){
          value = "00";
        }
        time_data.push(value);
        value = String(time_data[i] % time_fractions[i]).padStart(2, '0'); 
        if (value == "NaN"){
          value = "00";
        }
        time_data[i] =  value;       
    }
    return time_data;
  };
  function notesSetup (e){
    e.persist();
    
    let valuevar = e.target.value;
    switch(e.target.id){
      case "note-id":
        setNote(valuevar)
        break;
      case "hdri-id":
        setHdri(valuevar)
        break;
    }
    
  };
  const updateModalTime = async (value, field) => {
    var SaveFormat = new Intl.DateTimeFormat('az',{year:'numeric', month:'numeric', day:'numeric', hour:'numeric', minute:'numeric', second:'numeric', hour12:false });
    var actual = new Date(value);
    switch(field){
      case "SetupStart":
        if( ChecktTime(actual,field) == true ){
          alertTime(field, actual);
        }
        else{            
          await setSetupS(SaveFormat.format(actual));
        }
        break;
      case "SetupEnd":
        if( ChecktTime(actual,field) == true ){
          alertTime(field, actual);
        }
        else{
          await setSetupE(SaveFormat.format(actual));
          await setShootS(SaveFormat.format(actual));
        }          
        break;
      case "ShootStart":
        if( ChecktTime(actual,field) == true ){
          alertTime(field, actual);
        }
        else{
          await setShootS(SaveFormat.format(actual));
          var setupEndTime = new Date(setupEnd);
          if(actual < setupEndTime || setupEndTime.toString() === "Invalid Date"){
            await setSetupE(SaveFormat.format(actual));
          }
        }          
        break;
      case "ShootEnd":
        await setShootE(SaveFormat.format(actual));
        var setupEndTime = new Date(setupEnd);
        if(actual < setupEndTime || setupEndTime.toString() === "Invalid Date"){
          await setSetupE(SaveFormat.format(actual));
        }
        var shootStartTime = new Date(shootStart);
        if(actual < shootStartTime || shootStartTime.toString() === "Invalid Date"){
          await setShootS(SaveFormat.format(actual));
        }
        break;
    }
  };
  const fillOptionsShootDay = function(){
    var optionsArray = []
    for(var i = 1; i <= 100; i++) {
      optionsArray.push({value:i, label:"Day "+i})
    }
    return optionsArray;
  };
  function fillnew(value){
    let valuereturn = null;
    if(value == null || value == ''){
      var SaveFormat = new Intl.DateTimeFormat('az',{year:'numeric', month:'numeric', day:'numeric', hour:'numeric', minute:'numeric', second:'numeric', hour12:false });
      var actual = new Date();
      valuereturn = SaveFormat.format(actual);
    }
    else{
      return value;
    }
    return valuereturn;
    // return value;
  }
  const sendData= async()=>{
    const options = { year: 'numeric', month: 'numeric', day: 'numeric' };
    let date = startDate.toLocaleDateString('en-US', options);
    let split = date.split("/");
    let dateComp = split[2]+"-"+split[0]+"-"+split[1];

    let json={
      "sid": props.id,
      "uid": parseInt( getLocStg("uid") ),
      // "piid": props.piid,
      "bid": selectedBudgedImpact.value,
      "shoot_date": dateComp,
      "shoot_day": shootDay,
      "notes":note,
      "hdri_ref_files": hdri,
      "setup_start": fillnew(setupStart),
      "setup_end": fillnew(setupEnd),
      "shoot_start": fillnew(shootStart),
      "shoot_end": fillnew(shootEnd)
    };
    await axios.put( window.domain+"setup", json, returnHeaders()).then(res => {
    });
  }
  return (
    <Modal show={props.show} dialogClassName="max-800" onHide={() =>{ props.onHide();}} aria-labelledby="contained-modal-title-vcenter" centered >      
      <Modal.Body>
      <DwModalTimeSelect setModalTime={updateModalTime} field={field} timerModal={timerModal} onHide={() => setTimerModal(false)} />
      <div className="row mb-4">
      <div className = "col-11">
        <h4>Setup</h4>
      </div>
      <div className = "col-1" onClick={props.onHide}>
        <h5 className="text-right">X</h5>
      </div>
      </div> 
      <div className='row'>     
        <div className="col-lg-12 mb-2">
          <span>BUDGET IMPACT</span>
          <DropDown isDisabled={false} id="budget" required={false} placeholder="-" options={fillBudgedImpact()} onChange={(e) => updateBudgedImpact(e)}  value={selectedBudgedImpact}/>
        </div>
        <div className="col-xl-8 col-lg-6">
          <span>NOTE</span>
          <TextArea isDisabled={false} id={"note-id"} className="width-100 dw-height-100" required={false} placeholder="Enter note" onChange={(e) => {setNote(e.target.value)}}/>
        </div>
        <div className="col-xl-4 col-lg-6 mb-3">
          <span>HDRI FILE NAMES</span>
          <TextArea isDisabled={false} id={"hdri-id"} className="width-100 dw-height-100" required={false} placeholder="Enter"  onChange={(e) => {setHdri(e.target.value)}}/>
        </div>
        <div className="col-lg-12 col-xl-6">
          <div className="row">
            <div className="col-lg-4"> <span>SETUP START</span></div>
            <div className="col-lg-4"> <span>SETUP END</span></div>
            <div className="col-lg-4"> <span>SHOOT START</span></div>
          </div>
          <div className="row">
            <div className="col-lg-4"><TimeInput timerClick={()=>{setTimerModal(true); setfield("SetupStart")}} isDisabled={false} className={"time-color-blue"} value={displayValues(setupStart)} divClass="width-100" onClick={() => {starClock("SetupStart")}}/> </div>
            <div className="col-lg-4"><TimeInput timerClick={()=>{setTimerModal(true); setfield("SetupEnd")}} isDisabled={false} className={"time-color-blue"} value={displayValues(setupEnd)} divClass="width-100" required={true} onClick={() => {starClock("SetupEnd")}}/> </div>
            <div className="col-lg-4"><TimeInput timerClick={()=>{setTimerModal(true); setfield("ShootStart")}} isDisabled={false} className={"time-color-blue"} value={displayValues(shootStart)} divClass="width-100" required={true} onClick={() => {starClock("ShootStart")}}/></div>
          </div>
          <div  className="row">
            <div className="col-xl-4"> </div>
            <div className="col-xl-4"> <span className="time-label">TOTAL MIN </span><span className="time-color-blue">{startTime}</span>  </div>
            <div className="col-xl-4">  </div>
          </div>                
        </div>
        <div className="col-lg-12 col-xl-6">
          <div className="row">
            <div className="col-lg-4"> <span>SHOOT END</span></div>
            <div className="col-lg-4"> <span>SHOOT DATE</span></div>
            <div className="col-lg-4"> <span>SHOOT DAY</span></div>
          </div>
          <div className="row">
            <div className="col-lg-4"><TimeInput timerClick={()=>{setTimerModal(true); setfield("ShootEnd")}} isDisabled={false} className={"time-color-blue"} value={displayValues(shootEnd)} divClass="width-100" required={true} onClick={() => {starClock("ShootEnd")}}/> </div>
            <div className="col-lg-4"><DateInput isDisabled={false} className="width-100 time-color-blue" required={true} startDate={startDate} setStartDate={setStartDate}/> </div>
            <div className="col-lg-4"><DropDown isDisabled={false} required={true} options={fillOptionsShootDay()}  onChange={(e) => {setShootDay(e.value); setSelectedDay({value: e.value , label: e.label});}} value={selectedDay}/> </div>
          </div>
          <div  className="row mb-3">
            <div className="col-xl-4">
              <span className="time-label">TOTAL MIN </span> <span className="time-color-blue">{shootTime}</span> 
              <br></br>
              <span className="time-label">GRAND TOTAL </span> <span className="time-color-blue">{TotalTime}</span>
            </div>
          </div>          
        </div>
      </div>
      <div  className="row">
            <ButtonPrimary required={true} className="width-100" label={"Update"} action={()=>sendData()}></ButtonPrimary>
          </div>
      </Modal.Body>
    </Modal>
  );
}

DWModalSetUp.defaultProps = {
  show: false,
  onHide: null
}

export {DWModalSetUp}