import React, { useState ,useEffect } from "react";
import axios from "axios";
import DwNav from "../components/DwNav";
import { ButtonSecondary } from "../components/forms/Buttons";
import {TextInput, DropDown} from "../components/forms/TextInputs";
import { useNavigate } from "react-router-dom";


const CreateProject = () =>{
  let navigate = useNavigate();

  const [currentName, setName] = useState('');
  const [episode, setEpisode] = useState('');
  const [budgetImpact, setBudgeted] = useState([]);

  useEffect(() =>{
    getAll();
    // eslint-disable-next-line
  },[]);
  const getAll = async function(){
    try{
      await axios.get( window.domain+"budgetImpact?oid="+getLocStg("oid"),returnHeaders()).then(res => {
        setBudgeted(res["data"]);
      });
    }
    catch{ }
  }
  const getLocStg=function(e){
    return localStorage.getItem(e)
  }
  // const fillOptionsBudgeted = function(){
  //   var optionsArray = []
  //   if(budgetImpact){
  //     budgetImpact.map(function(key, index ){
  //       optionsArray.push({value:key.bid, label:key.type})
  //     });
  //   }
  //   return optionsArray;
  // }
  
  const returnHeaders = function(){
    var token = getCookie( 'token' );
    let headerProposal ={
    headers: {
        'X-BP-Token': token,
      }
    };
    return headerProposal;
  }
  const getCookie = function( name ) {
    var r = document.cookie.match( "\\b" + name + "=([^;]*)\\b" );
    return r ? r[ 1 ] : null;
  }
  const fillOptionsNumber = function(){
    var optionsArray =[]
    for(var i = 100; i <= 500; i++) {
      optionsArray.push({value:i, label:i.toString()})
    }
    return optionsArray;
  }
  const CreateOrgAPI = async(name)=>{
    let json = {
      "pid": parseInt(getLocStg("pid")),
      "number": episode,
      "name": name
    }
    try {
      await axios.post( window.domain +"episode", json, returnHeaders()).then(res => {
        navigate("/view");
      });
      
    } 
    catch (error) {
        alert(error.response.data.error);
        return;
    }
  } 
  return (
    <>
    <DwNav/>
      <div className = "row d-flex justify-content-center align-items-center h-100">
        <div className="col-12 col-md-9 col-lg-7 col-xl-5">
          <div className="" style={{borderRadius: "1rem"}}>
            <div className="card-body p-5 " >
              <div className="mt-md-4 pb-5">
                <h1 className="text-center" style={ { color: "#ffffff", } }>Create Episode</h1>
                <TextInput className={"width-100"} required={false} placeholder="Episode name" onChange={(e) => setName(e.target.value)} />
                <div className="mb-4" >
                <DropDown placeholder={'Select Episode'} options={fillOptionsNumber()} onChange={(e) => {setEpisode(e.value);}} />
                </div>                
                <ButtonSecondary className="width-100 mb-3" label="Create" action={()=>{ CreateOrgAPI(currentName) }}/>
              </div>
              <div className="row">
                <div className="">
                  <h6 className="bp-p">TV SERIES: Episodes are required.</h6>
                </div>
                <div className="mb-3">
                  <p className="bp-p">Use this form to create all of the episodes that this project requires.</p>
                </div>
                <div className="">
                  <h6 className="bp-p">FEATURES - Episodes not required</h6>
                </div>
                <div className="">
                  <p className="bp-p">For now Bulletproof Data Wrangler requires something be entered into the Episodes field even if this project is not broken into Episodes.</p>
                  <p className="bp-p">We suggest you enter a single episode called "Shots" whith an Episode Number of 100.</p>
                  <p className="bp-p">The next build will allow for a more transparent Feature Film workflow.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      </>
  );
}
export default CreateProject;