import React from "react";
import Modal from 'react-bootstrap/Modal';
import axios from "axios";
import { ButtonSecondary } from "./forms/Buttons";

const DwDeleteOptions = (props) => {

  const getCookie = function( name ) {
    var r = document.cookie.match( "\\b" + name + "=([^;]*)\\b" );
    return r ? r[ 1 ] : null;
  }
  // eslint-disable-next-line no-unused-vars
  const returnHeaders = function(){
    var token = getCookie( 'token' );
    let headerProposal ={ headers: {'X-BP-Token': token} };
    return headerProposal;    
  }
  // eslint-disable-next-line no-unused-vars
  const getSimilarCamera=(takes,selected)=>{
    let similarCameras=[]
    for(var i = takes.length; i >= 0; i--){
      if(takes[i]){
        if(takes[i].camera === selected.camera){
          similarCameras.push(takes[i]);
        }
      }
    }
    props.setsecondtake(similarCameras);
    props.setcurrentselection("getCameras")
  }
  // eslint-disable-next-line no-unused-vars
  const getSimilarTakes=(takes,selected)=>{
    let similarCameras=[]
    for(var i = takes.length; i >= 0; i--){
      if(takes[i]){
        if(takes[i].number === selected.number){
          similarCameras.push(takes[i]);
        }
      }
    }
    props.setsecondtake(similarCameras);
    props.setcurrentselection("getTakes")
  }
  const deleteTask=async(tkid)=>{
    var token = getCookie( 'token' );
    // let json ={"tkid":tkid}
    try {
      await axios.delete( window.domain+"take",{ headers: {'X-BP-Token': token}, data: {"tkid":tkid}}).then(response => {
          props.gettake()
     });
    }
    catch{}
  }

  return (
    <Modal show={props.show} onHide={props.onHide} aria-labelledby="contained-modal-title-vcenter" centered >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          <h4>
            DELETE
          </h4>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ButtonSecondary className="width-100 mb-3" label={"DELETE TAKE"} action={()=>{deleteTask(props.selectedTake.tkid); props.onHide(); props.gettake() }}/>
        <ButtonSecondary className="width-100 mb-3" label={"CANCEL"} action={()=>{ props.onHide();}}/>
      </Modal.Body>
      {/* <Modal.Footer>
        <ButtonPrimary  label="Close" action={props.onHide}/>
      </Modal.Footer> */}
    </Modal>
  )
}

DwDeleteOptions.defaultProps = {
  show: false,
  onHide: null
}

export {DwDeleteOptions}