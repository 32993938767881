import React from "react";
import { Button } from 'react-bootstrap';

const ButtonPrimary = ({label, action, required, className}) => {
    return (
       <Button className={className} bsPrefix="dw-btn" onClick={action} aria-required={required}>{label}</Button>
   )
}

const ButtonSecondary = ({label, action, required, className, isDisabled}) => {
    return (
        <Button className={className} bsPrefix="dw-btn" disabled={isDisabled} variant="secondary" onClick={action} aria-required={required}>{label}</Button>
    )
}

const ButtonImage= ({ action, required, className, icon}) => {
    return (
        <Button className={className} bsPrefix="dw-btn" variant="secondary" onClick={action} aria-required={required}>{icon}</Button>
    )
}

ButtonSecondary.defaultProps = ButtonPrimary.defaultProps = ButtonImage.defaultProps ={
    label : "Button",
    required : false,
    action : function(){ console.log( 'Button with unassigned function' ) },
}


// export default ButtonPrimary
export {ButtonPrimary, ButtonSecondary,ButtonImage}