import React, { useState ,useEffect } from "react";
import axios from "axios";
import { DropDown } from "../components/forms/TextInputs";
import { ButtonSecondary } from "../components/forms/Buttons";
import Swal from 'sweetalert2/dist/sweetalert2.js'
import '@sweetalert2/theme-dark/dark.scss';
import { useNavigate } from "react-router-dom";
import { DwGenericModal } from "../components/DwGenericModal";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash } from "@fortawesome/free-solid-svg-icons";

const DwProject = (props) =>{
  let navigate = useNavigate();
  const [project, setProject] = useState('');
  const [episode, setEpisode] = useState('');
  const [productionItem, setPrdItem] = useState('');
  const [projectSelected, setProjectSelected] = useState('');
  const [episodeSelected, setEpisodeSelected] = useState('');
  // eslint-disable-next-line no-unused-vars
  const [productionItemSelected, setProductionItemSelected] = useState('');
  const [shotName, setShotName] = useState('');
  // eslint-disable-next-line no-unused-vars
  const [int_ext, setInt_ext] = useState('');
  // eslint-disable-next-line no-unused-vars
  const [location,setLocationTitle] = useState('');
  const [pid, setPid] = useState('');
  const [eid, setEid] = useState('');
  const [piid, setPiid] = useState('');
  const [piSelected,setPrdItemS] = useState('');
  const [bid, setbid]=useState('');

  const [imageModal, setImageModal] = useState(false);

  const [projectselected2, setProjectSelected2] = useState('');
  const [episodeselected2, setEpisodeSelected2] = useState('');
  const[shotselected, setShotselected]=useState('');

  const [ishidden, setIshidden] = useState("ishidden");
  const [state1, setState1] = useState(true);
  const [state2, setState2] = useState(false);
  const [state3, setState3] = useState(false);
  
  useEffect(()=>{
    getProjects();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);
  useEffect(()=>{
    if(pid !== '' && pid !== null && pid !== undefined){
      getEpisodes()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[pid]);
  useEffect(()=>{
    if(eid !== '' && eid !== null && eid !== undefined){
    getShoot()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[eid, episodeSelected, props.newShot])
  useEffect(()=>{
    getProductionItemData()    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[piid])
  useEffect(()=>{
    if(productionItem.length>0){
      setIshidden("");
    }
    else{
      setIshidden("ishidden");
    }
    // eslint-disable-next-line
  },[productionItem])
  useEffect(()=>{
    if(props.newpiid !== ""){
      selectpiid();
    }
    // eslint-disable-next-line
  },[productionItem])
  useEffect(()=>{
    getProductionItemData();
    // eslint-disable-next-line
  },[productionItem])

  const selectpiid = () =>{
    let selected = productionItem.find( obj => obj.piid === parseInt( props.newpiid ));
    changeDropDownPrdItem({value:selected.piid, label:selected.shot_name});
    props.setNewPiid("");
  }
  const getProductionItemData= function(){
    setPrdItemS('')
    for(var i = 0; i < productionItem.length; i++) {
      if(piid === productionItem[i]["piid"]){
        setPrdItemS(productionItem[i])
        props.setPrdItem(productionItem[i])
        setbid(productionItem[i].budget_impact);
        
        setShotName('\u00A0 \u00A0 \u00A0' +productionItem[i]["shot_name"].toUpperCase() +'\u00A0 \u00A0 \u00A0  \u00A0 ' +productionItem[i]["int_ext"] +'\u00A0 \u00A0 '+productionItem[i]["location"]+'\u00A0 \u00A0'+ productionItem[i]["day_night"])
        
        setInt_ext()
        setLocationTitle()

        returndefaultValue(props.budgetImpact,productionItem[i]["budget_impact"],"BI");
        returndefaultValue(props.dayOptions,productionItem[i]["shoot_day"],"SD");
        returndefaultValue(props.vfxtype,productionItem[i]["vfx_type"],"VT");
      }
    }
  }
  const getProjects = async function(){
    try {
      await axios.get( window.domain+"project?oid="+getLocStg("oid"),returnHeaders()).then(res => {
        setProject(res["data"])
        if(res["data"].length === 0){
          navigate("/projects")
        }
      });      
    }
    catch (error) {
        alert("Error");
        return;
    }
  }
  const returndefaultValue=(options, value ,type)=>{
    let valueReturn={};
    for(var i = 0; i <= options.length; i++){
      if(options[i]){
        if(options[i].value === value){
          valueReturn= options[i];
        }
      }

    }
    switch(type){
      case "BI":
        props.setSelectedbi(valueReturn)
        break;
      case "SD":
        props.setSelectDay(valueReturn)
        break;
      case "VT":
        props.setSelectVFX(valueReturn)
        break;
        default:
          //Do nothing
          break;
    }
    
  }  
  const getEpisodes = async function(){
    try {
      await axios.get( window.domain+"episode?pid="+pid,returnHeaders()).then(res => {
        setEpisode(res["data"]);
        if(res["data"].length === 0){
          navigate("/create-episode")
        }
      });
    }
    catch(error){
      alert("Error");
      return;
    }
    
  }
  const getShoot = async function(update=false){
    try {
      await axios.get( window.domain+"productionItem?eid="+eid,returnHeaders()).then(res => {
        setPrdItem(res["data"])
        if(update){getProductionItemData();}
      });
    }
    catch(error){
      alert("Error", error);
      return;
    }    
  }
  const returnHeaders = function(){
    var token = getCookie( 'token' );
    let headerProposal ={
    headers: {
        'X-BP-Token': token,
      }
    };
    return headerProposal;
  }
  const getLocStg=function(e){
    return localStorage.getItem(e)
  }
  const getCookie = function( name ) {
    var r = document.cookie.match( "\\b" + name + "=([^;]*)\\b" );
    return r ? r[ 1 ] : null;
  }
  const changeDropDown = function(e){    
    localStorage.setItem( 'pid', e.value )
    localStorage.setItem( 'bid', e.bid )
    setPid(e.value);
    for(var i = 0; i < project.length; i++) {
      if(project[i].pid === e.value){
        props.setProject(project[i])
        setProjectSelected(project[i])
      }
    }
    setPrdItemS('')
    props.setEpisode('')
    setEpisodeSelected('')
    props.setPiid("");
    setProjectSelected2(e);
    setEpisodeSelected2("");
    setShotselected("");
    props.setClearSetup(new Date())
    localStorage.setItem('sid', "")
    props.gettake();
    setState1(true);
    setState2(false);
    setState3(false);
    setEid("");
    setPrdItem([]);
  }
  const changeDropDownEpidode = function(e){ 
    setEpisodeSelected2(e);   
    localStorage.setItem( 'eid', e.value )
    setEid(e.value);
    for(var i = 0; i < episode.length; i++) {
      if(episode[i].eid === e.value){
        props.setEpisode(episode[i])
        setEpisodeSelected(episode[i])
      }
    }
    setPrdItemS('')
    setShotselected("")
    props.setPiid("");
    props.setClearSetup(new Date())
    localStorage.setItem( 'sid', "")
    props.gettake();
    setState1(false);
    setState2(true);
    setState3(false);
  }
  const changeDropDownPrdItem = (e)=>{
    localStorage.setItem( 'piid', e.value );
    setPiid(e.value);
    props.setPiid(e.value);
    setShotselected(e);
    setState3(true);
    props.setClearSetup(new Date())
    localStorage.setItem( 'sid', "")
    props.gettake();
  }
  const _update = () =>{
    getShoot(true);
    
  }
  const fillOptions = function(){
    var optionsArray = []
    if(project.length>0){
      for (let index = 0; index < project.length; index++) {
        optionsArray.push({value:project[index].pid, label:project[index].name, bid:project[index].bid});
      }
      // project.map(function(key, index ){
      //   optionsArray.push({value:key.pid, label:key.name, bid:key.bid})
      // });
    }
    return optionsArray;
    
  }
  // const fillOptionsVxf = function(){
  //   var optionsArray = []
  //   if(props.vfxtype){
  //     props.vfxtype.map(function(key, index ){
  //       optionsArray.push({value:key.vid, label:key.type})
  //     });
  //   }
  //   return optionsArray;
  // }
  // const fillOptionsBudgeted = function(){
  //   var optionsArray = []
  //   if(props.budgetImpact){
  //     props.budgetImpact.map(function(key, index ){
  //       optionsArray.push({value:key.bid, label:key.type})
  //     });
  //   }
  //   return optionsArray;
  // }

  // eslint-disable-next-line no-unused-vars
  const fillOptionsSootDay = function(){
    var optionsArray = []
    for(var i = 1; i <= 100; i++) {
      optionsArray.push({value:i, label:"Day "+i})
    }
    return optionsArray;
  }
  const fillOptionsEpisode = function(){
    var optionsArray = []
    if(episode.length>0){
      for (let index = 0; index < episode.length; index++) {
        optionsArray.push({value:episode[index].eid, label:episode[index].number})
      }
      // episode.map(function(key, index ){
      //   optionsArray.push({value:key.eid, label:key.number})
      // });
    return optionsArray;
  }
  }
  const fillOptionsProductionItem = function(){
    var optionsArray = []
    if(productionItem.length>0){
      for (let index = 0; index < productionItem.length; index++) {
        optionsArray.push({value:productionItem[index].piid, label:productionItem[index].shot_name})
      }
      // productionItem.map(function(key, index ){
      //   optionsArray.push({value:key.piid, label:key.shot_name})
      // });
    return optionsArray;
  }
  }
  // eslint-disable-next-line no-unused-vars
  function triggerAlert() {
    Swal.fire({
      // eslint-disable-next-line react/style-prop-object
        html:<div style="display: none;" >  </div>
      }
    )
  }
  // eslint-disable-next-line no-unused-vars
  const nameConst = ()=>{
    var projectComp=""
    var episodeComp= "";
    var shootComp = ""
    if(episodeSelected !== ''){
      episodeComp = "_" + episodeSelected.number;
    }
    if(piSelected !== ''){
      shootComp = "_" + piSelected.number;
    }
    if(projectSelected !== ''){
      projectComp = projectSelected.short_name
    }
    
    var name = projectComp+episodeComp+shootComp
    localStorage.setItem( 'projectName',name )
    return name;
  }
  const updateVfx=async(e)=>{
    props.setSelectVFX({value: e.value , label: e.label});
    let json = {"piid":piid, "vfx_type": e.value}
    await axios.put( window.domain+"productionItem",json,returnHeaders()).then(res => {
    });
  }
  const updateShootDay=async(e)=>{
    props.setSelectDay({value: e.value , label: e.label})
    let json = {"piid":piid,"shoot_day": e.value}
    await axios.put( window.domain+"productionItem",json,returnHeaders()).then(res => {
    });
  }
  const updateBudgedImpact=async(e)=>{
    props.setSelectedbi({value: e.value , label: e.label})
    setbid(e.value);
    let json = {"piid":piid,"budget_impact": e.value}
    await axios.put( window.domain+"productionItem",json,returnHeaders()).then(res => {
    });
  }
  const valuebi=function(bID){
    let element = props.budgetImpact.find( obj => obj.value === bID );
    if(element == null){return}
    return {value: element.value, label:element.label}
  }
  // eslint-disable-next-line no-unused-vars
  const openModalImage = function(){
    setImageModal(true);
  }
  const deleteShotAPI = async()=>{
    var token = getCookie( 'token' );
    try {
      await axios.delete( window.domain + "productionItem",{ headers: {'X-BP-Token': token}, data: {"piid": piid} }).then(res => {
        props.gettake();
      }).then( setShotselected(""), props.setClearSetup(new Date()), sleepGetShoot(), setState3(false), setShotName(""));
    }
    catch{}
  }
  function sleepGetShoot(){
    const sleep = time => new Promise(res => setTimeout(res, time));
    sleep(300).then(msg => getShoot());
  }
  const deleteShot = function(){
    Swal.fire({
      title: 'Are you sure you want to delete this scene/vfx shot along with all its related setups and takes?',
      showDenyButton: true,
      confirmButtonText: 'YES',
      denyButtonText:"NO"
    }).then((result) => {
      if (result.isConfirmed) {
        deleteShotAPI()
      } else if (result.isDenied) {

      }
    })
  }
  return (
      <div className="col-lg-12" style={{borderRadius: "1rem"}}>
        <DwGenericModal entity={piSelected.type} id={piSelected.piid} image={piSelected.image} update={_update} show={imageModal} onHide={() => setImageModal(false)} />
            <form className="form-inline">
              <div className="form-group row">
                <div className="col-xl-12">
                  <div className="row">
                    <div className="col-xl-11 padding-r"><h5>SCENE / VFX SHOT {shotName.toUpperCase()} </h5></div>
                    <div className="col-xl-1"><FontAwesomeIcon  className="fa-camera image-table delete-setup" icon={faTrash} onClick={()=>{deleteShot()}}/></div>
                    {/* <div className="col-xl-3"><h5> {int_ext.toUpperCase()}</h5></div>
                    <div className="col-xl-3"><h5> {location.toUpperCase()}</h5></div> */}
                  </div>
                  
                  <div className="row mb-4">
                    <div className="col-xl-7">
                      <div className="row">
                        <div className="col-project">
                          <DropDown required={state1} value={projectselected2} placeholder="Project" options={fillOptions()} onChange={(e) => changeDropDown(e)} />
                        </div>
                        <div className="col-episode">
                          <DropDown required={state1} value={episodeselected2} placeholder="Episode" options={fillOptionsEpisode()} onChange={(e) => changeDropDownEpidode(e)} />
                        </div>
                        <div className="col-shot">
                          <DropDown required={false} className={ishidden} value={shotselected} placeholder="Shot" options={fillOptionsProductionItem()} onChange={(e) => changeDropDownPrdItem(e)} />
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-5">
                      <div className="row">
                        <div className="col-void">                        
                        </div>
                        <div className="col-scene">
                          <ButtonSecondary required={state2} className="width-100" label={"+ SCENE"} action={() =>{props.setNewScene(true)}}/>
                        </div>
                        <div className="col-vfx">
                          <ButtonSecondary required={state2} className="width-100" label={"+ VFX SHOT"}  action={() =>{props.setModalShow(true)}}/>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
            <div className="row row-r">
              <div className="col-lg-12 col-xl-3 mb-2">
              </div>
              {/* <div className="col-lg-12 col-xl-9 dw-color-card ">
              <div className="mb-4"></div>
                <div className="row">
                    <div className="col-xl-2 col-lg-4" >
                      <p  style={{color:"#ffffff"}}>PRIORITY</p>
                      <p  style={{color:"#ffffff"}}>{piSelected["priority"]}</p>
                    </div>
                    <div className="col-xl-4 col-lg-6" >
                      <p  style={{color:"#ffffff"}}>I.A.</p>
                      <p  style={{color:"#ffffff"}}>-</p>
                    </div>
                    <div className="col-xl-2 col-lg-4" >
                      <p  style={{color:"#ffffff"}}>STATUS</p>
                      <p  style={{color:"#ffffff"}}>{piSelected["status"]}</p>
                    </div>
                    
                    <div className="col-xl-2 col-lg-4" >
                      <p  style={{color:"#ffffff"}}>DIFF.</p>
                      <p  style={{color:"#ffffff"}}>{piSelected["difficulty"]}</p>
                    </div>
                    <div className="col-xl-1 col-lg-2 padding-r padding-l" >
                      <p  style={{color:"#ffffff"}}>#REC</p>
                      <p  style={{color:"#ffffff"}}>-</p>
                    </div>
                    <div className="col-xl-1 col-lg-2 padding-r padding-l" >
                      <p  style={{color:"#ffffff"}}>#SHOT</p>
                      <p  style={{color:"#ffffff"}}>{piSelected["shot_count"]}</p>
                    </div>
                </div>
              </div> */}
            </div>
            <div className="row row-r">
              <div className="col-lg-4 col-xl-3">
                <div className="mb-3">
                  <img src={piSelected.image || window.domainBase + "/img/placeholder.png"} alt="" className="width-100 ratio" onClick={()=>{openModalImage()}}></img> {/* onClick={()=>{openModalImage()}} */}
                </div>
                <div className="mb-3">
                  <span>VFX TYPE</span>
                  <DropDown required={state3} options={props.vfxtype} onChange={(e) => {updateVfx(e)}} value={props.SelectVFX}/>
                </div>
                <div className="mb-3 ">
                  <span >SHOOT DAY</span>
                  <DropDown required={state3} options={props.dayOptions}  onChange={(e) => {updateShootDay(e)}} value={props.selectDay}/>
                  {/* setSelectDay={setSelectDay} selectDay={SelectDay} dayOptions={fillOptionsSootDay()} */}
                </div>
                <div className="mb-3">
                  <span>BUDGET IMPACT</span>
                  <DropDown required={state3} options={props.budgetImpact} onChange={(e) => {updateBudgedImpact(e)}} value={valuebi(bid)}/>
                </div>
              </div>
              <div className="col-lg-8 dw-color-card col-xl-9">
                <hr style={{border:"2px", color:"white"}}></hr>
                <span>SCRIPT NOTE</span>
                <div className="dw-height-input"><span className="mb-4" style={{color:"#ffffff"}}>{piSelected["script_note"]}</span></div>
                <span>SCOPE OF WORK</span>
                <div className="dw-height-input"><span className="mb-4" style={{color:"#ffffff"}}>{piSelected["sow"]}</span></div>
                <span>CREW SHOOTING NOTES</span>
                <div className="dw-height-input"><span className="mb-4" style={{color:"#ffffff"}}>{piSelected["crew_notes"]}</span></div>
              </div>
            </div>
      </div>
  )   
}
export default DwProject;