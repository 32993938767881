import React, { Component } from "react";
import axios from "axios";
import md5 from "blueimp-md5";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faKey, faUser, faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons'
import { ButtonPrimary } from "../components/forms/Buttons";

const domain = window.domain;

class login extends Component {
	state = {
		Usuario : '', 
		Password: '',
		Usuario_tem: '',
		Password_temp: '',
		check : false,
		type: "password"
	}
	componentDidMount(){ 
		//this.checkRemembermeAuto()
	}
	checkRememberme= async () =>{
		if (localStorage.getItem('rememberMe') === "true"){
			localStorage.setItem('rememberMe', false);
		}
		else {localStorage.setItem( 'rememberMe', true); }
			let b
		if (localStorage.getItem( 'rememberMe') === "true"){
			b = true;
		}
		else{
			b = false
		}
		await this.setState( state => {
			return { check: ( state.check = b ) };
		});
	}
	getCookie = function( name ){
		var r = document.cookie.match( "\\b" + name + "=([^;]*)\\b" );
		return r ? r[ 1 ] : null;
	}
	cookieCheck = async () =>{
		var token = this.getCookie('token');
		if ( token !== null ){
			window.location.href = "/";
		}
	}
	checkRemembermeAuto = async () =>{
		this.cookieCheck();
		let b 
		if (localStorage.getItem('rememberMe') === "true"){
			b = true;
			await this.setState(state =>{
				return{
					check: (state.check = b),
					Usuario_tem: (state.Usuario_tem = localStorage.getItem('email' )),
					Password_temp: (state.Password_temp = localStorage.getItem('password')) 
				};
			});
		}
		else {
			b = false
			await this.setState(state =>{
				return {check:(state.check = b)};
			});
		}
	}
	_handleKeyDown = ( e ) =>{
		if (e.key === 'Enter'){
			this.tryLogin();
		}
	}
	tryLogin = async () =>{
		this.state.Usuario_tem=this.state.Usuario_tem.toLowerCase();
		let res= null;
		var strHash = md5(this.state.Password_temp);
		try{
			res = await axios.post( domain + "auth", '{"email": "' + this.state.Usuario_tem + '","pass": "' + strHash + '"}');
		} 
		catch (error){
			alert("Password or User Incorrect");
			return;
		}
		if (res.data.success === true ){           
			localStorage.setItem( 'email', this.state.Usuario_tem);
			localStorage.setItem( 'uid', res.data.uid);
			localStorage.setItem( 'user', res.data.name + " " + res.data.last_name);
			document.cookie = 'token=' + res.data.token + '; Max-Age=86400; path=/';
			window.location.href = "/organizations";
		}
		else{
			alert("Password or User Incorrect");
		}
	}
	updateUser = async event =>{
		await this.setState({Usuario_tem: event.target.value});
	}
	updatePassword = async event =>{ 
		await this.setState({Password_temp:event.target.value});
	}
	updateType = async () =>{
		await this.setState({type:(this.state.type === "password") ? "text" : "password"});
	}
	render(){
		return(
			<div className= "container py-5 h-100" >
				<div className = "row d-flex justify-content-center align-items-center h-100">
					<div className="col-12 col-md-8 col-lg-6 col-xl-5">
						<div className="card bg-dark text-white" style={{borderRadius: "1rem"}}>
							<div className="card-body p-5 text-center" >
								<div className="mb-md-5 mt-md-4 pb-5">
									<h2 className="fw-bold mb-5 text-uppercase">Login</h2>
									<form className="">
										<div className="form-outline form-white mb-4" >
											<div className="row">
												<div className="col-lg-12 mb-4 ">
													<div className="input-group mb-3">
														<span className="input-group-text input-group-custom"><FontAwesomeIcon icon={faUser}/></span>
														<input type="text" required={true} className="form-control dw-input" placeholder="Email" aria-label="password" aria-describedby="button-addon2"  onChange={ this.updateUser } onKeyDown={ this._handleKeyDown } />
													</div>
												</div>
												<div className="col-lg-12  mb-4 ">
													<div className="input-group mb-3">
														<span className="input-group-text input-group-custom"><FontAwesomeIcon icon={faKey}/></span>
														<input type={this.state.type} required={true} className="form-control dw-input" placeholder="Password" aria-label="password" aria-describedby="button-addon2"  onChange={ this.updatePassword } onKeyDown={ this._handleKeyDown } />
														<button onClick={()=>{this.updateType()}} className="btn btn-outline-secondary dw-input eyeOrange" type="button" id="button-addon2"><FontAwesomeIcon icon={(this.state.type === "password") ? faEyeSlash : faEye}/></button>
													</div>
												</div>
											</div>
										</div>
									</form>
									<p className="small mb-2 pb-lg-2 width-100"><a className="width-100" href="/register">Sign Up</a></p>
									<p className="small mb-5 pb-lg-2 width-100"><a className="width-100" href="#!">Forgot password?</a></p>
									<ButtonPrimary className="width-100" label="Login" required={true} action={() => {this.tryLogin()}}/>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}
export default login;