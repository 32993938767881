import { useState, useEffect } from 'react';
import axios from "axios";
import PropTypes from 'prop-types';
import DwNav from "../components/DwNav";
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { visuallyHidden } from '@mui/utils';
import { ButtonSecondary } from "../components/forms/Buttons";
import { DwModalPrint } from '../components/DwModalPrint';
import { DWModalSetUp } from '../components/DwModalSetUp';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { DropDown, DateInput } from "../components/forms/TextInputs";
import { ThemeProvider, createTheme } from '@mui/material/styles';

const DailyProductionReports =(props)=>{
  const [projects, setProjects] = useState('');
  const [selectedProjectID, setselectedProjectID] = useState('');

  const [episodes, setEpisodes] = useState('');
  const [selectedEpisodeID, setselectedEpisodeID] = useState('');

  const [shots, setShots] = useState('');
  const [selectedShotID, setSelectedShotID] = useState('');

  const [budgedImpact, setBudgetImpact] = useState('');
  const [selectedBudgedImpact, setbudgedImpact] = useState('');
  
  const [selectedVX, setVX] = useState('');
  const [selectedPreLet, setPreLet] = useState('');
  const [selectedPostLet, setPostLet] = useState('');
  const [selectedShootDay, setShootDay] = useState('');
  const [modalPrint, setmodalPrint] = useState(false);
  const [datesentfrom,setDateSentfrom] = useState('');
  const [datesentto,setDateSentto] = useState('');
  const [json, setJson] = useState({});
  const [rows, setRows] = useState([]);
  const [modalSetUp, setModalSetUp] = useState(false);
  const [setupId, setsetupId] = useState('');
  const [request, setRequest] = useState();
  const [total_setup_duration, settotal_setup_duration] = useState("");
  const [total_shoot_duration, settotal_shoot_duration] = useState("");
  const [total_duration, settotal_duration] = useState("");

  useEffect(() => {
    setRows([createData("-","-","-","-","-","-","-","-","-","-","-","-","-","-","-")])
    getprojects();
    getBudgedImpact();
    // eslint-disable-next-line
  },[]);
  useEffect(() => {
    if(new Date(datesentfrom) > new Date(datesentto)){
      Swal.fire({
        title: "Invalid date range"
      }).then((result) => {
        setDateSentfrom("")
        setDateSentto("");
      })
    }
    if(datesentto === "") {
      setDateSentto(datesentfrom)
    }
    if(datesentfrom === "") {
      setDateSentfrom(datesentto)
    }
    // eslint-disable-next-line
  },[datesentfrom, datesentto]);
  useEffect(() => {
    if(isdiferent(selectedProjectID)){
      getEpisodes();
    }
    // eslint-disable-next-line
  },[selectedProjectID]);
  useEffect(() => {
    if(isdiferent(selectedEpisodeID)){
      getShots();
    }
    // eslint-disable-next-line
  },[selectedEpisodeID]);

  const isdiferent =function(item){
    if(item !== '' && item !=="-" && item !== null && item !== undefined){
      return true;
    }
    return false;
  }
  const darkTheme = createTheme({
    palette: {
      mode: 'dark',
    },
  });
  // Table Start
  function createData(project, episode, slate, location, shoot_date, shoot_day, budget_impact, setup_start,setup_end,setup_duration,shoot_start,shoot_end,shoot_duration,total_duration,sid) {
    return {
      project,
      episode,
      slate,
      location,
      shoot_date,
      shoot_day,
      budget_impact,
      setup_start,
      setup_end,
      setup_duration,
      shoot_start,
      shoot_end,
      shoot_duration,
      total_duration,
      sid
    };


  }
  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }
  function getComparator(order, orderBy) {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }
  const headCells = [
    // {
    //   id: 'project',
    //   numeric: false,
    //   disablePadding: true,
    //   label: 'PROJECT',
    // },
    // {
    //   id: 'episode',
    //   numeric: true,
    //   disablePadding: false,
    //   label: 'EPISODE',
    // },
    {
      id: 'slate',
      numeric: true,
      disablePadding: false,
      label: 'SLATE',
    },
    {
      id: 'location',
      numeric: true,
      disablePadding: false,
      label: 'LOCATION',
    },
    {
      id: 'shoot_date',
      numeric: true,
      disablePadding: false,
      label: 'SHOOT DATE',
    },
    {
      id: 'shoot_day',
      numeric: true,
      disablePadding: false,
      label: 'SHOOT DAY',
    },
    {
      id: 'budget_impact',
      numeric: true,
      disablePadding: false,
      label: 'BUDGET IMPACT',
    },
    {
      id: 'setup_start',
      numeric: true,
      disablePadding: false,
      label: 'START',
    },
    {
      id: 'setup_end',
      numeric: true,
      disablePadding: false,
      label: 'END',
    },
    {
      id: 'setup_total',
      numeric: true,
      disablePadding: false,
      label: 'TOTAL',
    },
    {
      id: 'shoot_start',
      numeric: true,
      disablePadding: false,
      label: 'START',
    },
    {
      id: 'shoot_end',
      numeric: true,
      disablePadding: false,
      label: 'END',
    },
    {
      id: 'shoot_total',
      numeric: true,
      disablePadding: false,
      label: 'TOTAL',
    },
    {
      id: 'total',
      numeric: true,
      disablePadding: false,
      label: 'TOTAL',
    },
  ]
  function EnhancedTableHead(props) {
    const { order, orderBy, onRequestSort } =
      props;
    const createSortHandler = (property) => (event) => {
      onRequestSort(event, property);
    };
  
    return (
      <TableHead>
        <TableRow>
          <TableCell padding="checkbox">
  
          </TableCell>
          {headCells.map((headCell) => (
            <TableCell
              key={headCell.id}
              align='left'
              padding={headCell.disablePadding ? 'none' : 'normal'}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      
    );
  }
  EnhancedTableHead.propTypes = {
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
  }
  const EnhancedTableToolbar = (props) => {
    const { numSelected } = props;
  
    return (
      <Toolbar
        sx={{
          pl: { sm: 2 },
          pr: { xs: 1, sm: 1 },
        }}
      >
        {numSelected > 0 ? (
          <Typography
            sx={{ flex: '1 1 100%' }}
            color="inherit"
            variant="subtitle1"
            component="div"
          >
            {numSelected} selected
          </Typography>
        ) : (
          <Typography
            sx={{ flex: '1 1 100%' }}
            variant="h6"
            id="tableTitle"
            component="div"
          >
            
          </Typography>
        )}
      </Toolbar>
    );
  }
  EnhancedTableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired,
  }
  function EnhancedTable() {
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState("");
    const [selected, setSelected] = useState([]);
    const [page, setPage] = useState(0);
    const [dense, setDense] = useState(true);
    const [rowsPerPage, setRowsPerPage] = useState(25);
  
    const handleRequestSort = (event, property) => {
      const isAsc = orderBy === property && order === 'asc';
      setOrder(isAsc ? 'desc' : 'asc');
      setOrderBy(property);
    };
  
    const handleClick = (event, name) => {
      const selectedIndex = selected.indexOf(name);
      let newSelected = [];
  
      if (selectedIndex === -1) {
        newSelected = newSelected.concat(selected, name);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selected.slice(1));
      } else if (selectedIndex === selected.length - 1) {
        newSelected = newSelected.concat(selected.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          selected.slice(0, selectedIndex),
          selected.slice(selectedIndex + 1),
        );
      }
  
      setSelected(newSelected);
    };
  
    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };
  
    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
    };
  
    const handleChangeDense = (event) => {
      setDense(event.target.checked);
    };
  
    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
      page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;
  
    return (
      <ThemeProvider theme={darkTheme}>
      <Box sx={{ width: '100%' }}>
        <Paper sx={{ width: '100%', mb: 2 }}>
          <EnhancedTableToolbar numSelected={selected.length} />
          <TableContainer>
            <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size={dense ? 'small' : 'medium'}>              
              <TableHead>
                <TableRow>
                  <TableCell padding="checkbox"/>
                  {/* <TableCell/>
                  <TableCell/> */}
                  <TableCell/>
                  <TableCell/>
                  <TableCell/>
                  <TableCell/>
                  <TableCell/>
                  <TableCell/>
                  <TableCell>SETUP TIME</TableCell>
                  <TableCell/>
                  <TableCell/>
                  <TableCell>SHOOT TIME</TableCell>
                  <TableCell/>
                  <TableCell>DURATION</TableCell>
                </TableRow>
              </TableHead>
              <EnhancedTableHead
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                rowCount={rows.length}
              />

 
              <TableBody>
              <TableRow>
                  <TableCell padding="checkbox"/>
                  {/* <TableCell/>
                  <TableCell/> */}
                  <TableCell/>
                  <TableCell/>
                  <TableCell/>
                  <TableCell/>
                  <TableCell/>
                  <TableCell/>
                  <TableCell/>
                  <TableCell className='bp-blue'>{total_setup_duration}</TableCell>
                  <TableCell/>
                  <TableCell/>
                  <TableCell className='bp-blue'>{total_shoot_duration}</TableCell>
                  <TableCell className='bp-blue'>{total_duration}</TableCell>
                </TableRow>
                {rows.slice().sort(getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
  
                    // const labelId = `enhanced-table-checkbox-${index}`;
  
                    return (
                      <TableRow hover
                        onClick={(event) => handleClick(event, row.sid)}
                        role="checkbox"
                        tabIndex={-1}
                        key={row.sid}
                      >
                        <TableCell padding="checkbox">
                        </TableCell>
                        {/* <TableCell
                          component="th"
                          id={labelId}
                          scope="row"
                          padding="none"
                        >
                          {row.project}
                        </TableCell> */}
                        {/*
                          To align cell data to the right use:
                          <TableCell align="right">
                          */
                        }
                        {/* <TableCell>{row.episode}</TableCell> */}
                        <TableCell>{row.slate }</TableCell>
                        <TableCell>{row.location}</TableCell>
                        <TableCell>{row.shoot_date}</TableCell>
                        <TableCell>{row.shoot_day}</TableCell>
                        <TableCell>{row.budget_impact}</TableCell>
                        <TableCell>{row.setup_start}</TableCell>
                        <TableCell>{row.setup_end}</TableCell>
                        <TableCell className='bp-blue'>{row.setup_duration}</TableCell>
                        <TableCell>{row.shoot_start}</TableCell>
                        <TableCell>{row.shoot_end}</TableCell>
                        <TableCell className='bp-blue'>{row.shoot_duration}</TableCell>
                        <TableCell className='bp-blue'>{row.total_duration}</TableCell>
                        <TableCell><ButtonSecondary className="width-100" label={"..."} action={()=>{ openSetUp(row.sid) }}/></TableCell>
                      </TableRow>
                    );
                  })}
                {emptyRows > 0 && (
                  <TableRow
                    style={{
                      height: (dense ? 33 : 53) * emptyRows,
                    }}
                  >
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[ 25, 100]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
        &nbsp; &nbsp; &nbsp;
        <FormControlLabel
          control={<Switch checked={dense} onChange={handleChangeDense} />}
          label="Dense padding"
        />
      </Box>
      </ThemeProvider>
    );
  }
  const openSetUp = function(id){    
    setModalSetUp(true);
    setRequest(new Date());
    setsetupId(id);
  }
  const getLocStg=function(e){
    return localStorage.getItem(e)
  }
  const returnHeaders = function(){
    var token = getCookie( 'token' );
    let headerProposal ={headers: {'X-BP-Token': token}};
    return headerProposal;
  }
  const getCookie = function( name ) {
    var r = document.cookie.match( "\\b" + name + "=([^;]*)\\b" );
    return r ? r[ 1 ] : null;
  }
  const getprojects = async function(){
    await axios.get( window.domain+"project?oid="+getLocStg("oid"),returnHeaders()).then(res => {
      setProjects(res["data"]);
    });
  }
  const fillProjects = function(){
    var newArray = [];
    for (let index = 0; index < projects.length; index++) {
      newArray.push({value:projects[index].pid, label:projects[index].name});
    }
    return newArray;
  }
  const updateProject  = function(e){
    setselectedProjectID(e.value);
  }
  const getEpisodes = async function(){
    try {
      await axios.get( window.domain+"episode?pid=" + selectedProjectID, returnHeaders()).then(res => {
        setEpisodes(res["data"]);
      });
    } catch (error) {
      alert("Error");
      return;
    }
  }
  const fillEpisodes = function(){
    var newArray = [];
    newArray.push({value:"", label:"-"});
    for (let index = 0; index < episodes.length; index++) {
      newArray.push({value:episodes[index].eid, label:episodes[index].number});
    }
    return newArray;
  }
  const updateEpisode  = function(e){
    setselectedEpisodeID(e.value);
  }
  const getShots = async function(){
    try {
      await axios.get( window.domain+"productionItem?eid=" + selectedEpisodeID,returnHeaders()).then(res => {
        setShots(res["data"])
      });
    }
    catch(error){
      alert("Error");
      return;
    }
  }
  const fillShots = function(){
    var newArray = [];
    newArray.push({value:"", label:"-"});
    for (let index = 0; index < shots.length; index++) {
      newArray.push( {value:shots[index].scene_number, label:shots[index].scene_number});
    }
    return newArray;
  }
  const fillVX =function(){
    return [
      {value:"", label:"-"},
      {value:"V", label:"V"},
      {value:"VX", label:"VX"},
      {value:"X", label:"X"},
    ];
  }
  const fillAlfabet = function(){
    var optionsArray =[]
    optionsArray.push({value:"", label:"-"})
    for(var i = 0; i <= 25; i++) {
      optionsArray.push({value:i, label:(i+10).toString(36).toUpperCase()})      
    }
    return optionsArray;
  }
  const getBudgedImpact = async() =>{
    await axios.get( window.domain+"budgetImpact?oid="+getLocStg("oid"),returnHeaders()).then(res => {
      setBudgetImpact(res["data"])
    });
  }
  const fillBudgedImpact = function(){
    var newArray = [];
    newArray.push({value:"", label:"-"});
    for (let index = 0; index < budgedImpact.length; index++) {
      newArray.push({value:budgedImpact[index].bid, label:budgedImpact[index].type})
    }
    return newArray;
  }
  const updateSelectedShotID  = function(e){
    setSelectedShotID(e.value);
  }
  const updateVX  = function(e){
    setVX(e.value);
  }
  const updatePreLet  = function(e){
    setPreLet(e.label);
  }
  const updatePostLet  = function(e){
    setPostLet(e.label);
  }
  const updateShootDay  = function(e){
    setShootDay(e.value);
  }
  const updateBudgedImpact  = function(e){
    setbudgedImpact(e.value);
  }
  const createjson = function(){
    var SaveFormat = new Intl.DateTimeFormat('az',{year:'numeric', month:'numeric', day:'numeric' });
    let newjson={"pid": selectedProjectID, "shoot_day": selectedShootDay}
    let datefrom = null;
    let dateto = null;
    if(isdiferent(datesentfrom)){ datefrom = SaveFormat.format(datesentfrom) }
    if(isdiferent(datesentto)){ dateto = SaveFormat.format(datesentto) }
    if(isdiferent(selectedEpisodeID)) { newjson.eid = selectedEpisodeID}
    if(isdiferent(selectedVX)) { newjson.v_x = selectedVX}
    if(isdiferent(selectedPreLet)){ newjson.scene_prelet = selectedPreLet}
    if(isdiferent(selectedShotID)) { newjson.scene_number = selectedShotID}
    if(isdiferent(selectedPostLet)) { newjson.scene_postlet = selectedPostLet}
    if(isdiferent(datefrom)) { newjson.date_from = datefrom}
    if(isdiferent(dateto)) { newjson.date_to = dateto}
    if(isdiferent(selectedBudgedImpact)) { newjson.bid = selectedBudgedImpact};
    return newjson;
  }
  function encodeWhiteSpaces(str) {
    return str.split('').map(function(c) { return c === ' ' ? '\u00A0' : c }).join('');
 }
  const search = async() =>{
    if(isdiferent(selectedProjectID) === false){
      Swal.fire({
        title: 'Please select a project'
      }).then((result) => {})
    }
    else{
      try {
        await axios.post( window.domain+"reportPreview", createjson(), returnHeaders()).then(res => {
          if(res.data.length === 0){
            setRows([createData("-","-","-","-","-","-","-","-","-","-","-","-","-","-","-",)]);
          }
          else{
            let rowsaux = [];
            setRows(rowsaux);
            settotal_setup_duration(res.data.totals.total_setup_duration);
            settotal_shoot_duration(res.data.totals.total_shoot_duration);
            settotal_duration(res.data.totals.total_duration);
            res.data.items.forEach(element => {
              rowsaux.push(createData(
                element.project,
                element.episode,
                encodeWhiteSpaces(element.slate),
                element.location,
                element.shoot_date,
                element.shoot_day,
                element.budget_impact,
                element.setup_start,
                element.setup_end,
                element.setup_duration,
                element.shoot_start,
                element.shoot_end,
                element.shoot_duration,
                element.total_duration,
                element.sid));
            });
            setRows(rowsaux);
          }          
          });
      }
      catch{
        setRows([createData("-","-","-","-","-","-","-","-","-","-","-","-","-","-","-",)]);
      }
    }
  }
  const print = function(){
    setJson(createjson());
    setmodalPrint(true);
  }
  const fillOptionsSootDay = function(){
    var optionsArray = []
    optionsArray.push({value:"", label:"-"});
    for(var i = 1; i <= 100; i++) {
      optionsArray.push({value:i, label:"Day "+i})
    }
    return optionsArray;
  }
  return(
    <>
      <DwNav/>
      <header>
        <h1>Daily Production Report</h1>
        <h6> Search for SETUPS to add to the DPR </h6>
      </header>
      <div className="col-12 row pt-2" >

        <DwModalPrint title={"Create Daily Production Reports"} btnLabel={"OPEN DPR"} url={"dailyProductionReport"} setmodalPrint={setmodalPrint} modalPrint={modalPrint} data={json} onHide={() => setmodalPrint(false)} />
        <DWModalSetUp setModalSetUp={setModalSetUp} date={request} id={setupId} show={modalSetUp} onHide={() => setModalSetUp(false)}></DWModalSetUp>
        <div className="col-sm-1">
          <DropDown required={true} options={fillProjects()} onChange={(e) => updateProject(e)} placeholder="PROJECT"/>
        </div>
        <div className="col-sm-1">
          <DropDown required={true} options={fillEpisodes()} onChange={(e) => updateEpisode(e)} placeholder="EPISODE"/>
        </div>
        <div className="col-sm-1">
          <DropDown required={false} options={fillVX()} onChange={(e) => updateVX(e)} placeholder="V/X"/>
        </div>
        <div className="col-sm-1">
          <DropDown required={false} options={fillAlfabet()} onChange={(e) => updatePreLet(e)} placeholder="PRELET"/>
        </div>
        <div className="col-sm-1">
          <DropDown required={false} options={fillShots()}  onChange={(e) => updateSelectedShotID(e)} placeholder="SCENE"/>
        </div>
        <div className="col-sm-1">
          <DropDown required={false} options={fillAlfabet()} onChange={(e) => updatePostLet(e)} placeholder="POSTLET"/>
        </div>
        <div className="col-sm-1">
          <DateInput className="width-100 autocomplete" setStartDate={setDateSentfrom} startDate={datesentfrom} required={false} placeholder="FROM SHOOT DATE"/>
        </div>
        <div className="col-sm-1">
          <DateInput className="width-100 autocomplete" setStartDate={setDateSentto} startDate={datesentto} required={false} placeholder="TO SHOOT DATE"/>
        </div>
        <div className="col-sm-1">
          <DropDown required={false} options={fillOptionsSootDay()} onChange={(e) => updateShootDay(e)} placeholder="SHOOT DAY"/>
        </div>
        <div className="col-sm-1">
          <DropDown required={false} options={fillBudgedImpact()} onChange={(e) => updateBudgedImpact(e)} placeholder="BUDGET IMPACT"/>
        </div>
        <div className="col-sm-1">
          < ButtonSecondary className="width-100" label={"Search"} required={true} action={() =>{search()}}/>
        </div>
        <div className="col-sm-1">
          < ButtonSecondary className="width-100" label={"Create DPR"} required={true} action={() =>{print()}}/>
        </div>
      </div>
      <br/>
      <EnhancedTable/>
    </>
  )
}
export default DailyProductionReports