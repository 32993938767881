import React, { useState ,useEffect } from "react";
import axios from "axios";
import DwProjects from"../components/DwProject";
import DwSetup from"../components/DwSetup2";
import DwTakes from"../components/DwTakes";
import DwCookie from"../components/DwCookieCheck";
import {DwNewShotModal} from '../components/DwModals';
import {DwNewSceneModal} from '../components/DwSceneModals';
import DwNav from "../components/DwNav";

const Organization = () =>{
  const [modalShow, setModalShow] = useState(false);
  const [newScene, setNewScene] = useState(false);
  const [piid, setPiid] = useState(0);
  // const [tkid, setTkid] = useState(0);
  const [sid, setSid] = useState("");
  const [updateSetup, setUdso] = useState('');
  const [allProjects, setAllProjects] = useState([]);
  const [project, setProject] = useState([]);
  const [episode, setEpisode] = useState([]);
  const [productionItem, setPrdItem] = useState('');
  const [setup, setSetup] = useState([]);
  const [take, setTake] = useState([]);
  const [budgetImpact, setBudgeted] = useState([]);
  const [vfxtype, setVxf] = useState([]);
  const [selectBI, setSelectBI] = useState({});
  const [SelectDay, setSelectDay] = useState({});
  const [SelectVFX, setSelectVFX] = useState({});
  const [newShot, setNewShot]= useState('');
  const [newPiid, setNewPiid] = useState("");

  const [clearSetup, setClearSetup] = useState('');
  const [timecontrol, setTimeControl]=useState();
  
  useEffect(() =>{
    getAll();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);
  useEffect(()=>{    
    getSetup();    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[piid, updateSetup]);
  useEffect(()=>{
    if(sid !== ""){
      getTake();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[sid]);


  const fillOptionsVxf = function(){
    var optionsArray = []
    if(vfxtype){
      for (let index = 0; index < vfxtype.length; index++) {
        optionsArray.push({value:vfxtype[index].vid, label:vfxtype[index].type});
      }
      // vfxtype.map(function(key, index ){
      //   optionsArray.push({value:key.vid, label:key.type})
      // });
    }
    return optionsArray;
  }
  const fillOptionsBudgeted = function(){
    var optionsArray = []
    if(budgetImpact){
      for (let index = 0; index < budgetImpact.length; index++) {
        optionsArray.push({value:budgetImpact[index].bid, label:budgetImpact[index].type});
      }
      // budgetImpact.map(function(key, index ){
      //   optionsArray.push({value:key.bid, label:key.type})
      // });
    }
    return optionsArray;
  }
  const fillOptionsSootDay = function(){
    var optionsArray = []
    for(var i = 1; i <= 100; i++) {
      optionsArray.push({value:i, label:"Day "+i})
    }
    return optionsArray;
  }
  const getTake = async function(REFRESH){
    if(REFRESH === false || REFRESH === undefined){
      setTake([])
    }    
    try{
      await axios.get( window.domain+"take?sid="+getLocStg("sid"),returnHeaders()).then(res => {
        setTake(res["data"]);
      });
    }
    catch{}
  } 
  const getAll = async function(){    
    try{
      await axios.get( window.domain+"budgetImpact?oid="+getLocStg("oid"),returnHeaders()).then(res => {
        setBudgeted(res["data"])
      });
      await axios.get( window.domain+"vfxType?oid="+getLocStg("oid"),returnHeaders()).then(res => {
        setVxf(res["data"]) 
      });
    }
    catch{
    }
  }
  const getSetup = async function(){
    try {
      await axios.get( window.domain+"setup?piid="+piid.toString(),returnHeaders()).then(res => {
        setSetup(res["data"])
      });
    }
    catch (error) {
        alert("Error");
        return;
    }
  }
  const getLocStg=function(e){
    return localStorage.getItem(e)
  }
  const returnHeaders = function(){
    var token = getCookie( 'token' );
    let headerProposal ={
    headers: {
        'X-BP-Token': token,
      }
    };
    return headerProposal;
  }
  const getCookie = function(name){
    var r = document.cookie.match( "\\b" + name + "=([^;]*)\\b" );
    return r ? r[ 1 ] : null;
  }

  return(
      <div className="col-12 row" >
        <div className="row">
          <div className="col-xl-6 col-6  col-sm-6">
            <DwNav/>
          </div>
          <div className="col-xl-6 col-6  col-sm-6">
          <img src="/img/logo.jpg" alt="" style={{ height: 60, float: "right"}}></img> 
          </div>
        </div>
        <DwCookie/>
        <div className="col-sm-5">
          <div className="card bg-dark p-2 m-1 " ><DwProjects productionItem={productionItem} newpiid={newPiid} setNewPiid={setNewPiid} newShot={newShot} setClearSetup={setClearSetup} SelectVFX={SelectVFX} setSelectVFX={setSelectVFX} setSelectDay={setSelectDay} selectDay={SelectDay} dayOptions={fillOptionsSootDay()} gettake={getTake} setAllProjects={setAllProjects} vfxtype={fillOptionsVxf()} budgetImpact={fillOptionsBudgeted()} selectedbi={selectBI} setSelectedbi={setSelectBI} setEpisode={setEpisode} setProject={setProject} setPiid={setPiid} setPrdItem={setPrdItem} setModalShow={setModalShow} setNewScene={setNewScene}/></div>
        </div>
        <div className="col-sm-7 ">
          <div className="m-1 p-2 card bg-dark"><DwSetup timeControl={timecontrol} take={take} setsid={setSid} setSelectDay={setSelectDay} selectDay={SelectDay} dayOptions={fillOptionsSootDay()} selectedbi={selectBI} setSelectedbi={setSelectBI} setUdso={setUdso} gettake={getTake} budgetImpact={fillOptionsBudgeted()} episode={episode} project={project} piid={piid} productionItem={productionItem} setPrdItem={setPrdItem} setSetup={setSetup} setup={setup} clearSetup={clearSetup}/></div>
        </div>
          <DwNewSceneModal setnewshot={setNewShot} setNewPiid={setNewPiid} show={newScene} onHide={() => setNewScene(false)} allprojects={allProjects} project={project} episode={episode} productionitem={productionItem}/>
          <DwNewShotModal setnewshot={setNewShot} setNewPiid={setNewPiid} show={modalShow} onHide={() => setModalShow(false)} allprojects={allProjects} project={project} episode={episode} productionitem={productionItem}/>
        <div className="col-sm-12">
          <div className="card bg-dark p-2 m-1">
            <DwTakes take={take} gettake={getTake} heightwin={280} setTimeControl={setTimeControl}/>
          </div>
        </div>
      </div>
  )
}
export default Organization;